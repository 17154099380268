import {
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { routes } from "../../constants";
import Axios, { authHeaders } from "../../helpers/axios";

export const createSidemenu = createAsyncThunk(
  "createSidemenu",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/sph/side-menu`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 || response.status === 201) {
        toast.success(data.toastText, {
          autoClose: 3000,
        });
        data.navigate(routes.sidemenu);
        return response.data;
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

export const updateSidemenu = createAsyncThunk(
  "updateSidemenu",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/sph/side-menu/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 && !data.cb && !data.toast) {
        toast.success(data.toastText, {
          autoClose: 3000,
        });
        data.navigate(`${routes.sidemenu}`, { state: { value: data.value } });
        return response.data;
      }
      if (response.status === 200 && data.cb) {
        // toast.success("Side menu component updated successfully", {
        //   autoClose: 3000,
        // });
        return data.cb(null, response.data);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      console.log("error", error.response);
    }
  }
);

export const getSidemenu = createAsyncThunk(
  "getSidemenu",
  async (data, thunkAPI) => {
    try {
      let api = data.admin === 1 ? "" : "/nav-menu-list";
      const response = await Axios.get(
        `/sph/side-menu${api}?start=0&admin=${data.admin}&app_type_id=${
          data.app_type_id || ""
        }&search_type=${data.appStatus || ""}`,
        authHeaders()
      );
      return {
        admin_sidebar: data.admin === 1 && response.data,
        all_sidebar: data.admin === 0 && response.data,
      };
    } catch (error) {
      if (error.response.status === 403 && data.noAuthority) {
        toast.error(error.response.data.message, {
          autoClose: 3000,
        });
        return data.noAuthority(error.response.data);
      }
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

export const clearSidemenuList = createAsyncThunk(
  "clearSidemenuList",
  async () => {
    return;
  }
);

const sidemenuSlice = createSlice({
  name: "sidemenu",
  initialState: {
    adminSidemenuList: [],
    sidemenuList: [],
    createSidemenuComponent: "",
    updateSidemenuComponent: "",
    error: null,
    loader: false,
    updateLoader: false,
    no_authority: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Get side menu

    builder.addCase(getSidemenu.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getSidemenu.fulfilled, (state, action) => {
      state.loader = false;
      state.updateLoader = false;
      if (action.payload?.admin_sidebar?.length > 0) {
        state.admin_sidebar = action.payload.admin_sidebar;
      }
      if (action.payload?.all_sidebar?.length > 0) {
        state.sidemenuList = action.payload.all_sidebar;
      }
    });
    builder.addCase(getSidemenu.rejected, (state, action) => {
      state.loader = false;
      state.updateLoader = false;
      state.error = action.payload;
    });

    // Create side menu
    builder.addCase(createSidemenu.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(createSidemenu.fulfilled, (state, action) => {
      state.loader = false;
      state.createSidemenuComponent = action.payload;
    });
    builder.addCase(createSidemenu.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Update side menu
    builder.addCase(updateSidemenu.pending, (state) => {
      state.loader = true;
      state.updateLoader = true;
    });
    builder.addCase(updateSidemenu.fulfilled, (state, action) => {
      state.loader = false;
      state.updateSidemenuComponent = action.payload;
    });
    builder.addCase(updateSidemenu.rejected, (state, action) => {
      state.loader = false;
      state.updateLoader = false;
      state.error = action.payload;
    });

    //clear side menu
    builder.addCase(clearSidemenuList.fulfilled, (state, action) => {
      state.loader = false;
      state.sidemenuList = [];
    });
  },
});

export default sidemenuSlice.reducer;

import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "../../component/Common/Button";
import Input from "../../component/Common/Input";
import { routes } from "../../constants";
import { useForm } from "../../hooks/useForm";
import { dateFunction } from "../../component/Common/PageComponent/DirectoryComponents";
import { isValidPhoneNumber } from "react-phone-number-input";
import { withoutMetadata } from "../../component/Common/PageComponent/LoginPageComponents";
import { parsePhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-number-input";
import { generate_password } from "../../component/Common/PageComponent/AdminUserComponents";
import {
  createRestaurant,
  updateRestaurant,
} from "../../store/slice/foodBeverageSlice";
import { SidebarComponent } from "../../helpers/commonFunction";

const CreateFoodBeverage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const restaurant = location?.state?.restaurant;
  const value = location?.state?.value;
  const page = location?.state?.page_number;
  const dispatch = useDispatch();

  const { t } = useTranslation("common");

  const sideHeading = localStorage.getItem("sidebar_component");

  const initialFValues = {
    restaurant_name: restaurant ? restaurant?.restaurant_name : "",
    owner_name: restaurant ? restaurant?.owner_name : "",
    // restaurant_logo: restaurant ? restaurant?.restaurant_logo : "",
    // restaurant_logo_preview: restaurant
    //   ? `${process.env.REACT_APP_FILE_BASE_URL}/${restaurant?.restaurant_logo}`
    //   : "",
    // category_id: restaurant ? restaurant?.category_id : "",
    start_time: restaurant
      ? new Date(dateFunction(restaurant?.start_time))
      : "",
    end_time: restaurant ? new Date(dateFunction(restaurant?.end_time)) : "",
    phone_number: restaurant
      ? "+" + restaurant.country_code + restaurant.phone_number
      : "",
    email: restaurant ? restaurant?.email : "",
    active: restaurant ? restaurant?.active_status : "1",
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var mailReg = new RegExp(mailformat);

    if ("restaurant_name" in fieldValues) {
      temp.restaurant_name = fieldValues.restaurant_name
        ? ""
        : t("errors.restaurant_name");
    }

    if ("owner_name" in fieldValues) {
      temp.owner_name = fieldValues.owner_name ? "" : t("errors.owner_name");
    }

    if ("phone_number" in fieldValues) {
      temp.phone_number = fieldValues.phone_number
        ? isValidPhoneNumber(fieldValues.phone_number)
          ? ""
          : t("errors.phone_valid")
        : t("errors.phone_required");
    }

    if ("email" in fieldValues) {
      if (!fieldValues.email) {
        temp.email = t("errors.email");
      } else {
        temp.email = fieldValues.email.match(mailReg)
          ? ""
          : t("errors.email_val");
      }
    }

    // if ("start_time" in fieldValues) {
    //   temp.start_time = fieldValues.start_time ? "" : t("errors.start_time");
    // }

    // if ("end_time" in fieldValues) {
    //   temp.end_time = fieldValues.end_time ? "" : t("errors.end_time");
    // }

    // if ("restaurant_logo" in fieldValues) {
    //   temp.restaurant_logo = "";
    //   if (!fieldValues.restaurant_logo) {
    //     temp.restaurant_logo = t("errors.logo_require");
    //   }
    //   if (fieldValues.restaurant_logo.size > 5000000) {
    //     temp.restaurant_logo = t("errors.large_img_size");
    //     setValues({
    //       ...values,
    //       restaurant_logo_preview: "",
    //       restaurant_logo: "",
    //     });
    //   }
    //   if (
    //     fieldValues?.restaurant_logo?.name &&
    //     !fieldValues?.restaurant_logo?.name?.match(/.(jpg|jpeg|png|gif)$/i)
    //   ) {
    //     temp.restaurant_logo = t("errors.img_invalid");
    //     setValues({
    //       ...values,
    //       restaurant_logo_preview: "",
    //       restaurant_logo: "",
    //     });
    //   }
    // }

    // if ("category_id" in fieldValues) {
    //   temp.category_id = fieldValues.category_id ? "" : t("errors.category");
    // }

    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  // const onChangeStart = (e) => {
  //   setValues({
  //     ...values,
  //     start_time: e,
  //   });
  //   setErrors({
  //     ...errors,
  //     start_time: "",
  //   });
  // };

  // const onChangeEnd = (e) => {
  //   setValues({
  //     ...values,
  //     end_time: e,
  //   });
  //   setErrors({
  //     ...errors,
  //     end_time: "",
  //   });
  // };

  // const restaurantImageRemoveHandler = () => {
  //   setValues({ ...values, restaurant_logo: "", restaurant_logo_preview: "" });
  // };

  const createRestaurantHandler = async () => {
    if (validate()) {
      const parsedPhone =
        values.phone_number &&
        withoutMetadata(parsePhoneNumber(values.phone_number));

      // const formData = new FormData();
      // let attachmentLogoUrl;
      // if (
      //   values.restaurant_logo &&
      //   typeof values.restaurant_logo !== "string"
      // ) {
      //   formData.append("file", values.restaurant_logo);
      //   attachmentLogoUrl = await dispatch(fileUpload(formData));
      //   formData.delete("file");
      // } else {
      //   attachmentLogoUrl = values.restaurant_logo;
      // }

      const data = {
        restaurant_name: values.restaurant_name,
        // restaurant_logo:
        //   typeof values.restaurant_logo !== "string"
        //     ? attachmentLogoUrl.payload
        //     : attachmentLogoUrl,
        // restaurant_category: values.category_id.value,
        owner_name: values.owner_name,
        email: values.email,
        country_code: parsedPhone ? parsedPhone?.countryCallingCode : "",
        phone_number: parsedPhone ? parsedPhone?.nationalNumber : "",
        active_status: values.active,
      };
      if (!restaurant) {
        data.password = generate_password();
      }

      restaurant
        ? dispatch(
            updateRestaurant({
              data,
              id: restaurant.id,
              toast: t("toast.update_restaurant"),
              navigate,
            })
          )
        : dispatch(
            createRestaurant({
              data,
              navigate,
              toast: t("toast.create_restaurant"),
            })
          );
    }
  };

  return (
    <>
      <div className="comman_btn_container center_back_btn announcement_page-container">
        <h2 className="mobile_configuration_heading">
          {`${SidebarComponent()} > Create`}
        </h2>
        <Link
          to={`${routes.foodBeverage}`}
          state={{ page, value }}
          className="comman_btn ml-auto secondary_btn"
        >
          {t("common.back")}
        </Link>
      </div>
      <div className="project_edit_main_content">
        <div className="create_from_row">
          <Input
            className="create_from_input_content null"
            labelClassName="create_from_label required"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder={t("form.restaurant_place")}
            label={t("form.restaurantName")}
            id="restaurant_name"
            name="restaurant_name"
            value={values?.restaurant_name}
            onChange={handleInputChange}
            error={errors?.restaurant_name || ""}
          />
          <Input
            className="create_from_input_content null"
            labelClassName="create_from_label required"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder={t("form.owner_name_place")}
            label={t("form.owner_name")}
            id="owner_name"
            name="owner_name"
            value={values?.owner_name}
            onChange={handleInputChange}
            error={errors?.owner_name || ""}
          />
          <Input
            className="create_from_input_content null"
            labelClassName="create_from_label required"
            inputClassName={`create_from_input`}
            errorClassName="err_text"
            type="email"
            placeholder={t("form.emailPlace")}
            label={t("form.email")}
            id="email"
            name="email"
            value={values?.email}
            onChange={handleInputChange}
            error={errors?.email || ""}
          />
          <div className="login_input_row mb-0">
            <label className="required">{t("form.phoneNumber")}</label>
            <PhoneInput
              placeholder={t("form.phoneNumberPlace")}
              value={values?.phone_number}
              defaultCountry="TH"
              onChange={(e) => {
                setValues({
                  ...values,
                  phone_number: e,
                });
                setErrors({ ...errors, phone_number: "" });
              }}
            />
            {errors?.phone_number && (
              <span className="err_text">{errors.phone_number}</span>
            )}
          </div>
          {/* <div className="create_from_input_content ">
            <label className="create_from_label required">
              {t("event.start_time")}
            </label>
            <DatePicker
              className="create_from_input mb-5"
              placeholderText={t("event.start_place")}
              selected={values.start_time}
              onChange={(e) => onChangeStart(e)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
            />
            {errors?.start_time && (
              <span className="err_text">{errors?.start_time}</span>
            )}
          </div>
          <div className="create_from_input_content ">
            <label className="create_from_label required">
              {t("event.end_time")}
            </label>
            <DatePicker
              className="create_from_input"
              placeholderText={t("event.end_place")}
              selected={values.end_time}
              onChange={(e) => onChangeEnd(e)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
            />
            {errors?.end_time && (
              <span className="err_text">{errors?.end_time}</span>
            )}
          </div> */}
          <div className={`create_from_input_content`}>
            <label className="create_from_label required">
              {t("form.active_status")}{" "}
            </label>
            <Input
              className="create_from_radio_checkbox_contentainer"
              labelClassName="create_from_radio_label_role"
              inputClassName="create_from_radio_checkbox"
              type="checkbox"
              label={"Active"}
              id={"active"}
              name="active"
              value={values?.active}
              onChange={(e) =>
                setValues({ ...values, active: e.target.checked ? "1" : "0" })
              }
              checked={values?.active === "1"}
            />
          </div>
          {/* <div className="login_input_row mb-0">
            <label className="required">{t("form.category")}</label>
            <Select
              placeholder={t("form.category_place")}
              value={values.category_id}
              className="basic-multi-select"
              classNamePrefix="select"
              name="category_id"
              options={categoryOptions}
              onChange={(e) => handleInputChange(e, "category_id")}
            />
            {errors.category_id && (
              <span className="err_text">{errors.category_id}</span>
            )}
          </div>
          <div className="project_edit_detail_divider">
            <label className="create_from_label required">
              {t("directory.logo")}
            </label>
            <div className="project_edit_feature_input_file">
              <Input
                className="create_from_input_content"
                labelClassName="create_from_label"
                errorClassName="err_text"
                type="file"
                id={`restaurant_logo`}
                labelOtherProps={
                  <span>{values.restaurant_logo ? editIcon : addIcon}</span>
                }
                name="restaurant_logo"
                accept="image/*"
                onChange={handleInputChange}
                onClick={(e) => (e.target.value = null)}
                imageProps={imagePreviewProps(
                  values.restaurant_logo,
                  values.restaurant_logo_preview,
                  restaurantImageRemoveHandler
                )}
              />
            </div>
            <p className="create_project_review mb-0 block">
              {t("form.xsmall_size_desc")}
            </p>
            {errors?.restaurant_logo && (
              <span className="err_text">{errors?.restaurant_logo}</span>
            )}
          </div> */}
        </div>
        <Button
          className="project_submit_bottom_btn center_back_btn"
          buttonClassName="comman_btn"
          onClick={createRestaurantHandler}
          text={`${restaurant ? t("common.save") : t("common.create")}`}
        />
      </div>
    </>
  );
};

export default CreateFoodBeverage;

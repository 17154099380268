import moment from "moment";
import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "../../component/Common/Button";
import Input from "../../component/Common/Input";
import Loader from "../../component/Common/Loader";
import Pagination from "../../component/Pagination/Pagination";
import { routes } from "../../constants";
import { useForm } from "../../hooks/useForm";
import { deleteIcon, editIcon, searchIcon, viewIcon } from "../../icons";
import { getContact } from "../../store/slice/contactSlice";
import DeleteContactModal from "./DeleteContactModal";
import ViewModalContact from "./ViewModalContact";
import {
  SidebarComponent,
  defaultTimeZone,
} from "../../helpers/commonFunction";
import ViewImageModal from "../../component/Common/PageComponent/ViewImageModal";

const Contacts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { page_number } = params;
  const [pageN, setPageN] = useState(page_number - 1);
  const { t } = useTranslation("common");

  const sideHeading = localStorage.getItem("sidebar_component");

  const location = useLocation();
  const value = location?.state?.value;
  const valuePage = location?.state?.valuePage;

  const contacts = useSelector((state) => state.contact.contactList?.data);
  const contactLoader = useSelector((state) => state.contact.loader);
  const contactCount = useSelector((state) => state.contact.contactList?.count);
  let fileUploadLoader = useSelector((state) => state.fileUpload.loader);

  const [apiErr, setApiErr] = useState(false);

  const initialFValues = {
    search_data: value?.search_data ? value?.search_data : "",
    phone_number: value?.phone_number ? value?.phone_number : "",
    deleteContactModal: false,
    deleteContactData: "",
    viewModal: false,
    viewModalData: "",
  };

  const { values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
  );

  const ref = useRef(false);

  useEffect(() => {
    // if (values.search_data) {
    if (ref.current) {
      navigate(`${routes.contact}/page/1`);
      if (+pageN === 0) {
        const timer = setTimeout(() => {
          dispatch(
            getContact({
              search_data: values.search_data,
              phone_number: values.phone_number,
              noAuthority: (error) => {
                if (error) {
                  console.log("err", error);
                  setApiErr(true);
                } else {
                  setApiErr(false);
                }
              },
            })
          );
        }, 500);
        return () => clearTimeout(timer);
      }
    }
  }, [dispatch, values.search_data, values.phone_number]);

  useEffect(() => {
    if (ref.current) {
      dispatch(
        getContact({
          start: pageN,
          search_data: values.search_data,
          phone_number: values.phone_number,
          noAuthority: (error) => {
            if (error) {
              console.log("err", error);
              setApiErr(true);
            } else {
              setApiErr(false);
            }
          },
        })
      );
    }
  }, [pageN]);

  useEffect(() => {
    dispatch(
      getContact({
        start: (valuePage - 1) * 10,
        search_data: values.search_data,
        phone_number: values.phone_number,
        noAuthority: (error) => {
          if (error) {
            console.log("err", error);
            setApiErr(true);
          } else {
            setApiErr(false);
          }
        },
      })
    );
    ref.current = true;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const viewContactHandler = (menu) => {
    setValues({ ...values, viewModal: true, viewModalData: menu });
  };

  const viewHandleClose = () => {
    setValues({ ...values, viewModal: false, viewModalData: "" });
  };

  const deleteContactHandler = (item) => {
    setValues({ ...values, deleteContactModal: true, deleteContactData: item });
  };

  const handleClose = () => {
    setValues({ ...values, deleteContactModal: false, deleteContactData: "" });
  };

  const onPageChange = (page_number) => {
    if (ref.current) {
      setPageN(page_number);
    }
  };

  const [imgZoom, setImgZoom] = useState(false);
  const [imgSrc, setImgSrc] = useState("");

  // if (fileUploadLoader || contactLoader) {
  //   return <Loader />;
  // }

  return (
    <>
      <div className="project_edit_main_content">
        <div className="rolemanagement_project_btn_row_flex">
          <h2 className="project_subheading">{SidebarComponent()}</h2>
          <div className="creat_edit_project_btn_row creat_edit_project_btn_row_flex">
            <Link
              to={routes.createContact}
              state={{
                value: {
                  search_data: values.search_data,
                  phone_number: values.phone_number,
                },
                page_number,
              }}
              className="comman_btn ml-auto"
            >
              {t("common.create")}
            </Link>
          </div>
        </div>
        <>
          <div className="user_data_search_select_box_row">
            <div className="user_data_search">
              <Input
                inputClassName="user_data_search_input"
                type="search"
                placeholder={t("filter.search_name")}
                id="search_data"
                name="search_data"
                value={values.search_data}
                onChange={handleInputChange}
              />
              <Button
                buttonClassName="user_data_search_btn"
                text={searchIcon}
              />
            </div>
            <div className="user_data_search">
              <Input
                inputClassName="user_data_search_input"
                type="search"
                placeholder={t("filter.search_phone")}
                id="phone_number"
                name="phone_number"
                value={values.phone_number}
                onChange={handleInputChange}
              />
              <Button
                buttonClassName="user_data_search_btn"
                text={searchIcon}
              />
            </div>
          </div>
          {fileUploadLoader || contactLoader ? (
            <Loader />
          ) : contacts?.length === 0 ? (
            <h2 className="ta_c c-grey">No results found</h2>
          ) : !contacts && apiErr ? (
            <h2 className="ta_c c-grey">You have no authority</h2>
          ) : (
            <div className="custom_data_table_content">
              <table className="custom_data_table">
                <thead className="custom_data_table_head">
                  <tr>
                    <th className="custom_data_table_heading">
                      {t("table.profileImage")}
                    </th>
                    <th className="custom_data_table_heading">
                      {t("table.name")}
                    </th>
                    <th className="custom_data_table_heading">
                      {t("table.phoneNumber")}
                    </th>
                    <th className="custom_data_table_heading">
                      {t("table.email")}
                    </th>
                    <th className="custom_data_table_heading">
                      {t("form.company")}
                    </th>
                    <th className="custom_data_table_heading">
                      {t("table.department")}
                    </th>
                    <th className="custom_data_table_heading">
                      {t("form.position")}
                    </th>
                    <th className="custom_data_table_heading">
                      {t("table.createdAt")}
                    </th>
                    <th className="custom_data_table_heading">
                      {t("table.updatedAt")}
                    </th>
                    <th className="custom_data_table_heading">
                      {t("table.action")}
                    </th>
                  </tr>
                </thead>
                <tbody className="custom_data_table_body">
                  {contacts?.map((menu, i) => {
                    return (
                      <tr className="custom_data_table_row" key={menu.id}>
                        <td className="custom_data_table_item table_item">
                          <img
                            src={
                              menu.contact_profile_image
                                ? process.env.REACT_APP_FILE_BASE_URL +
                                  "/" +
                                  menu.contact_profile_image
                                : process.env.REACT_APP_FILE_BASE_URL +
                                  "/45/a30a40a1-510a-420a-bfb6-bedb974e15ed.png"
                            }
                            alt={"contact_profile_image"}
                            className={`custom_data_table_img `}
                            onClick={() => {
                              setImgZoom(true);
                              setImgSrc(
                                menu.contact_profile_image
                                  ? process.env.REACT_APP_FILE_BASE_URL +
                                      "/" +
                                      menu.contact_profile_image
                                  : process.env.REACT_APP_FILE_BASE_URL +
                                      "/45/a30a40a1-510a-420a-bfb6-bedb974e15ed.png"
                              );
                            }}
                          />
                        </td>
                        <td className="custom_data_table_item table_item">
                          {(menu.contact_first_name
                            ? menu.contact_first_name
                            : "") +
                            " " +
                            (+menu.contact_last_name
                              ? menu.contact_last_name
                              : "")}
                        </td>
                        <td className="custom_data_table_item table_item">
                          {/* +
                          {menu.contact_country_code +
                            " " +
                            menu.contact_phone_number} */}
                          {menu.contact_phone_number
                            ? menu.contact_phone_number
                            : "-"}
                        </td>
                        <td className="custom_data_table_item table_item">
                          {menu.email ? menu.email : "-"}
                        </td>
                        <td className="custom_data_table_item table_item">
                          {menu.company ? menu.company : "-"}
                        </td>
                        <td className="custom_data_table_item table_item">
                          {menu.contact_post ? menu.contact_post : "-"}
                        </td>
                        <td className="custom_data_table_item table_item">
                          {menu.contact_position ? menu.contact_position : "-"}
                        </td>
                        <td className="custom_data_table_item table_item">
                          {menu.created_at &&
                            moment
                              .utc(menu.created_at, "YYYYMMDD HH:mm:ss")
                              .clone()
                              .tz(defaultTimeZone)
                              .format("DD/MM/YYYY, hh:mm A")}
                        </td>
                        <td className="custom_data_table_item table_item">
                          {menu.updated_at
                            ? moment
                                .utc(menu.updated_at, "YYYYMMDD HH:mm:ss")
                                .clone()
                                .tz(defaultTimeZone)
                                .format("DD/MM/YYYY, hh:mm A")
                            : "-"}
                        </td>

                        <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                          <div className="custom_data_table_view_edit_btn_item_row">
                            <div className="tooltip">
                              <button
                                className="custom_data_table_view_edit_item_btn"
                                onClick={() => viewContactHandler(menu)}
                              >
                                {viewIcon}
                              </button>
                              <span className="tooltiptext">View</span>
                            </div>
                            <div className="tooltip">
                              <Link
                                to={`${routes.contact}/edit/${menu.id}`}
                                state={{
                                  menu,
                                  value: {
                                    search_data: values.search_data,
                                    phone_number: values.phone_number,
                                  },
                                  page_number,
                                }}
                                className="custom_data_table_view_edit_item_btn"
                              >
                                {editIcon}
                              </Link>
                              <span className="tooltiptext">Edit</span>
                            </div>

                            <div className="tooltip">
                              <button
                                className="custom_data_table_view_edit_item_btn"
                                onClick={() => deleteContactHandler(menu)}
                              >
                                {deleteIcon}
                              </button>
                              <span className="tooltiptext">Delete</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
          {+contactCount > 0 && (
            <Pagination
              totalRecords={contactCount}
              onPageChange={onPageChange}
            />
          )}
        </>
        <ViewModalContact
          handleClose={viewHandleClose}
          show={values.viewModal}
          contactDetail={values.viewModalData}
        />
        <DeleteContactModal
          page_number={page_number}
          handleClose={handleClose}
          values={values}
          setValues={setValues}
        />
      </div>
      <ViewImageModal
        setImgZoom={setImgZoom}
        imgZoom={imgZoom}
        imgSrc={imgSrc}
      />
    </>
  );
};

export default Contacts;

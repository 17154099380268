import {
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import Axios, { authHeaders } from "../../helpers/axios";

export const createFeedbackType = createAsyncThunk(
  "createFeedbackType",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/sph/types/feedback`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 || response.status === 201) {
        toast.success(data.toast, {
          autoClose: 3000,
        });

        if (data.cb) {
          return data.cb(null, response.data);
        }
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

export const updateFeedbackType = createAsyncThunk(
  "updateFeedbackType",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/sph/types/feedback/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        if (data.cb) {
          return data.cb(null, response.data);
        }
      }
    } catch (error) {
      console.log("error", error.response);
    }
  }
);

export const updateFeedback = createAsyncThunk(
  "updateFeedback",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/sph/feedbacks/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        toast.success(data.toast, {
          autoClose: 3000,
        });

        return data.cb(null, response.data);
      }
    } catch (error) {
      console.log("error", error.response);
    }
  }
);

export const getFeedback = createAsyncThunk(
  "getFeedback",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/sph/feedbacks?start=${data.start || 0}`,
        authHeaders()
      );
      return { data: response.data, count: response.headers["x-total-count"] };
    } catch (error) {
      if (error.response.status === 403 && data.noAuthority) {
        toast.error(error.response.data.message, {
          autoClose: 3000,
        });
        return data.noAuthority(error.response.data);
      }
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

export const getFeedbackTypes = createAsyncThunk(
  "getFeedbackTypes",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(`/sph/types/feedback`, authHeaders());
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

const feedbackSlice = createSlice({
  name: "feedback",
  initialState: {
    feedbackList: [],
    feedbackTypesList: [],
    createFeedbackTypeComponent: "",
    updateFeedbackTypeComponent: "",
    error: null,
    loader: false,
    updateLoader: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Get Feedback

    builder.addCase(getFeedback.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getFeedback.fulfilled, (state, action) => {
      state.loader = false;
      state.feedbackList = action.payload;
    });
    builder.addCase(getFeedback.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get Feedback Types
    builder.addCase(getFeedbackTypes.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getFeedbackTypes.fulfilled, (state, action) => {
      state.loader = false;
      state.feedbackTypesList = action.payload;
    });
    builder.addCase(getFeedbackTypes.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Create Feedback type
    builder.addCase(createFeedbackType.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(createFeedbackType.fulfilled, (state, action) => {
      state.loader = false;
      state.createFeedbackTypeComponent = action.payload;
    });
    builder.addCase(createFeedbackType.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Update service type
    builder.addCase(updateFeedbackType.pending, (state) => {
      state.loader = true;
      state.updateLoader = true;
    });
    builder.addCase(updateFeedbackType.fulfilled, (state, action) => {
      state.loader = false;
      state.updateFeedbackTypeComponent = action.payload;
    });
    builder.addCase(updateFeedbackType.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default feedbackSlice.reducer;

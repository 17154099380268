import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getJustCoConfig,
  updateJustCoConfig,
} from "../../store/slice/justCoSlice";
import { useTranslation } from "react-i18next";
import Loader from "../../component/Common/Loader";
import moment from "moment";
import { Link } from "react-router-dom";
import { editIcon } from "../../icons";
import { routes } from "../../constants";
import { useRef } from "react";
import { toast } from "react-toastify";
import {
  SidebarComponent,
  defaultTimeZone,
} from "../../helpers/commonFunction";

const JustCoConfig = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const ref = useRef(false);

  const sideHeading = localStorage.getItem("sidebar_component");
  const justCoConfigList = useSelector((state) => state.justCo.justCoList);
  const justCoLoader = useSelector((state) => state.justCo.loader);

  useEffect(() => {
    dispatch(getJustCoConfig());
    ref.current = true;
  }, []);

  const statusOnOff = (item) => {
    const data = {
      is_active: item.is_active === 1 ? 0 : 1,
    };

    dispatch(
      updateJustCoConfig({
        data,
        id: item.id,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            dispatch(getJustCoConfig());
            toast.success("Status updated successfully", { autoClose: 3000 });
          }
        },
      })
    );
  };

  // Publish Un-Publish HANDLER
  // const publishMenu = (item) => {
  //   const data = {
  //     is_active: item.is_active === "1" ? "0" : "1",
  //   };

  //   const isPrivacyPolicyPresent = item.privacy_policy !== null;
  //   const isTAndCPresent = item.t_and_c !== null;
  //   const isTitleAndDescription = item.title && item.description !== null;

  //   let toastMessage = "";

  //   if (item.is_active === "1") {
  //     if (isPrivacyPolicyPresent) {
  //       toastMessage = t("toast.unpub_pp");
  //     } else if (isTAndCPresent) {
  //       toastMessage = t("toast.unpub_tc");
  //     } else if (isTitleAndDescription) {
  //       toastMessage = t("toast.unpub_td");
  //     }
  //   } else {
  //     if (isPrivacyPolicyPresent) {
  //       toastMessage = t("toast.pub_pp");
  //     } else if (isTAndCPresent) {
  //       toastMessage = t("toast.pub_tc");
  //     } else if (isTitleAndDescription) {
  //       toastMessage = t("toast.pub_td");
  //     }
  //   }

  //   dispatch(
  //     updateJustCoConfig({
  //       data,
  //       id: item.id,
  //       cb: (err, res) => {
  //         if (err) {
  //           console.log("err", err);
  //         } else {
  //           dispatch(getJustCoConfig());
  //           toast.success(toastMessage, {
  //             autoClose: 3000,
  //           });
  //         }
  //       },
  //     })
  //   );
  // };

  return (
    <div className="project_edit_main_content">
      <div className="rolemanagement_project_btn_row_flex ">
        <h2 className="project_subheading">{SidebarComponent()}</h2>
        <div className="creat_edit_project_btn_row">
          {/* <Link
            to={routes.editJustCo}
            state={{ staticContent: justCoConfigList }}
            className="comman_btn ml-auto"
          >
            {t("common.create")}
          </Link> */}
        </div>
      </div>
      {justCoLoader ? (
        <Loader />
      ) : justCoConfigList?.length === 0 && !justCoConfigList ? (
        <h2 className="ta_c c-grey">No results found</h2>
      ) : (
        <div className="custom_data_table_content">
          <table className="custom_data_table">
            <thead className="custom_data_table_head">
              <tr>
                <th className="custom_data_table_heading">{t("table.name")}</th>
                <th className="custom_data_table_heading">
                  {t("setting.policy.language")}
                </th>
                <th className="custom_data_table_heading">
                  {t("table.createdAt")}
                </th>
                <th className="custom_data_table_heading">
                  {t("table.updatedAt")}
                </th>

                <th className="custom_data_table_heading">
                  {t("table.action")}
                </th>
              </tr>
            </thead>
            <tbody className="custom_data_table_body">
              {justCoConfigList?.map((item, i) => {
                return (
                  <tr className="custom_data_table_row" key={i}>
                    <td className="custom_data_table_item table_item">
                      {item.api_key &&
                      item.api_client &&
                      item.logo &&
                      item.banner_image !== ""
                        ? t("form.justCo_config")
                        : item.t_and_c
                        ? t("setting.policy.termsCondition")
                        : item.title && item.description
                        ? t("form.landing_page")
                        : t("setting.policy.privacyPolicy")}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {item.language ? item.language : "-"}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {item.created_at
                        ? moment
                            .utc(item.created_at, "YYYYMMDD HH:mm:ss")
                            .clone()
                            .tz(defaultTimeZone)
                            .format("DD/MM/YYYY, hh:mm A")
                        : "-"}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {item.updated_at
                        ? moment
                            .utc(item.updated_at, "YYYYMMDD HH:mm:ss")
                            .clone()
                            .tz(defaultTimeZone)
                            .format("DD/MM/YYYY, hh:mm A")
                        : "-"}
                    </td>

                    <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                      <div className="custom_data_table_view_edit_btn_item_row">
                        {/* {(item.secret_key && item.application_key) ||
                        (item.logo && item.banner_image) !== null ? (
                          ""
                        ) : (
                          <>
                            {item.is_active && (
                              <button
                                className={`custom_data_table_view_edit_item_btn hide_compoenent_btn w-77 ${
                                  item.is_active === "0" ? "green" : "red"
                                }`}
                                onClick={() => publishMenu(item)}
                              >
                                {item.is_active === "1"
                                  ? t("feature.unpublish")
                                  : t("feature.publish")}
                              </button>
                            )}
                          </>
                        )} */}

                        <div className="tooltip">
                          {item.privacy_policy !== null ? (
                            <Link
                              to={routes.editJustCo}
                              state={{ staticContent: item }}
                              className="custom_data_table_view_edit_item_btn"
                            >
                              {editIcon}
                            </Link>
                          ) : item.api_key && item.api_client !== null ? (
                            <Link
                              to={routes.editSecretApplicationKey}
                              state={{ staticContent: item }}
                              className="custom_data_table_view_edit_item_btn"
                            >
                              {editIcon}
                            </Link>
                          ) : item.t_and_c !== null ? (
                            <Link
                              to={routes.editTermsAndConditions}
                              state={{ staticContent: item }}
                              className="custom_data_table_view_edit_item_btn"
                            >
                              {editIcon}
                            </Link>
                          ) : item.logo !== null ? (
                            <Link
                              to={routes.editLogo}
                              state={{ staticContent: item }}
                              className="custom_data_table_view_edit_item_btn"
                            >
                              {editIcon}
                            </Link>
                          ) : item.title && item.description !== null ? (
                            <Link
                              to={routes.editTitleDescription}
                              state={{ staticContent: item }}
                              className="custom_data_table_view_edit_item_btn"
                            >
                              {editIcon}
                            </Link>
                          ) : (
                            "-"
                          )}
                          <span className="tooltiptext">Edit</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default JustCoConfig;

import {
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { routes } from "../../constants";
import Axios, { authHeaders } from "../../helpers/axios";

export const createCountryCode = createAsyncThunk(
  "createCountryCode",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/sph/types/add-country-code`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 || response.status === 201) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        data.navigate(`${routes.countryCodes}/page/1`);
        return response.data;
      }
      data.setLoading(false);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

export const updateCountryCode = createAsyncThunk(
  "updateCountryCode",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/sph/types/country-code/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 && !data.cb) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        data.navigate(`${routes.countryCodes}/page/${data.valuePage}`, {
          state: { valuePage: data.valuePage },
        });
        data.setLoading(false);
        return response.data;
      }
      if (response.status === 200 && data.cb) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        return data.cb(null, response.data);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

export const updateCountryCodeModal = createAsyncThunk(
  "updateCountryCodeModal",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/sph/types/update-country-preference`,
        data.updatedArr,
        authHeaders()
      );
      if (response.status === 200 && !data.cb) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        return response.data;
      }
      if (response.status === 200 && data.cb) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        return data.cb(null, response.data);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

export const getCountryCode = createAsyncThunk(
  "getCountryCode ",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/sph/types/country-codes?search=${data.search || ""}&start=${
          data.start || 0
        }&limit=${data.limit || ""}`,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

export const getAllCountryCodeList = createAsyncThunk(
  "getAllCountryCodeList",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/sph/types/country-codes-for-model?search=&start=0&limit=`,
        authHeaders()
      );

      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

const countrySlice = createSlice({
  name: "country",
  initialState: {
    getCountry: [],
    allList: [],
    createCountry: "",
    updateCountry: "",
    updateCountryModal: "",
    error: null,
    loader: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Create country code
    builder.addCase(createCountryCode.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(createCountryCode.fulfilled, (state, action) => {
      state.loader = false;
      state.createCountry = action.payload;
    });
    builder.addCase(createCountryCode.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Update Country Code
    builder.addCase(updateCountryCode.pending, (state) => {
      state.loader = true;
      state.updateLoader = true;
    });
    builder.addCase(updateCountryCode.fulfilled, (state, action) => {
      state.loader = false;
      state.updateCountry = action.payload;
    });
    builder.addCase(updateCountryCode.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Update Country Code Modal
    builder.addCase(updateCountryCodeModal.pending, (state) => {
      state.loader = true;
      state.updateLoader = true;
    });
    builder.addCase(updateCountryCodeModal.fulfilled, (state, action) => {
      state.loader = false;
      state.updateCountryModal = action.payload;
    });
    builder.addCase(updateCountryCodeModal.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get Countries List
    builder.addCase(getCountryCode.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getCountryCode.fulfilled, (state, action) => {
      state.loader = false;
      state.getCountry = action.payload;
    });
    builder.addCase(getCountryCode.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //Get All Countries List
    builder.addCase(getAllCountryCodeList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getAllCountryCodeList.fulfilled, (state, action) => {
      state.loader = false;
      state.allList = action.payload;
    });
    builder.addCase(getAllCountryCodeList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default countrySlice.reducer;

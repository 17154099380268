import { logout } from "../store/slice/userSlice";
import Axios, { authHeaders } from "./axios";

export const checkAuth = async (dispatch, setErr, Navigate) => {
  try {
    const { data } = await Axios.get("/sph/users/jwt-decode", authHeaders());

    if (data.blocked === "1" || data.delete_status === "1") {
      localStorage.removeItem("error");
      dispatch(logout());
      setErr(null);
      // Navigate(-1);
    }
    if (localStorage.getItem("error")) {
      localStorage.removeItem("error");
      setErr(null);
      Navigate(-1);
    }
  } catch (error) {
    if (error.message === "Network Error") {
      localStorage.setItem("error", "Network Error");
      setErr("Network Error");
    } else if (error?.response?.status === 403) {
      dispatch(logout());
      localStorage.removeItem("error");
      setErr(null);
    } else {
      localStorage.removeItem("error");
      setErr(null);
    }
  }
};

import React, { useRef } from "react";
import { useEffect } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../component/Common/Button";
import Loader from "../../component/Common/Loader";
import MenuListTable from "../../component/Common/Table/MenuListTable";
import { routes } from "../../constants";
import { SidebarComponent, reorder } from "../../helpers/commonFunction";
import { getSidemenu, updateSidemenu } from "../../store/slice/sidemenuSlice";
import { dataForUpdate } from "../../component/Common/PageComponent/SidemenuComponents";
import DeleteSidemenuModal from "./DeleteSidemenuModal";
import { useForm } from "../../hooks/useForm";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import Select from "react-select";
import { getAppType } from "../../store/slice/rolemanagementSlice";
import ViewImageModal from "../../component/Common/PageComponent/ViewImageModal";

const appStatusOptions = [
  { value: "", label: "All" },
  { value: "ACTIVE", label: "Active" },
  { value: "NON_ACTIVE", label: "Non Active" },
];

const SidemenuList = () => {
  const dispatch = useDispatch();
  let menuList = useSelector((state) => state.sidemenu.admin_sidebar);
  let menuLoader = useSelector((state) => state.sidemenu.loader);
  let menuUpdateLoader = useSelector((state) => state.sidemenu.updateLoader);

  const sideHeading = localStorage.getItem("sidebar_component");

  const location = useLocation();
  const value = location?.state?.value;

  const [imgZoom, setImgZoom] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const [apiErr, setApiErr] = useState(false);

  // const [saveDisabled, setSaveDisabled] = useState(false);
  const ref = useRef(false);

  const appTypeList = useSelector((state) => state.rolemanagement.appTypeList);

  const appTypeOptions = appTypeList?.map((item) => {
    return {
      value: item.id,
      label: item.app_type_display_name,
    };
  });

  const userAppTypeOptions = appTypeOptions && [
    { value: "", label: "All" },
    ...appTypeOptions,
  ];

  const { t } = useTranslation("common");

  useEffect(() => {
    dispatch(getAppType());
    // dispatch(getSidemenu({ admin: 1 }));
  }, [dispatch]);

  const initialFValues = {
    role: value?.role ? value?.role : "",
    appStatus: value?.appStatus ? value?.appStatus : "",
    deleteModal: false,
    deleteModalId: "",
  };

  const { values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
  );

  useEffect(() => {
    dispatch(
      getSidemenu({
        admin: 1,
        app_type_id: values.role.value,
        appStatus: values.appStatus.value,
        noAuthority: (error) => {
          if (error) {
            console.log("err", error);
            setApiErr(true);
          } else {
            setApiErr(false);
          }
        },
      })
    );
  }, [values.role, values.appStatus]);

  const handleClose = () => {
    setValues({ ...values, deleteModal: false, deleteModalId: "" });
  };

  const updateSidemenuHandler = (sidemenu, action) => {
    const data = dataForUpdate(sidemenu, action);

    dispatch(
      updateSidemenu({
        data,
        id: sidemenu.id,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            action === "delete"
              ? toast.error(t("toast.delete_sidemenu"), {
                  autoClose: 3000,
                })
              : toast.success(
                  `${t("sidemenu.head")} ${
                    sidemenu.hidden === "0"
                      ? t("feature.deactivated")
                      : t("feature.activated")
                  } ${t("amenity.successfully")}`,
                  {
                    autoClose: 3000,
                  }
                );
            setValues({ ...values, deleteModal: false, deleteModalId: "" });
            dispatch(
              getSidemenu({
                admin: 1,
                app_type_id: values.role.value,
                appStatus: values.appStatus.value,
              })
            );
          }
        },
      })
    );
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(
      menuList,
      result.source.index,
      result.destination.index
    );
    // setSaveDisabled(true);
    ref.current = true;
    menuList = items;
  };

  const saveSidemenuHandler = () => {
    for (let i = 0; i < menuList.length; i++) {
      const data = {
        sidemenu_title: menuList[i].sidemenu_title,
        sidemenu_icon: menuList[i].sidemenu_icon,
        order_number: i + 1,
      };

      dispatch(
        updateSidemenu({
          data,
          id: menuList[i].id,
          toast: 1,
          // eslint-disable-next-line no-loop-func
          cb: (err, res) => {
            if (err) {
              console.log("err", err);
            } else {
              if (i === menuList.length - 1) {
                dispatch(
                  getSidemenu({
                    admin: 1,
                    app_type_id: values.role.value,
                    appStatus: values.appStatus.value,
                  })
                );
                toast.success(t("toast.update_sidemenu"), {
                  autoClose: 2000,
                });
              }
              // setSaveDisabled(false);
              ref.current = false;
            }
          },
        })
      );
    }
  };

  if (menuLoader || menuUpdateLoader) {
    return <Loader />;
  }

  return (
    <>
      <div className="project_edit_main_content">
        <div className="rolemanagement_project_btn_row_flex">
          <h2 className="project_subheading mb-20">{SidebarComponent()}</h2>
          <div className="creat_edit_project_btn_row">
            <Link to={routes.createSidemenu} className="comman_btn ml-auto">
              {t("common.create")}
            </Link>
          </div>
        </div>

        <div className="sidemenu_btn-container">
          {/* {menuList?.length > 0 && (
          <Button
            buttonClassName={`comman_btn ${!ref.current && "disabled_btn"}`}
            className="create_from_bottom_btn"
            onClick={() => saveSidemenuHandler()}
            text={t("common.save")}
            disabled={!ref.current}
          />
        )} */}
          <div className="user_data_search_select_box_row mb-0">
            <div className="user_data_search w-230">
              <Select
                placeholder={t("filter.search_sidebar_status")}
                value={values.appStatus}
                onChange={(e) => handleInputChange(e, "appStatus")}
                className="basic-multi-select"
                classNamePrefix="select"
                name="appStatus"
                options={appStatusOptions}
              />
            </div>
            <div className="user_data_search w-230">
              <Select
                placeholder={t("filter.search_app_type")}
                value={values.role}
                onChange={(e) => handleInputChange(e, "role")}
                className="basic-multi-select"
                classNamePrefix="select"
                name="role"
                options={userAppTypeOptions}
              />
            </div>
          </div>
        </div>

        {menuList?.length === 0 ? (
          <h2 className="ta_c c-grey">No results found</h2>
        ) : !menuList && apiErr ? (
          <h2 className="ta_c c-grey">You have no authority</h2>
        ) : (
          <DragDropContext onDragEnd={(e) => onDragEnd(e)}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  className="custom_data_table_content"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  <Button
                    buttonClassName={`comman_btn mb-15 ${
                      !ref.current && "disabled_btn"
                    }`}
                    className="create_from_bottom_btn"
                    onClick={() => saveSidemenuHandler()}
                    text={t("common.save")}
                    disabled={!ref.current}
                  />
                  <MenuListTable
                    list={menuList}
                    updateMenu={updateSidemenuHandler}
                    name={"sidemenu"}
                    values={values}
                    setValues={setValues}
                    setImgZoom={setImgZoom}
                    setImgSrc={setImgSrc}
                  />
                  {menuList?.length > 0 && (
                    <Button
                      buttonClassName={`comman_btn save_featured ${
                        !ref.current && "disabled_btn"
                      }`}
                      className="create_from_bottom_btn"
                      onClick={() => saveSidemenuHandler()}
                      text={t("common.save")}
                      disabled={!ref.current}
                    />
                  )}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
        <DeleteSidemenuModal
          values={values}
          handleClose={handleClose}
          deleteSidemenuHandler={updateSidemenuHandler}
        />
      </div>
      <ViewImageModal
        setImgZoom={setImgZoom}
        imgZoom={imgZoom}
        imgSrc={imgSrc}
      />
    </>
  );
};

export default SidemenuList;

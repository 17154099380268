import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getUserDetail } from "../../store/slice/userSlice";
import { defaultTimeZone } from "../../helpers/commonFunction";

const UserDetailPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;

  const location = useLocation();
  const locationState = location?.state;

  const { t } = useTranslation("common");

  const userInfo = useSelector((state) => state.user.userDetail?.data);

  useEffect(() => {
    dispatch(getUserDetail({ id }));
  }, []);
  return (
    <div className="project_edit_main_content">
      <div className="creat_edit_project_btn_row user_detail_component_container">
        <button
          onClick={() => {
            locationState?.page_number
              ? navigate(`/users/page/${locationState?.page_number}`, {
                  state: {
                    value: locationState?.value,
                    value_page: locationState?.page_number,
                  },
                })
              : navigate(-1);
          }}
          className="comman_btn ml-auto secondary_btn"
        >
          {t("common.back")}
        </button>
      </div>
      <div className="about_page_section">
        <div className="about_detaile_row">
          <div className="about_detaile_text_content">
            <h2>{t("table.profilePhoto")} </h2>
            <img
              src={
                userInfo?.profile_image
                  ? process.env.REACT_APP_FILE_BASE_URL +
                    "/" +
                    userInfo?.profile_image
                  : process.env.REACT_APP_FILE_BASE_URL +
                    "/45/a30a40a1-510a-420a-bfb6-bedb974e15ed.png"
              }
              alt="user profile"
              className="user_profile"
            />
          </div>
          <div className="about_detaile_text_content">
            <h2>{t("table.name")} </h2>
            <p>
              {userInfo?.first_name} {userInfo?.last_name}
            </p>
          </div>
          <div className="about_detaile_text_content">
            <h2>{t("table.phoneNumber")}</h2>
            <p>
              {userInfo?.phone_number && userInfo?.phone_number !== "null"
                ? "+" + userInfo?.country_code + " " + userInfo?.phone_number
                : "-"}
            </p>
          </div>
          <div className="about_detaile_text_content">
            <h2>{t("table.email")} </h2>
            <p>{userInfo?.email}</p>
          </div>
          <div className="about_detaile_text_content">
            <h2>{t("table.role")} </h2>
            <p>{userInfo?.role_display_name}</p>
          </div>

          <div className="about_detaile_text_content">
            <h2>{t("table.app_type")}</h2>
            <p>{userInfo?.app_type_display_name}</p>
          </div>
          {userInfo?.role_display_name === "Tenant" && (
            <>
              <div className="about_detaile_text_content">
                <h2>{t("form.company")}</h2>
                <p>{userInfo?.company_name ? userInfo?.company_name : "-"}</p>
              </div>
              <div className="about_detaile_text_content">
                <h2>{t("table.department")}</h2>
                <p>
                  {userInfo?.department_name ? userInfo?.department_name : "-"}
                </p>
              </div>
              <div className="about_detaile_text_content">
                <h2>{t("form.position")}</h2>
                <p>{userInfo?.position ? userInfo?.position : "-"}</p>
              </div>
            </>
          )}
          <div className="about_detaile_text_content">
            <h2>{t("table.createdAt")}</h2>
            <p>
              {moment
                .utc(userInfo?.created_at, "YYYYMMDD HH:mm:ss")
                .clone()
                .tz(defaultTimeZone)
                .format("DD/MM/YYYY, hh:mm A")}
            </p>
          </div>
          <div className="about_detaile_text_content">
            <h2>{t("table.updatedAt")} </h2>
            <p>
              {userInfo?.updated_at
                ? moment
                    .utc(userInfo?.updated_at, "YYYYMMDD HH:mm:ss")
                    .clone()
                    .tz(defaultTimeZone)
                    .format("DD/MM/YYYY, hh:mm A")
                : "-"}
            </p>
          </div>
          <div className="about_detaile_text_content">
            <h2>{t("table.lastActiveAt")} </h2>
            <p>
              {userInfo?.last_login
                ? moment
                    .utc(userInfo?.last_login, "YYYYMMDD HH:mm:ss")
                    .clone()
                    .tz(defaultTimeZone)
                    .format("DD/MM/YYYY, hh:mm A")
                : "-"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetailPage;

import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  getAnnouncement,
  updateAnnouncement,
} from "../../store/slice/announcementSlice";
import { SidebarComponent } from "../../helpers/commonFunction";

const DeleteAnnouncementModal = ({
  handleClose,
  values,
  setValues,
  page_number,
}) => {
  const showHideClassName =
    values?.announcementDeleteModal && "user_detail_modal_show";
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const sideHeading = localStorage.getItem("sidebar_component");

  const deleteAnnouncementHandler = () => {
    const data = {
      delete_status: "1",
    };
    dispatch(
      updateAnnouncement({
        data,
        id: values.deleteModalId,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            dispatch(
              getAnnouncement({
                start: (page_number - 1) * 10,
                announcement_type: values.announcement_type,
                announcement_title: values.announcement_title,
              })
            );
            setValues({
              ...values,
              announcementDeleteModal: false,
              deleteModalId: "",
            });
          }
        },
        toastText: t("toast.delete_announcement"),
      })
    );
  };

  return (
    <>
      <div
        className={`user_detail_modal_bg  ${showHideClassName}`}
        onClick={handleClose}
      ></div>
      <section
        className={`user_detail_modal_main delete_feedback_modal ${showHideClassName}`}
      >
        <div className="inquiry_complaint_detail_section">
          <h2 className="announcement_page-view-modal">
            Delete {SidebarComponent()}
          </h2>
          <h4 className="announcement_page-view-modal delete_feedback_subheading">
            {t("modal.delete.deleteAnnouncementDesc")}
          </h4>
          <div className="delete_feedback_btn-container">
            <button
              type="button"
              onClick={handleClose}
              className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn secondary_btn"
            >
              {t("common.close")}
            </button>
            <button
              type="button"
              onClick={deleteAnnouncementHandler}
              className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn ml-20"
            >
              {t("common.delete")}
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default DeleteAnnouncementModal;

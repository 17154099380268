import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../component/Common/Loader";
import Pagination from "../../component/Pagination/Pagination";
import { useForm } from "../../hooks/useForm";
import { getFeedback, getFeedbackTypes } from "../../store/slice/feedbackSlice";
import DeleteFeedbackModal from "./DeleteFeedbackModal";
import FeedbackTable from "./FeedbackTable";
import FeedbackTypeForm from "./FeedbackTypeForm";
import settingImage from "../../images/setting.png";
import { useParams } from "react-router-dom";
import ViewFeedbackModal from "./ViewFeedbackModal";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import DeleteFeedbacktypeModal from "./DeleteFeedbacktypeModal";
import ViewImageModal from "../../component/Common/PageComponent/ViewImageModal";
import { SidebarComponent } from "../../helpers/commonFunction";

const Feedback = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const ref = useRef(false);
  const { page_number } = params;
  const [pageN, setPageN] = useState(page_number - 1);

  const { t } = useTranslation("common");

  const feedbackCount = useSelector(
    (state) => state.feedback.feedbackList?.count
  );

  const sideHeading = localStorage.getItem("sidebar_component");

  const feedbacksList = useSelector(
    (state) => state.feedback.feedbackList?.data
  );

  const feedbackLoader = useSelector((state) => state.feedback.loader);

  const [updateState, setUpdateState] = useState("");
  const [apiErr, setApiErr] = useState(false);

  useEffect(() => {
    if (ref.current) {
      if (+pageN === 0) {
        dispatch(
          getFeedback({
            noAuthority: (error) => {
              if (error) {
                console.log("err", error);
                setApiErr(true);
              } else {
                setApiErr(false);
              }
            },
          })
        );
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (updateState) {
      setValues({
        ...values,
        feedbackType_name: updateState.feedback_type_name
          ? updateState.feedback_type_name
          : "",
        feedbackType_icon: updateState.feedback_type_image
          ? updateState.feedback_type_image
          : "",
        feedbackType_icon_preview: updateState.feedback_type_image
          ? `${process.env.REACT_APP_FILE_BASE_URL}/${updateState.feedback_type_image}`
          : "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateState]);

  const [imgZoom, setImgZoom] = useState(false);
  const [imgSrc, setImgSrc] = useState("");

  const initialFValues = {
    feedbackTypeForm: false,
    feedbackType_name: "",
    feedbackType_icon: "",
    feedbackType_icon_preview: "",
    feedbackEditModal: false,
    editModalData: "",
    deleteModal: false,
    deleteModalData: "",
    viewModal: false,
    viewModalData: "",
    deleteTypeModal: false,
    deleteModalId: "",
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("feedbackType_icon" in fieldValues) {
      temp.feedbackType_icon = "";
      if (!fieldValues.feedbackType_icon) {
        temp.feedbackType_icon = t("errors.icon_require");
      }
      if (fieldValues.feedbackType_icon.size > 1000000) {
        temp.feedbackType_icon = t("errors.small_icon_size");
        setValues({
          ...values,
          feedbackType_icon_preview: "",
          feedbackType_icon: "",
        });
      }
      if (
        fieldValues.feedbackType_icon?.name &&
        !fieldValues.feedbackType_icon?.name?.match(/.(jpg|jpeg|png|gif)$/i)
      ) {
        temp.feedbackType_icon = t("errors.icon_invalid");
        setValues({
          ...values,
          feedbackType_icon_preview: "",
          feedbackType_icon: "",
        });
      }
    }

    if ("feedbackType_name" in fieldValues) {
      temp.feedbackType_name = fieldValues.feedbackType_name
        ? ""
        : t("errors.name");
    }

    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const addFeedbackType = () => {
    dispatch(getFeedbackTypes());
    setValues({ ...values, feedbackTypeForm: true });
  };

  const backFeedbackHandler = () => {
    setValues({
      ...values,
      feedbackTypeForm: false,
      feedbackType_name: "",
      feedbackType_icon: "",
      feedbackType_icon_preview: "",
    });
    setErrors({
      ...errors,
      feedbackType_name: "",
      feedbackType_icon: "",
    });
  };

  const handleCloseHandler = () => {
    setValues({
      ...values,
      deleteTypeModal: false,
      deleteModalId: "",
    });
  };

  const handleClose = () => {
    setValues({
      ...values,
      deleteModal: false,
      deleteModalData: "",
    });
  };

  const handleFeedbackViewClose = () => {
    setValues({
      ...values,
      viewModal: false,
      viewModalData: "",
    });
  };

  useEffect(() => {
    if (ref.current) {
      dispatch(
        getFeedback({
          start: pageN,
          noAuthority: (error) => {
            if (error) {
              console.log("err", error);
              setApiErr(true);
            } else {
              setApiErr(false);
            }
          },
        })
      );
    }
  }, [pageN]);

  useEffect(() => {
    dispatch(
      getFeedback({
        noAuthority: (error) => {
          if (error) {
            console.log("err", error);
            setApiErr(true);
          } else {
            setApiErr(false);
          }
        },
      })
    );
    ref.current = true;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPageChange = (page_number) => {
    if (ref.current) {
      setPageN(page_number);
    }
  };

  if (feedbackLoader) {
    return <Loader />;
  }

  return (
    <>
      <div className="project_edit_main_content">
        <div className="rolemanagement_project_btn_row_flex">
          <h2 className="project_subheading">{SidebarComponent()}</h2>
          {feedbacksList && !apiErr && (
            <div className="creat_edit_project_btn_row">
              {/* {!values.feedbackTypeForm ? (
                <button
                  className="comman_btn setting_btn"
                  onClick={addFeedbackType}
                >
                  <img
                    src={settingImage}
                    alt="setting_img"
                    width={"100%"}
                    height={"100%"}
                  />
                </button>
              ) : (
                <button
                  className="comman_btn ml-auto secondary_btn"
                  onClick={() => backFeedbackHandler()}
                >
                  {t("common.back")}
                </button>
              )} */}
            </div>
          )}
        </div>
        {values.feedbackTypeForm ? (
          <>
            <FeedbackTypeForm
              updateState={updateState}
              values={values}
              setValues={setValues}
              setErrors={setErrors}
              handleInputChange={handleInputChange}
              errors={errors}
              setUpdateState={setUpdateState}
              validate={validate}
            />
          </>
        ) : feedbacksList?.length === 0 ? (
          <h2 className="ta_c c-grey">No results found</h2>
        ) : !feedbacksList && apiErr ? (
          <h2 className="ta_c c-grey">You have no authority</h2>
        ) : (
          <FeedbackTable
            values={values}
            setValues={setValues}
            setImgZoom={setImgZoom}
            setImgSrc={setImgSrc}
          />
        )}
        <DeleteFeedbackModal
          values={values}
          setValues={setValues}
          show={values.deleteModal}
          serviceDetail={values.deleteModalData}
          handleClose={handleClose}
          page_number={page_number}
        />
        <ViewFeedbackModal
          handleClose={handleFeedbackViewClose}
          show={values.viewModal}
          feedbackDetail={values.viewModalData}
        />
        {+feedbackCount > 0 && !values.feedbackTypeForm && (
          <Pagination
            totalRecords={feedbackCount}
            onPageChange={onPageChange}
          />
        )}
      </div>
      <DeleteFeedbacktypeModal
        values={values}
        setValues={setValues}
        handleClose={handleCloseHandler}
      />
      <ViewImageModal
        setImgZoom={setImgZoom}
        imgZoom={imgZoom}
        imgSrc={imgSrc}
      />
    </>
  );
};

export default Feedback;

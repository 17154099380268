import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slice/userSlice";
import dashboardSlice from "./slice/dashboardSlice";
import amenityReducer from "./slice/amenitySlice";
import sidemenuReducer from "./slice/sidemenuSlice";
import fileUploadReducer from "./slice/fileUploadSlice";
import announcementReducer from "./slice/announcementSlice";
import contactReducer from "./slice/contactSlice";
import rolemanagementReducer from "./slice/rolemanagementSlice";
import floorLevelreducer from "./slice/floorLevelSlice";
import directoryreducer from "./slice/directorySlice";
import servicereducer from "./slice/serviceSlice";
import notificationreducer from "./slice/notificationSlice";
import feedbackreducer from "./slice/feedbackSlice";
import adminUserReducer from "./slice/adminUserSlice";
import settingReducer from "./slice/settingSlice";
import eventReducer from "./slice/eventSlice";
import promotionReducer from "./slice/PromotionSlice";
import visitorInviteSlice from "./slice/visitorInviteSlice";
import foodBeverageSlice from "./slice/foodBeverageSlice";
import purposeSlice from "./slice/purposeSlice";
import docTypeSlice from "./slice/docTypeSlice";
import justCoSlice from "./slice/justCoSlice";
import countryCodeSlice from "./slice/countryCodeSlice";

const store = configureStore({
  reducer: {
    dashboard: dashboardSlice,
    adminUser: adminUserReducer,
    user: userReducer,
    amenity: amenityReducer,
    sidemenu: sidemenuReducer,
    announcement: announcementReducer,
    fileUpload: fileUploadReducer,
    contact: contactReducer,
    rolemanagement: rolemanagementReducer,
    floor: floorLevelreducer,
    directory: directoryreducer,
    service: servicereducer,
    notification: notificationreducer,
    feedback: feedbackreducer,
    setting: settingReducer,
    event: eventReducer,
    promotion: promotionReducer,
    visitorInvite: visitorInviteSlice,
    foodBeverage: foodBeverageSlice,
    purpose: purposeSlice,
    docType: docTypeSlice,
    justCo: justCoSlice,
    country: countryCodeSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export default store;

import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "../../component/Common/Button";
import Input from "../../component/Common/Input";
import { routes } from "../../constants";
import { useForm } from "../../hooks/useForm";
import { createFloor, updateFloor } from "../../store/slice/floorLevelSlice";
import { useState } from "react";
import Loader from "../../component/Common/Loader";
import { SidebarComponent } from "../../helpers/commonFunction";

const CreateFloorLevel = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const editFloor = location?.state?.floor;
  const { t } = useTranslation("common");
  const [uploading, setUploading] = useState(false);

  const sideHeading = localStorage.getItem("sidebar_component");

  const initialFValues = {
    floor_name: editFloor ? editFloor?.floor_name : "",
    level_name: editFloor ? editFloor?.level_name : "",
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("floor_name" in fieldValues) {
      temp.floor_name = fieldValues.floor_name ? "" : t("errors.floor");
    }

    if ("level_name" in fieldValues) {
      temp.level_name = fieldValues.level_name ? "" : t("errors.level");
    }

    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { errors, setErrors, values, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const createFloorLevelHandler = () => {
    if (validate()) {
      setUploading(true);
      const data = {
        floor_name: values.floor_name,
        level_name: values.level_name,
      };
      if (editFloor) {
        dispatch(
          updateFloor({
            data,
            id: editFloor?.id,
            navigate,
            toastText: t("toast.update_floor"),
          })
        );
        // setUploading(false);
      } else {
        dispatch(
          createFloor({ data, navigate, toast: t("toast.create_floor") })
        );
        // setUploading(false);
      }
    }
  };

  if (uploading) {
    return <Loader />;
  }

  return (
    <>
      <div className="comman_btn_container center_back_btn announcement_page-container">
        <h2 className="mobile_configuration_heading">
          {editFloor
            ? `${SidebarComponent()} > Edit`
            : `${SidebarComponent()} > Create`}
        </h2>
        <Link
          to={routes.floorLevel}
          className="comman_btn ml-auto secondary_btn"
        >
          {t("common.back")}
        </Link>
      </div>
      <div className="project_edit_main_content">
        <div className="create_from_row">
          <Input
            className="project_edit_feature_input"
            labelClassName="required"
            errorClassName="err_text"
            type="text"
            placeholder={t("floor.floor/levelPlace")}
            label={`${t("floor.floor/level")}`}
            id="floor_name"
            name="floor_name"
            value={values.floor_name}
            onChange={handleInputChange}
            error={errors?.floor_name || ""}
          />
          <Input
            className="project_edit_feature_input"
            labelClassName="required"
            errorClassName="err_text"
            type="text"
            placeholder={t("floor.locationPlace")}
            label={`${t("floor.location")}`}
            id="level_name"
            name="level_name"
            value={values.level_name}
            onChange={handleInputChange}
            error={errors?.level_name || ""}
          />
        </div>
        <Button
          className="project_submit_bottom_btn center_back_btn"
          buttonClassName="comman_btn"
          onClick={createFloorLevelHandler}
          text={`${editFloor ? t("common.save") : t("common.create")}`}
        />
      </div>
    </>
  );
};

export default CreateFloorLevel;

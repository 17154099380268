import React, { useCallback, useEffect, useState } from "react";
import Button from "../../component/Common/Button";
import Input from "../../component/Common/Input";
import {
  SidebarComponent,
  imagePreviewProps,
} from "../../helpers/commonFunction";
import { routes } from "../../constants";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import { useForm } from "../../hooks/useForm";
import TextArea from "../../component/Common/TextArea";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getFloors } from "../../store/slice/floorLevelSlice";
import "react-clock/dist/Clock.css";
import DatePicker from "react-datepicker";
import { addIcon, editIcon } from "../../icons";
import {
  createDirectory,
  getDirectoryTypes,
  updateDirectory,
} from "../../store/slice/directorySlice";
import {
  dateFunction,
  dayName,
  DirectoryData,
  // directoryTypeOptions,
  directoryValidate,
  getInitialDirectoryState,
} from "../../component/Common/PageComponent/DirectoryComponents";
import DirectoryTypeForm from "./DirectoryTypeForm";
import { useTranslation } from "react-i18next";

const CreateDirectory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const directory = location?.state?.directory;
  const page = location?.state?.page_number;
  const value = location?.state?.value;

  const [apiErr, setApiErr] = useState(false);

  const sideHeading = localStorage.getItem("sidebar_component");

  const { t } = useTranslation("common");

  const floorList = useSelector((state) => state.floor.floorsList?.data);

  const directoryTypes = useSelector(
    (state) => state.directory.directoryTypeList
  );

  let fileUploadLoader = useSelector((state) => state.fileUpload.loader);

  const directoryTypeOptions = directoryTypes?.map((directoryType) => {
    return {
      value: directoryType.id,
      label: directoryType.directory_type_name,
    };
  });

  const [updateState, setUpdateState] = useState();

  const directoryFloorLevelOptions = floorList?.map((floor) => {
    return {
      value: floor.level_name + " - " + floor.floor_name,
      label: floor.level_name + " - " + floor.floor_name,
      id: floor.id,
    };
  });

  useEffect(() => {
    dispatch(
      getDirectoryTypes({
        noAuthority: (error) => {
          if (error) {
            console.log("err", error);
            setApiErr(true);
          } else {
            setApiErr(false);
          }
        },
      })
    );
    dispatch(
      getFloors({
        noAuthority: (error) => {
          if (error) {
            console.log("err", error);
            setApiErr(true);
          } else {
            setApiErr(false);
          }
        },
      })
    );
  }, [dispatch]);

  const initialFValues = useCallback(() => {
    return getInitialDirectoryState(directory);
  }, [directory]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    return directoryValidate(
      temp,
      fieldValues,
      values,
      setValues,
      setErrors,
      t
    );
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const onChangeClose = (e, item) => {
    let temp = [...values.directory_opening_times];
    temp[item.weekday - 1]["closed"] = e.target.checked ? "1" : "0";
    temp[item.weekday - 1]["start_hour"] = new Date(
      dateFunction("12:00:00 AM")
    );

    temp[item.weekday - 1]["end_hour"] = new Date(dateFunction("12:00:00 AM"));

    // setValues({
    //   ...values,
    //   active: e.target.checked ? "1" : "0",
    // });
    setValues({
      ...values,
      directory_opening_times: temp,
    });
    setErrors({
      ...errors,
      directory_opening_times: "",
    });
  };

  const onChangeStart = (e, item) => {
    let temp = [...values.directory_opening_times];
    temp[item.weekday - 1]["start_hour"] = e;
    setValues({
      ...values,
      directory_opening_times: temp,
    });
    setErrors({
      ...errors,
      directory_opening_times: "",
    });
  };

  const onChangeEnd = (e, item) => {
    let temp = [...values.directory_opening_times];
    temp[item.weekday - 1]["end_hour"] = e;
    setValues({
      ...values,
      directory_opening_times: temp,
    });
    setErrors({
      ...errors,
      directory_opening_times: "",
    });
  };

  const logoImageRemoveHandler = () => {
    setValues({ ...values, directory_logo: "", directory_logo_preview: "" });
  };

  const mainImageRemoveHandler = () => {
    setValues({
      ...values,
      directory_main_image: "",
      directory_main_image_preview: "",
    });
  };

  const createTypeHandler = () => {
    setValues({ ...values, directoryTypeComponent: true });
  };

  const openingValidation = () => {
    let isValid = false;

    values.directory_opening_times?.filter(
      (item) =>
        (item.start_hour === item.end_hour && item.closed === "0") ||
        (item.start_hour?.getTime() == item.end_hour?.getTime() &&
          item.closed === "0")
    ).length > 0
      ? setErrors({
          ...errors,
          directory_opening_times:
            "You have to set opening & closing time otherwise check closed for that day !",
        })
      : (isValid = true);

    return isValid;
  };

  const createDirectoryHandler = async () => {
    if (validate() && openingValidation()) {
      const data = await DirectoryData(values, dispatch, setValues);

      directory
        ? dispatch(
            updateDirectory({
              data,
              navigate,
              id: directory.id,
              toast: t("toast.update_directory"),
              page,
              value,
            })
          )
        : dispatch(
            createDirectory({
              data,
              navigate,
              toast: t("toast.create_directory"),
            })
          );
    }
  };

  return (
    <>
      {values.directoryTypeComponent ? (
        <>
          <div className="comman_btn_container center_back_btn announcement_page-container">
            <button
              onClick={() => {
                setValues({
                  ...values,
                  directoryTypeComponent: false,
                  directoryType_name: "",
                  directoryType_icon: "",
                  directoryType_icon_preview: "",
                });
                setErrors({
                  ...errors,
                  directoryType_name: "",
                  directoryType_icon: "",
                });
                setUpdateState("");
              }}
              className="comman_btn ml-auto secondary_btn"
            >
              {t("common.back")}
            </button>
          </div>
          <div className="project_edit_main_content">
            <DirectoryTypeForm
              updateState={updateState}
              setUpdateState={setUpdateState}
              values={values}
              setValues={setValues}
              errors={errors}
              setErrors={setErrors}
              handleInputChange={handleInputChange}
            />
          </div>
        </>
      ) : (
        <>
          <div className="comman_btn_container center_back_btn announcement_page-container">
            <h2 className="mobile_configuration_heading">
              {directory
                ? `${SidebarComponent()} > Edit`
                : `${SidebarComponent()} > Create`}
            </h2>
            <Link
              to={`${routes.directory}/page/${page}`}
              state={{ page, value }}
              className="comman_btn ml-auto secondary_btn"
            >
              {t("common.back")}
            </Link>
          </div>
          <div className="project_edit_main_content">
            <div className="create_from_row">
              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label required"
                inputClassName={`create_from_input`}
                errorClassName="err_text"
                type="text"
                placeholder={t("form.retailNamePlace")}
                label={t("form.retailName")}
                id="directory_name"
                name="directory_name"
                value={values.directory_name}
                onChange={handleInputChange}
                error={errors.directory_name || ""}
                // disabled={directory}
              />
              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label required"
                inputClassName="create_from_input"
                errorClassName="err_text"
                type="text"
                placeholder={t("directory.short_description_place")}
                label={t("directory.short_description")}
                id="directory_short_description"
                name="directory_short_description"
                value={values.directory_short_description}
                onChange={handleInputChange}
                error={errors.directory_short_description || ""}
              />
              <TextArea
                className="create_from_input_content "
                labelClassName="create_from_label required"
                inputClassName="create_from_input"
                errorClassName="err_text"
                type="text"
                placeholder={t("announcement.description")}
                label={t("announcement.description_place")}
                id="directory_description"
                name="directory_description"
                value={values.directory_description}
                onChange={handleInputChange}
                error={errors.directory_description || ""}
              />
              <div className="login_input_row">
                <label className="required inline_block">
                  {t("table.type")}
                </label>
                <button className="create_link_btn" onClick={createTypeHandler}>
                  {t("directory.create_directory_type")}
                </button>
                <Select
                  placeholder={t("directory.directory_type_place")}
                  value={values.directory_type}
                  className="basic-multi-select directory_dropdown"
                  classNamePrefix="select"
                  name="directory_type"
                  options={directoryTypeOptions}
                  onChange={(e) => handleInputChange(e, "directory_type")}
                />
                {errors.directory_type && (
                  <span className="err_text">{errors.directory_type}</span>
                )}
              </div>
              <div className="project_edit_detail_divider">
                <label className="create_from_label required">
                  {t("directory.logo")} (1:1)
                </label>
                <div className="project_edit_feature_input_file">
                  <Input
                    className="create_from_input_content"
                    labelClassName="create_from_label"
                    errorClassName="err_text"
                    type="file"
                    id={`directory_logo`}
                    labelOtherProps={
                      <span>{values.directory_logo ? editIcon : addIcon}</span>
                    }
                    name="directory_logo"
                    accept="image/*"
                    onChange={handleInputChange}
                    onClick={(e) => (e.target.value = null)}
                    imageProps={imagePreviewProps(
                      values.directory_logo,
                      values.directory_logo_preview,
                      logoImageRemoveHandler
                    )}
                  />
                </div>
                <p className="create_project_review mb-0 block">
                  {t("form.max_size_one_one")}
                </p>
                {errors.directory_logo && (
                  <span className="err_text">{errors.directory_logo}</span>
                )}
              </div>
              <div className="project_edit_detail_divider">
                <label className="create_from_label required">
                  {t("directory.main_image")} (2:3)
                </label>
                <div className="project_edit_feature_input_file">
                  <Input
                    className="create_from_input_content"
                    labelClassName="create_from_label"
                    errorClassName="err_text"
                    type="file"
                    id={`directory_main_image`}
                    labelOtherProps={
                      <span>
                        {values.directory_main_image ? editIcon : addIcon}
                      </span>
                    }
                    name="directory_main_image"
                    accept="image/*"
                    onChange={handleInputChange}
                    onClick={(e) => (e.target.value = null)}
                    imageProps={imagePreviewProps(
                      values.directory_main_image,
                      values.directory_main_image_preview,
                      mainImageRemoveHandler
                    )}
                  />
                </div>
                <p className="create_project_review mb-0 block">
                  {t("form.max_size_two_three")}
                </p>
                {errors.directory_main_image && (
                  <span className="err_text">
                    {errors.directory_main_image}
                  </span>
                )}
              </div>

              <div className="login_input_row mb-0">
                <label className="requirednot">{t("form.phoneNumber")}</label>
                <PhoneInput
                  placeholder={t("form.phoneNumberPlace")}
                  value={values.directory_contact_number}
                  defaultCountry="TH"
                  onChange={(e) => {
                    setValues({
                      ...values,
                      directory_contact_number: e,
                    });
                    setErrors({ ...errors, directory_contact_number: "" });
                  }}
                />
                {errors.directory_contact_number && (
                  <span className="err_text">
                    {errors.directory_contact_number}
                  </span>
                )}
              </div>

              <div className="login_input_row mb-0">
                <label className="required">{t("floor.floor/level")}</label>
                <Select
                  placeholder={t("floor.selectfloor/level")}
                  value={values.directory_floor_level_id}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  name="directory_floor_level_id"
                  options={directoryFloorLevelOptions}
                  onChange={(e) =>
                    handleInputChange(e, "directory_floor_level_id")
                  }
                />
                {errors.directory_floor_level_id && (
                  <span className="err_text">
                    {errors.directory_floor_level_id}
                  </span>
                )}
              </div>

              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label requirednot"
                inputClassName="create_from_input"
                errorClassName="err_text"
                type="text"
                placeholder={t("directory.website_link_place")}
                label={t("directory.website_link")}
                id="directory_website_link"
                name="directory_website_link"
                value={values.directory_website_link}
                onChange={handleInputChange}
                error={errors.directory_website_link || ""}
              />
              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label requirednot"
                inputClassName="create_from_input"
                errorClassName="err_text"
                type="text"
                placeholder={t("directory.email_link_place")}
                label={t("directory.email_link")}
                id="directory_email_link"
                name="directory_email_link"
                value={values.directory_email_link}
                onChange={handleInputChange}
                error={errors.directory_email_link || ""}
              />
              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label requirednot"
                inputClassName="create_from_input"
                errorClassName="err_text"
                type="text"
                placeholder={t("directory.facebook_link_place")}
                label={t("directory.facebook_link")}
                id="directory_facebook_link"
                name="directory_facebook_link"
                value={values.directory_facebook_link}
                onChange={handleInputChange}
                error={errors.directory_facebook_link || ""}
              />
              <div></div>
            </div>
            <div>
              <label className="create_from_nested_label">
                {t("directory.opening_times")}
              </label>
              <div className="create_from_nested_row">
                {values.directory_opening_times?.map((item, i) => {
                  return (
                    <div className="create_from_input_content " key={i}>
                      <label className="create_from_label">
                        {t(`directory.${dayName[item?.weekday]}`)}
                      </label>
                      <Input
                        className="create_from_radio_checkbox_contentainer"
                        labelClassName="create_from_radio_label_role"
                        inputClassName="create_from_radio_checkbox"
                        type="checkbox"
                        label={"Close"}
                        id={`${item?.weekday - 1}`}
                        name="closed"
                        value={
                          values?.directory_opening_times[item.weekday - 1]
                            .closed
                        }
                        onChange={(e) => {
                          onChangeClose(e, item);
                        }}
                        checked={
                          values?.directory_opening_times[item.weekday - 1]
                            .closed === "1"
                        }
                      />
                      {/* </div> */}
                      <DatePicker
                        className="create_from_input mb-5"
                        placeholderText={t("directory.opening_times_place")}
                        selected={
                          item?.start_hour
                            ? item.start_hour?.getTime() === 1481135400000
                              ? ""
                              : item.start_hour
                            : item?.start_hour
                        }
                        onChange={(e) => onChangeStart(e, item)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        disabled={item?.closed === "1"}
                      />
                      <DatePicker
                        className="create_from_input"
                        placeholderText={t("directory.closing_times_place")}
                        selected={
                          item?.end_hour
                            ? item.end_hour.getTime() === 1481135400000
                              ? ""
                              : item.end_hour
                            : item?.end_hour
                        }
                        onChange={(e) => onChangeEnd(e, item)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        disabled={item?.closed === "1"}
                      />
                    </div>
                  );
                })}
                <div></div>
                <span className="err_text width_max_content">
                  {errors.directory_opening_times || ""}
                </span>
              </div>
            </div>
            {fileUploadLoader ? (
              <div className="project_submit_bottom_btn center_back_btn">
                <span className="comman_btn">Loading...</span>
              </div>
            ) : (
              <Button
                className="project_submit_bottom_btn center_back_btn"
                buttonClassName="comman_btn"
                onClick={createDirectoryHandler}
                text={`${directory ? t("common.save") : t("common.create")}`}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default CreateDirectory;

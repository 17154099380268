import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  getAdminUser,
  updateAdminUser,
} from "../../store/slice/adminUserSlice";
import { SidebarComponent } from "../../helpers/commonFunction";

const DeleteAdminModal = ({ handleClose, values, setValues, sideHeading }) => {
  const { t } = useTranslation("common");
  const showHideClassName = values?.deleteUserModal && "user_detail_modal_show";

  const dispatch = useDispatch();

  const deleteUserHandler = () => {
    const data = {
      delete_status: "1",
    };
    dispatch(
      updateAdminUser({
        data,
        id: values.deleteUserModalData.id,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            dispatch(getAdminUser({}));
            setValues({
              ...values,
              deleteUserModal: false,
              deleteUserModalData: "",
            });
          }
        },
        toast: t("toast.user_delete"),
      })
    );
  };

  return (
    <>
      <div
        className={`user_detail_modal_bg  ${showHideClassName}`}
        onClick={handleClose}
      ></div>
      <section
        className={`user_detail_modal_main delete_feedback_modal ${showHideClassName}`}
      >
        <div className="inquiry_complaint_detail_section">
          <h2 className="announcement_page-view-modal ">
            Delete {SidebarComponent()}
          </h2>
          <h4 className="announcement_page-view-modal delete_feedback_subheading">
            {t("modal.delete.deleteUserDesc")}
          </h4>
          <div className="delete_feedback_btn-container">
            <button
              type="button"
              onClick={handleClose}
              className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn secondary_btn"
            >
              {t("common.close")}
            </button>
            <button
              type="button"
              onClick={deleteUserHandler}
              className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn ml-20 "
            >
              {t("common.delete")}
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default DeleteAdminModal;

import {
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { toast } from "react-toastify";
import { routes } from "../../constants";

export const getJustCoConfig = createAsyncThunk("getJustCoConfig", async () => {
  try {
    const response = await Axios.get(`/sph/types/configuration`, authHeaders());
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message, {
      autoClose: 3000,
    });
    return error.response.data.message;
  }
});

export const updateJustCoConfig = createAsyncThunk(
  "updateJustCoConfig",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/sph/types/configuration/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 && !data.cb) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        data.navigate(routes.justCo);
        return response.data;
      }
      if (data.cb) {
        return data.cb(null, response.data);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

export const createJustCoConfigPage = createAsyncThunk(
  "createJustCoConfigPage",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/sph/types/configuration`,
        data.data,
        authHeaders()
      );
      if ((response.status === 200 || response.status === 201) && !data.cb) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        data.navigate(routes.justCo);
        return response.data;
      }
      if (data.cb) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        data.navigate(routes.justCo);
        return data.cb(null, response.data);
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

const justCoSlice = createSlice({
  name: "justCo",
  initialState: {
    justCoList: [],
    editJustCo: "",
    createJustCo: "",
    isLoading: false,
    error: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    //GET JUST CO CONFIG LIST
    builder.addCase(getJustCoConfig.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getJustCoConfig.fulfilled, (state, action) => {
      state.loader = false;
      state.justCoList = action.payload;
    });
    builder.addCase(getJustCoConfig.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //UPDATE JUST CO CONFIG LIST
    builder.addCase(updateJustCoConfig.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(updateJustCoConfig.fulfilled, (state, action) => {
      state.loader = false;
      state.editJustCo = action.payload;
    });
    builder.addCase(updateJustCoConfig.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //CREATE JUST CO CONFIG LIST
    builder.addCase(createJustCoConfigPage.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(createJustCoConfigPage.fulfilled, (state, action) => {
      state.loader = false;
      state.createJustCo = action.payload;
    });
    builder.addCase(createJustCoConfigPage.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default justCoSlice.reducer;

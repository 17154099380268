import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { routes } from "../../constants";
import Axios, { authHeaders } from "../../helpers/axios";
import { notify } from "../../helpers/commonFunction";

//loginUser
const language = localStorage.getItem("language") || "en";
export const loginUser = createAsyncThunk(
  "loginUser",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post("/sph/users/login", data, {
        headers: {
          lang: language,
        },
      });
      if (response.data.data.app_type !== 2) {
        notify("You are not authorized !");
        throw new Error("You are not authorized !");
      }
      if (response.status === 200) {
        notify("Welcome Back", "success");
        localStorage.setItem("sidebar_component", "Dashboard");
        return response.data;
      }
    } catch (error) {
      notify(error.response.data.message);
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Get User List
export const getUsers = createAsyncThunk("getUsers", async (data, thunkAPI) => {
  try {
    const response = await Axios.get(
      `/sph/users/get-users-list?start=${data.start || 0}&search_key=${
        data.user_name || ""
      }&email=${data.email || ""}&role_display_name=${
        data.role || ""
      }&phone_number=${data.phone_number || ""}`,
      authHeaders()
    );
    return { data: response.data, count: response.headers["x-total-count"] };
  } catch (error) {
    toast.error(error.response.data.message, {
      autoClose: 3000,
    });
    return error.response.data.message;
  }
});

// User Detail By Id
export const getUserDetail = createAsyncThunk(
  "getUserDetail",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/sph/users/get-user-by-id/${data.id}`,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

// Update User
export const updateUser = createAsyncThunk(
  "updateUser",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/sph/users/update-user/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 && !data.cb) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        data.navigate(`${routes.users}/page/${data.page_number}`, {
          state: { value: data.value, value_page: data.page_number },
        });
        return response.data;
      }
      if (data.cb) {
        toast.error(data.toast, {
          autoClose: 3000,
        });
        return data.cb(null, response.data);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      console.log("error", error.response);
    }
  }
);

//createSlice
const initialUser = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : null;

const initialState = {
  userList: [],
  userDetail: "",
  updateUser: "",
  loader: false,
  error: "",
  user: initialUser,
};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      localStorage.removeItem("user");
    },
  },

  extraReducers: (builder) => {
    //loginUser
    builder.addCase(loginUser.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.loader = false;
      state.user = action.payload;
      localStorage.setItem("user", JSON.stringify(action.payload.data));
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //getUsers
    builder.addCase(getUsers.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.loader = false;
      state.userList = action.payload;
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //getUserDetail
    builder.addCase(getUserDetail.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getUserDetail.fulfilled, (state, action) => {
      state.loader = false;
      state.userDetail = action.payload;
    });
    builder.addCase(getUserDetail.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //updateUser
    builder.addCase(updateUser.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.loader = false;
      state.updateUser = action.payload;
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});
export const { logout } = userSlice.actions;
export default userSlice.reducer;

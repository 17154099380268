import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { routes } from "../../constants";
import { deleteIcon, editIcon, searchIcon } from "../../icons";
import { getAdminUser } from "../../store/slice/adminUserSlice";

import Loader from "../../component/Common/Loader";
import { useForm } from "../../hooks/useForm";
import DeleteAdminModal from "./DeleteAdminModal";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import Input from "../../component/Common/Input";
import Button from "../../component/Common/Button";
import { useRef } from "react";
import {
  SidebarComponent,
  defaultTimeZone,
} from "../../helpers/commonFunction";
import ViewImageModal from "../../component/Common/PageComponent/ViewImageModal";

const AdminUsers = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const adminUser = useSelector((state) => state.adminUser.adminUserList);
  const adminUserLoader = useSelector((state) => state.adminUser.loader);

  const [apiErr, setApiErr] = useState(false);

  const location = useLocation();
  const value = location?.state?.value;
  const sideHeading = localStorage.getItem("sidebar_component");

  const userName =
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));

  const initialFValues = {
    user_name: value?.user_name ? value?.user_name : "",
    email: value?.email ? value?.email : "",
    phone_number: value?.phone_number ? value?.phone_number : "",
    deleteUserModal: false,
    deleteUserModalData: "",
  };

  const { values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
  );

  const ref = useRef(false);

  useEffect(() => {
    dispatch(
      getAdminUser({
        name: values.user_name,
        email: values.email,
        phone: values.phone_number,
        noAuthority: (err) => {
          if (err) {
            setApiErr(true);
          } else {
            setApiErr(false);
          }
        },
      })
    );
  }, []);

  useEffect(() => {
    if (ref.current) {
      const timer = setTimeout(() => {
        dispatch(
          getAdminUser({
            name: values.user_name,
            email: values.email,
            phone: values.phone_number,
            noAuthority: (err) => {
              if (err) {
                setApiErr(true);
              } else {
                setApiErr(false);
              }
            },
          })
        );
      }, 500);
      return () => clearTimeout(timer);
    } else {
      ref.current = true;
    }
  }, [values.phone_number, values.user_name, values.email]);

  const deleteUserHandler = (menu) => {
    setValues({ ...values, deleteUserModal: true, deleteUserModalData: menu });
  };

  const handleDeleteUserClose = () => {
    setValues({ ...values, deleteUserModal: false, deleteUserModalData: "" });
  };

  const [imgZoom, setImgZoom] = useState(false);
  const [imgSrc, setImgSrc] = useState("");

  // if (adminUserLoader) {
  //   return <Loader />;
  // }

  return (
    <>
      <div className="project_edit_main_content">
        <div className="rolemanagement_project_btn_row_flex">
          <h2 className="project_subheading">
            {/* {sideHeading} */}
            {SidebarComponent()}
          </h2>
          <div className="creat_edit_project_btn_row">
            <Link to={routes.createAdminUsers} className="comman_btn ml-auto">
              {t("common.create")}
            </Link>
          </div>
        </div>
        <div className="user_data_search_select_box_row">
          <div className="user_data_search">
            <Input
              inputClassName="user_data_search_input"
              type="search"
              placeholder={t("filter.search_name")}
              id="user_name"
              name="user_name"
              value={values.user_name}
              onChange={handleInputChange}
            />
            <Button buttonClassName="user_data_search_btn" text={searchIcon} />
          </div>
          <div className="user_data_search">
            <Input
              inputClassName="user_data_search_input"
              type="search"
              placeholder={t("filter.search_email")}
              id="email"
              name="email"
              value={values.email}
              onChange={handleInputChange}
            />
            <Button buttonClassName="user_data_search_btn" text={searchIcon} />
          </div>
          <div className="user_data_search">
            <Input
              inputClassName="user_data_search_input"
              type="search"
              placeholder={t("filter.search_phone")}
              id="phone_number"
              name="phone_number"
              value={values.phone_number}
              onChange={handleInputChange}
            />
            <Button buttonClassName="user_data_search_btn" text={searchIcon} />
          </div>
        </div>
        <div className="custom_data_table_content">
          {adminUserLoader ? (
            <Loader />
          ) : adminUser?.length === 0 ? (
            <h2 className="ta_c">No results found</h2>
          ) : !adminUser && apiErr ? (
            <h2 className="ta_c">You have no authority</h2>
          ) : (
            <table className="custom_data_table">
              <thead className="custom_data_table_head">
                <tr>
                  <th className="custom_data_table_heading">
                    {" "}
                    {t("table.profileImage")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.name")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.email")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.phoneNumber")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("form.userRole")}
                  </th>
                  {/* <th className="custom_data_table_heading">
                  {t("table.loginType")}
                </th> */}
                  <th className="custom_data_table_heading">
                    {t("table.createdAt")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.updatedAt")}
                  </th>
                  {/* {userName.user_role_id === 2 && ( */}
                  <th className="custom_data_table_heading">
                    {t("table.action")}
                  </th>
                  {/* )} */}
                </tr>
              </thead>
              <tbody className="custom_data_table_body">
                {adminUser?.map((menu, i) => {
                  return (
                    <tr className="custom_data_table_row" key={menu.id}>
                      <td className="custom_data_table_item table_item">
                        <img
                          src={
                            menu.profile_image
                              ? process.env.REACT_APP_FILE_BASE_URL +
                                "/" +
                                menu.profile_image
                              : process.env.REACT_APP_FILE_BASE_URL +
                                "/45/a30a40a1-510a-420a-bfb6-bedb974e15ed.png"
                          }
                          alt={"profile_image"}
                          className={`custom_data_table_img `}
                          onClick={() => {
                            setImgZoom(true);
                            setImgSrc(
                              menu.profile_image
                                ? process.env.REACT_APP_FILE_BASE_URL +
                                    "/" +
                                    menu.profile_image
                                : process.env.REACT_APP_FILE_BASE_URL +
                                    "/45/a30a40a1-510a-420a-bfb6-bedb974e15ed.png"
                            );
                          }}
                        />
                      </td>
                      <td className="custom_data_table_item table_item">
                        {!menu.first_name ||
                        menu.first_name == "" ||
                        menu.first_name == "null"
                          ? "-"
                          : menu.first_name}
                        {!menu.last_name ||
                        menu.last_name == "" ||
                        menu.last_name == "null"
                          ? ""
                          : " " + menu.last_name}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {menu.email ? menu.email : "-"}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {menu.phone_number
                          ? "+" + menu.country_code + " " + menu.phone_number
                          : "-"}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {menu.role_display_name ? menu.role_display_name : "-"}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {menu.created_at &&
                          moment
                            .utc(menu.created_at, "YYYYMMDD HH:mm:ss")
                            .clone()
                            .tz(defaultTimeZone)
                            .format("DD/MM/YYYY, hh:mm A")}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {menu.updated_at
                          ? moment
                              .utc(menu.updated_at, "YYYYMMDD HH:mm:ss")
                              .clone()
                              .tz(defaultTimeZone)
                              .format("DD/MM/YYYY, hh:mm A")
                          : "-"}
                      </td>
                      {(userName.user_role_id === 2 ||
                        menu.user_role_id !== 2) && (
                        <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                          <div className="custom_data_table_view_edit_btn_item_row table_two_btn_row">
                            <div className="tooltip">
                              <Link
                                to={`${routes.adminUsers}/edit/${menu.id}`}
                                state={{
                                  user: menu,
                                  value: {
                                    user_name: values.user_name,
                                    email: values.email,
                                    phone_number: values.phone_number,
                                  },
                                }}
                                className="custom_data_table_view_edit_item_btn"
                              >
                                {editIcon}
                              </Link>
                              <span className="tooltiptext">Edit</span>
                            </div>

                            {userName.user_role_id === 2 &&
                              menu.user_role_id !== 2 && (
                                <div className="tooltip">
                                  <button
                                    className="custom_data_table_view_edit_item_btn"
                                    onClick={() => deleteUserHandler(menu)}
                                  >
                                    {deleteIcon}
                                  </button>
                                  <span className="tooltiptext">Delete</span>
                                </div>
                              )}
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
        <DeleteAdminModal
          handleClose={handleDeleteUserClose}
          values={values}
          setValues={setValues}
          sideHeading={SidebarComponent()}
        />
      </div>
      <ViewImageModal
        setImgZoom={setImgZoom}
        imgZoom={imgZoom}
        imgSrc={imgSrc}
      />
    </>
  );
};

export default AdminUsers;

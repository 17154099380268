import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { routes } from "../../constants";
import { editIcon } from "../../icons";
import {
  getMobileConfigDetail,
  getPolicyPage,
  getTermsPage,
  getToken,
  getVisitorPrivacyPolicy,
  publishStaticPage,
} from "../../store/slice/settingSlice";

import {
  SidebarComponent,
  defaultTimeZone,
} from "../../helpers/commonFunction";

const Settings = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation("common");

  const sideHeading = localStorage.getItem("sidebar_component");
  const staticContent = useSelector((state) => state.setting.policyList);
  const termsContent = useSelector((state) => state.setting.termsList);
  const tokenContent = useSelector((state) => state.setting.token);
  const mobileConfig = useSelector((state) => state.setting.mobileConfigDetail);
  const visitorContent = useSelector((state) => state.setting.visitorPrivacy);

  const test = useSelector((state) => state.setting);
  console.log("mobileConfig", mobileConfig, "test", test);

  useEffect(() => {
    dispatch(getPolicyPage());
    dispatch(getTermsPage());
    dispatch(getToken());
    dispatch(getMobileConfigDetail());
    dispatch(getVisitorPrivacyPolicy());
  }, []);

  const publishMenu = (item, name) => {
    const data = {
      is_active: item.is_active === "1" ? "0" : "1",
    };
    let toastMessage;
    if (name === "t_c") {
      toastMessage =
        item.is_active === "1" ? t("toast.unpub_tc") : t("toast.pub_tc");
    } else if (name === "privacy_policy") {
      toastMessage =
        item.is_active === "1" ? t("toast.unpub_pp") : t("toast.pub_pp");
    } else if (name === "visitor_privacy_policy") {
      toastMessage =
        item.is_active === "1" ? t("toast.unpub_vpp") : t("toast.pub_vpp");
    }
    dispatch(
      publishStaticPage({
        data,
        id: item.id,
        toast: toastMessage,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            if (name === "t_c") {
              dispatch(getTermsPage());
            } else if (name === "privacy_policy") {
              dispatch(getPolicyPage());
            } else {
              dispatch(getVisitorPrivacyPolicy());
            }
          }
        },
      })
    );
  };

  return (
    <>
      <div className="project_edit_main_content">
        <div className="rolemanagement_project_btn_row_flex ">
          <h2 className="project_subheading">{SidebarComponent()}</h2>
          <div className="creat_edit_project_btn_row"></div>
        </div>
        <div className="custom_data_table_content">
          <table className="custom_data_table">
            <thead className="custom_data_table_head">
              <tr>
                <th className="custom_data_table_heading">{t("table.name")}</th>
                <th className="custom_data_table_heading">
                  {t("setting.policy.language")}
                </th>
                <th className="custom_data_table_heading">
                  {t("table.created_by")}
                </th>
                <th className="custom_data_table_heading">
                  {t("table.createdAt")}
                </th>
                <th className="custom_data_table_heading">
                  {t("table.updatedAt")}
                </th>
                <th className="custom_data_table_heading">
                  {t("table.publishedAt")}
                </th>
                <th className="custom_data_table_heading">
                  {t("table.action")}
                </th>
              </tr>
            </thead>
            <tbody className="custom_data_table_body">
              {staticContent?.map((item, i) => {
                return (
                  <tr className="custom_data_table_row" key={i}>
                    <td className="custom_data_table_item table_item">
                      Privacy Policy
                    </td>
                    <td className="custom_data_table_item table_item">
                      {item?.language ? item?.language : "-"}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {item?.user_name ? item?.user_name : "-"}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {item?.created_at
                        ? moment(item?.created_at).format("DD/MM/YYYY, hh:mm A")
                        : "-"}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {item?.updated_at
                        ? moment(item?.updated_at).format("DD/MM/YYYY, hh:mm A")
                        : "-"}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {item?.published_at
                        ? moment(item?.published_at).format(
                            "DD/MM/YYYY, hh:mm A"
                          )
                        : "-"}
                    </td>
                    <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                      <div className="custom_data_table_view_edit_btn_item_row">
                        {item?.is_active && (
                          <button
                            className={`custom_data_table_view_edit_item_btn hide_compoenent_btn w-77 ${
                              item?.is_active === "0" ? "green" : "red"
                            }`}
                            onClick={() => publishMenu(item, "privacy_policy")}
                          >
                            {item?.is_active === "1"
                              ? t("feature.unpublish")
                              : t("feature.publish")}
                          </button>
                        )}
                        <div className="tooltip">
                          <Link
                            to={routes.privacyPolicy}
                            state={{ staticContent: item }}
                            className="custom_data_table_view_edit_item_btn"
                          >
                            {editIcon}
                          </Link>
                          <span className="tooltiptext">Edit</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
              {visitorContent?.map((item, i) => {
                return (
                  <tr className="custom_data_table_row" key={i}>
                    <td className="custom_data_table_item table_item">
                      Visitor Privacy Policy
                    </td>
                    <td className="custom_data_table_item table_item">
                      {item?.language ? item?.language : "-"}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {item?.user_name ? item?.user_name : "-"}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {item?.created_at
                        ? moment(item?.created_at).format("DD/MM/YYYY, hh:mm A")
                        : "-"}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {item?.updated_at
                        ? moment(item?.updated_at).format("DD/MM/YYYY, hh:mm A")
                        : "-"}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {item?.published_at
                        ? moment(item?.published_at).format(
                            "DD/MM/YYYY, hh:mm A"
                          )
                        : "-"}
                    </td>
                    <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                      <div className="custom_data_table_view_edit_btn_item_row">
                        {item?.is_active && (
                          <button
                            className={`custom_data_table_view_edit_item_btn hide_compoenent_btn w-77 ${
                              item?.is_active === "0" ? "green" : "red"
                            }`}
                            onClick={() =>
                              publishMenu(item, "visitor_privacy_policy")
                            }
                          >
                            {item?.is_active === "1"
                              ? t("feature.unpublish")
                              : t("feature.publish")}
                          </button>
                        )}
                        <div className="tooltip">
                          <Link
                            to={routes.visitorPolicy}
                            state={{ visitorContent: item }}
                            className="custom_data_table_view_edit_item_btn"
                          >
                            {editIcon}
                          </Link>
                          <span className="tooltiptext">Edit</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
              {termsContent?.map((item, i) => {
                return (
                  <tr className="custom_data_table_row" key={i}>
                    <td className="custom_data_table_item table_item">
                      Terms & Conditions
                    </td>
                    <td className="custom_data_table_item table_item">
                      {item?.language ? item?.language : "-"}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {item?.user_name ? item?.user_name : "-"}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {item?.created_at
                        ? moment(item?.created_at).format("DD/MM/YYYY, hh:mm A")
                        : "-"}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {item?.updated_at
                        ? moment(item?.updated_at).format("DD/MM/YYYY, hh:mm A")
                        : "-"}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {item?.published_at
                        ? moment(item?.published_at).format(
                            "DD/MM/YYYY, hh:mm A"
                          )
                        : "-"}
                    </td>
                    <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                      <div className="custom_data_table_view_edit_btn_item_row">
                        {item?.is_active && (
                          <button
                            className={`custom_data_table_view_edit_item_btn hide_compoenent_btn w-77 ${
                              item?.is_active === "0" ? "green" : "red"
                            }`}
                            onClick={() => publishMenu(item, "t_c")}
                          >
                            {item?.is_active === "1"
                              ? t("feature.unpublish")
                              : t("feature.publish")}
                          </button>
                        )}
                        <div className="tooltip">
                          <Link
                            to={routes.termsConditions}
                            state={{ termsContent: item }}
                            className="custom_data_table_view_edit_item_btn"
                          >
                            {editIcon}
                          </Link>
                          <span className="tooltiptext">Edit</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
              <tr className="custom_data_table_row">
                <td className="custom_data_table_item table_item">Token</td>
                <td className="custom_data_table_item table_item">{"-"}</td>
                <td className="custom_data_table_item table_item">
                  {tokenContent?.user_name ? tokenContent?.user_name : "-"}
                </td>
                <td className="custom_data_table_item table_item">
                  {tokenContent?.created_at
                    ? moment
                        .utc(tokenContent.created_at, "YYYYMMDD HH:mm:ss")
                        .clone()
                        .tz(defaultTimeZone)
                        .format("DD/MM/YYYY, hh:mm A")
                    : "-"}
                </td>
                <td className="custom_data_table_item table_item">
                  {tokenContent?.updated_at
                    ? moment
                        .utc(tokenContent.updated_at, "YYYYMMDD HH:mm:ss")
                        .clone()
                        .tz(defaultTimeZone)
                        .format("DD/MM/YYYY, hh:mm A")
                    : "-"}
                </td>
                <td className="custom_data_table_item table_item">
                  {tokenContent?.published_at
                    ? moment(tokenContent?.published_at).format(
                        "DD/MM/YYYY, hh:mm A"
                      )
                    : "-"}
                </td>
                <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                  <div className="custom_data_table_view_edit_btn_item_row">
                    <div className="tooltip">
                      <Link
                        to={routes.tokenComponent}
                        className="custom_data_table_view_edit_item_btn"
                      >
                        {editIcon}
                      </Link>
                      <span className="tooltiptext">Edit</span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr className="custom_data_table_row">
                <td className="custom_data_table_item table_item">
                  Mobile App Version Control
                </td>
                <td className="custom_data_table_item table_item">{"-"}</td>
                <td className="custom_data_table_item table_item">-</td>
                <td className="custom_data_table_item table_item">
                  {mobileConfig?.created_at
                    ? moment
                        .utc(mobileConfig?.created_at, "YYYYMMDD HH:mm:ss")
                        .clone()
                        .tz(defaultTimeZone)
                        .format("DD/MM/YYYY, hh:mm A")
                    : "-"}
                </td>
                <td className="custom_data_table_item table_item">
                  {mobileConfig?.update_at
                    ? moment
                        .utc(mobileConfig?.update_at, "YYYYMMDD HH:mm:ss")
                        .clone()
                        .tz(defaultTimeZone)
                        .format("DD/MM/YYYY, hh:mm A")
                    : "-"}
                </td>
                <td className="custom_data_table_item table_item">-</td>
                <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                  <div className="custom_data_table_view_edit_btn_item_row">
                    <div className="tooltip">
                      <Link
                        to={routes.mobileConComponent}
                        state={{ configData: mobileConfig }}
                        className="custom_data_table_view_edit_item_btn"
                      >
                        {editIcon}
                      </Link>
                      <span className="tooltiptext">Edit</span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr className="custom_data_table_row">
                <td className="custom_data_table_item table_item">
                  Country Codes
                </td>
                <td className="custom_data_table_item table_item">{"-"}</td>
                <td className="custom_data_table_item table_item">-</td>
                <td className="custom_data_table_item table_item">-</td>
                <td className="custom_data_table_item table_item">-</td>
                <td className="custom_data_table_item table_item">-</td>
                <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                  <div className="custom_data_table_view_edit_btn_item_row">
                    <div className="tooltip">
                      <Link
                        to={`${routes.countryCodes}/page/1`}
                        // state={{ configData: mobileConfig }}
                        className="custom_data_table_view_edit_item_btn"
                      >
                        {editIcon}
                      </Link>
                      <span className="tooltiptext">Edit</span>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
    // <div className="project_edit_main_content">
    //   <div className="setting_btns_container">
    //     <button
    //       className="zu_loginas-btn zu_login_btn"
    //       onClick={policyHandler}
    //       disabled={policyComponent}
    //     >
    //       {t("setting.policy.privacyPolicy")}
    //     </button>
    //     <button
    //       className="zu_loginas-btn zu_login_btn"
    //       onClick={termsHandler}
    //       disabled={termsComponent}
    //     >
    //       {t("setting.policy.termsCondition")}
    //     </button>
    //     {/* <button
    //       className="zu_loginas-btn zu_login_btn"
    //       onClick={languageHandler}
    //       disabled={languageComponent}
    //     >
    //       {t("setting.policy.language")}
    //     </button> */}
    //     <button
    //       className="zu_loginas-btn zu_login_btn"
    //       onClick={tokenHandler}
    //       disabled={tokenComponent}
    //     >
    //       {t("setting.policy.token")}
    //     </button>
    //   </div>
    //   {/* {staticLoader ? (
    //     <Loader />
    //   ) : ( */}
    //   <div className="create_from_row  settings_container">
    //     {policyComponent && staticLoader ? (
    //       <Loader />
    //     ) : (
    //       policyComponent && (
    //         <PrivacyPolicy
    //           values={values}
    //           setValues={setValues}
    //           staticContent={staticContent}
    //         />
    //       )
    //     )}
    //     {termsComponent && staticLoader ? (
    //       <Loader />
    //     ) : (
    //       termsComponent && (
    //         <TermsConditions
    //           values={values}
    //           setValues={setValues}
    //           termsContent={termsContent}
    //         />
    //       )
    //     )}
    //     {tokenComponent && <Token />}
    //   </div>
    //   {/* )} */}
    // </div>
  );
};

export default Settings;

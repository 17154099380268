import React, { useEffect, useState } from "react";
import Input from "../component/Common/Input";
import Select from "react-select";
import { useForm } from "../hooks/useForm";
import { imagePreviewProps } from "../helpers/commonFunction";
import { addIcon, editIcon } from "../icons";
import PhoneInput from "react-phone-number-input";
import Button from "../component/Common/Button";
import { visitorUserValidation } from "../component/Common/PageComponent/GeneralFormComponents";
import { useDispatch, useSelector } from "react-redux";
import {
  getVisitorInviteById,
  updateVisitorInviteByGeneral,
} from "../store/slice/visitorInviteSlice";
import { useNavigate, useParams } from "react-router-dom";
import { fileUploadVisitor } from "../store/slice/fileUploadSlice";
import CommonDatePicker from "../component/Common/DatePicker";
import moment from "moment";
import { withoutMetadata } from "../component/Common/PageComponent/LoginPageComponents";
import { parsePhoneNumber } from "react-phone-number-input";
import { routes } from "../constants";
import { getDocType } from "../store/slice/docTypeSlice";
import AlreadyFillForm from "./AlreadyFillForm";
import nationalityList from "../helpers/nationality.json";
import { useTranslation } from "react-i18next";

const GeneralForm = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  let fileUploadLoader = useSelector((state) => state.fileUpload.loader);
  const docTypeList = useSelector((state) => state.docType.docTypeList?.data);
  const [alreadyFill, setAlreadyFill] = useState({ fill: false, status: "" });
  const [visitorInvite, setvisitorInvite] = useState({});
  const [vehiclePlateErr, setVehiclePlateErr] = useState("");

  const documentOptions = docTypeList?.map((docType) => {
    return {
      value: docType.id,
      label: docType.title,
      id: docType.id,
    };
  });

  const test = useSelector((state) => state.docType);

  console.log("test", test);
  console.log("docTypeList===>", docTypeList);
  console.log("documentOptions", documentOptions);

  const vehicleTypeOptions = [
    {
      value: "",
      label: "No vehicle",
    },
    {
      value: "Car",
      label: "Car",
    },
    {
      value: "Motorcycle",
      label: "Motorcycle",
    },
  ];

  const nationalityOptions =
    nationalityList &&
    nationalityList.map((item, id) => {
      return {
        value: item.nationality,
        label: item.nationality,
      };
    });

  useEffect(() => {
    dispatch(
      getVisitorInviteById({
        id: params?.id,
        cb: (err, res) => {
          if (res.length !== 0) {
            if (
              res[0].invite_status === "pending_visitor_information" ||
              res[0].invite_status === "pending_approval"
            ) {
              setvisitorInvite(res[0]);
            } else if (
              res[0].invite_status === "approved" ||
              res[0].invite_status === "cancelled" ||
              res[0].invite_status === "rejected"
            ) {
              setAlreadyFill({ fill: true, status: res[0].invite_status });
            }
          } else if (res.length === 0) {
            setAlreadyFill({ fill: true, status: "cancelled" });
          }
          // else if (err) {
          //   console.log("err", err);
          // }
        },
      })
    );
    dispatch(getDocType({}));
  }, []);

  const initialFValues = {
    name: "",
    document_type: "",
    doc_number: "",
    email: "",
    phone_number: "",
    license_plate_number: "",
    vehicle_type: "",
    make: "",
    color: "#000000",
    doc_image: "",
    doc_image_preview: "",
    nationality: "",
    issue_date: "",
    expiry_date: "",
    subvisitor_1: "",
    subvisitor_2: "",
    subvisitor_3: "",
    subvisitor_4: "",
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    return visitorUserValidation(
      fieldValues,
      temp,
      values,
      setValues,
      setErrors,
      t
    );
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  useEffect(() => {
    setValues({
      ...values,
      name: visitorInvite
        ? visitorInvite?.first_name + " " + visitorInvite?.last_name
        : "",
      // email: visitorInvite ? visitorInvite?.email : "",
      phone_number:
        visitorInvite && visitorInvite.visitor_phone_number
          ? "+" +
            visitorInvite.visitor_country_code +
            visitorInvite.visitor_phone_number
          : "",
      tenant_company: visitorInvite ? visitorInvite?.tenant_company : "-",
      company_name: visitorInvite ? visitorInvite?.visitor_company : "-",
      department: visitorInvite ? visitorInvite?.tenant_department : "-",
      tenant_first_name: visitorInvite ? visitorInvite?.tenant_first_name : "-",
      tenant_last_name: visitorInvite ? visitorInvite?.tenant_last_name : "-",
      tenant_position: visitorInvite ? visitorInvite?.tenant_position : "-",
      tenant_email: visitorInvite ? visitorInvite?.tenant_email : "-",
      tenant_contact: visitorInvite?.tenant_country_code
        ? "+" +
          visitorInvite?.tenant_country_code +
          " " +
          visitorInvite?.tenant_phone_number
        : "-",
      tenant_floor: visitorInvite ? visitorInvite?.tenant_floor : "-",
      visit_date: visitorInvite
        ? moment(visitorInvite?.visit_date).format("DD/MM/YYYY HH:mm")
        : "-",
      visit_time: visitorInvite ? visitorInvite?.visit_time : "-",
      purpose: visitorInvite ? visitorInvite?.purpose_title : "-",
      email: visitorInvite ? visitorInvite?.email : "-",
      first_name: visitorInvite ? visitorInvite?.first_name : "-",
      last_name: visitorInvite ? visitorInvite?.last_name : "-",
      license_plate_number: visitorInvite
        ? visitorInvite?.licence_plate_number
        : "",
      vehicle_type: visitorInvite
        ? visitorInvite.vehicle_type
          ? {
              value: visitorInvite.vehicle_type,
              label: visitorInvite.vehicle_type,
            }
          : ""
        : "",
      nationality: visitorInvite?.nationality
        ? {
            value: visitorInvite?.nationality,
            label: visitorInvite?.nationality,
          }
        : "",
      issue_date: visitorInvite?.issue_date
        ? new Date(visitorInvite?.issue_date)
        : "",
      expiry_date: visitorInvite?.expiry_date
        ? new Date(visitorInvite?.expiry_date)
        : "",
      doc_number: visitorInvite?.document_number
        ? visitorInvite?.document_number
        : "",
      document_type: visitorInvite?.document_type_title
        ? {
            label: visitorInvite?.document_type_title,
            value: visitorInvite.document_type,
          }
        : "",
      doc_image: visitorInvite?.document_image
        ? visitorInvite?.document_image
        : "",
      subvisitor_1: visitorInvite?.subvisitor_1
        ? visitorInvite?.subvisitor_1
        : "",
      subvisitor_2: visitorInvite?.subvisitor_2
        ? visitorInvite?.subvisitor_2
        : "",
      subvisitor_3: visitorInvite?.subvisitor_3
        ? visitorInvite?.subvisitor_3
        : "",
      subvisitor_4: visitorInvite?.subvisitor_4
        ? visitorInvite?.subvisitor_4
        : "",
      doc_image_preview: visitorInvite?.document_image
        ? process.env.REACT_APP_FILE_BASE_URL +
          "/" +
          visitorInvite?.document_image
        : "",
    });
  }, [visitorInvite]);

  const imageRemoveHandler = () => {
    setValues({ ...values, doc_image: "", doc_image_preview: "" });
  };

  const changeVehTypeHandler = (e) => {
    if (e.value === "") {
      setValues({
        ...values,
        vehicle_type: e,
        license_plate_number: "",
      });
      setVehiclePlateErr("");
    } else {
      setValues({
        ...values,
        vehicle_type: e,
      });
      setVehiclePlateErr("");
    }
  };

  const vehicleValidate = () => {
    if (values.vehicle_type === "" || values.vehicle_type.value === "") {
      return true;
    } else if (values.license_plate_number.trim() !== "") {
      return true;
    } else {
      setVehiclePlateErr("Please enter licence plate number!");
      return false;
    }
  };

  const createVisitorHandler = async () => {
    if (validate() && vehicleValidate()) {
      const formData = new FormData();
      let attachmentUrl;
      if (values.doc_image && typeof values.doc_image !== "string") {
        formData.append("file", values.doc_image);
        attachmentUrl = await dispatch(fileUploadVisitor(formData));
        if (attachmentUrl && attachmentUrl.payload) {
          setValues({
            ...values,
            doc_image: attachmentUrl.payload,
          });
        }
        formData.delete("file");
      } else {
        attachmentUrl = values.doc_image;
      }

      const parsedPhone =
        values.phone_number &&
        withoutMetadata(parsePhoneNumber(values.phone_number));

      const data = {
        visitor_code: params?.id,
        document_type: values.document_type.value,
        document_number: values.doc_number,
        document_image:
          typeof values.doc_image !== "string"
            ? attachmentUrl.payload
            : attachmentUrl,
        licence_plate_number: values.license_plate_number,
        // make: values.make,
        // color: values.color,
        nationality: values.nationality?.value,
        vehicle_type: values.vehicle_type?.value,
        issue_date: moment(values.issue_date).format("YYYY-MM-DD"),
        expiry_date: moment(values.expiry_date).format("YYYY-MM-DD"),
        visitor_country_code: parsedPhone?.countryCallingCode,
        visitor_phone_number: parsedPhone?.nationalNumber,
        subvisitor_1: values.subvisitor_1 ? values.subvisitor_1 : null,
        subvisitor_2: values.subvisitor_2 ? values.subvisitor_2 : null,
        subvisitor_3: values.subvisitor_3 ? values.subvisitor_3 : null,
        subvisitor_4: values.subvisitor_4 ? values.subvisitor_4 : null,
      };

      dispatch(
        updateVisitorInviteByGeneral({
          data,
          cb: (err, res) => {
            if (err) {
              // console.log("err", err);
            } else {
              setValues({
                ...values,
                // name: "",
                document_type: "",
                doc_number: "",
                first_name: "",
                last_name: "",
                email: "",
                phone_number: "",
                license_plate_number: "",
                doc_image: "",
                doc_image_preview: "",
                issue_date: "",
                expiry_date: "",
                nationality: "",
                vehicle_type: "",
                purpose: "",
                visit_time: "",
                visit_date: "",
                tenant_floor: "",
                tenant_contact: "",
                tenant_email: "",
                tenant_position: "",
                tenant_last_name: "",
                tenant_first_name: "",
                department: "",
                company_name: "",
                tenant_company: "",
              });
              navigate(routes.completionForm);
            }
          },
        })
      );
    }
  };

  return (
    <>
      {alreadyFill.fill ? (
        <AlreadyFillForm alreadyFill={alreadyFill} />
      ) : (
        <section className="login_section">
          <div className="login_content m-w_login-content">
            <h2 className="project_subheading table_item mt-10">
              Visitor Information
            </h2>
            <div className="create_from_row">
              <div className="visitor_infom_form_col">
                <h2 className="visitor_infom_form_heading">Tenant Details</h2>
              </div>
              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label"
                inputClassName="create_from_input visitor_disabled_input"
                label="Company Name"
                value={values?.tenant_company}
                disabled={true}
              />
              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label"
                inputClassName="create_from_input visitor_disabled_input"
                label="Department"
                value={values?.department}
                disabled={true}
              />
              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label"
                inputClassName="create_from_input visitor_disabled_input"
                label="First Name"
                value={values?.tenant_first_name}
                disabled={true}
              />
              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label"
                inputClassName="create_from_input visitor_disabled_input"
                label="Last Name"
                value={values?.tenant_last_name}
                disabled={true}
              />
              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label"
                inputClassName="create_from_input visitor_disabled_input"
                label="Position"
                value={values?.tenant_position}
                disabled={true}
              />
              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label"
                inputClassName="create_from_input visitor_disabled_input"
                label="Email"
                value={values?.tenant_email}
                disabled={true}
              />
              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label"
                inputClassName="create_from_input visitor_disabled_input"
                label="Contact"
                value={values?.tenant_contact}
                disabled={true}
              />
              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label"
                inputClassName="create_from_input visitor_disabled_input"
                label="Floor"
                value={values?.tenant_floor}
                disabled={true}
              />
              <div className="visitor_infom_form_col">
                <h2 className="visitor_infom_form_heading">Visitor Details</h2>
              </div>
              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label"
                inputClassName="create_from_input visitor_disabled_input"
                label="Date / Time"
                value={moment(values?.visit_date, "DD-MM-YYYYTHH:mm:ss").format(
                  "DD/MM/YYYY hh:mm A"
                )}
                disabled={true}
              />
              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label"
                inputClassName="create_from_input visitor_disabled_input"
                label="Purpose"
                value={values?.purpose}
                disabled={true}
              />
              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label"
                inputClassName="create_from_input visitor_disabled_input"
                label="Company Name"
                value={values?.company_name}
                disabled={true}
              />
              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label"
                inputClassName="create_from_input visitor_disabled_input"
                label="Email"
                value={values?.email}
                disabled={true}
              />
              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label"
                inputClassName="create_from_input visitor_disabled_input"
                label="First Name"
                value={values?.first_name}
                disabled={true}
              />
              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label"
                inputClassName="create_from_input visitor_disabled_input"
                label="Last Name"
                value={values?.last_name}
                disabled={true}
              />
              {/* <Input
                className="create_from_input_content null"
                labelClassName="create_from_label"
                inputClassName="create_from_input visitor_disabled_input"
                errorClassName="err_text"
                type="text"
                placeholder="Enter Name"
                label="Name"
                id="name"
                name="name"
                value={values?.name}
                onChange={handleInputChange}
                error={errors?.name || ""}
                disabled={true}
              /> */}
              <div className="project_edit_feature_input mt-0">
                <label className="create_from_label required">
                  Document Type
                </label>
                <Select
                  placeholder="Select Document Type"
                  value={values?.document_type}
                  className="basic-multi-select z-999999"
                  classNamePrefix="select"
                  name="document_type"
                  options={documentOptions}
                  onChange={(e) => handleInputChange(e, "document_type")}
                />
                {errors?.document_type && (
                  <span className="err_text">{errors?.document_type}</span>
                )}
              </div>
              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label required"
                inputClassName="create_from_input"
                errorClassName="err_text"
                type="text"
                placeholder="Enter Document number"
                label="Document Number"
                id="doc_number"
                name="doc_number"
                value={values?.doc_number}
                onChange={handleInputChange}
                error={errors?.doc_number || ""}
              />
              <CommonDatePicker
                values={values}
                setValues={setValues}
                errors={errors}
                setErrors={setErrors}
                label={"Document Issue Date"}
                name="issue_date"
                type="issue"
                maxDate={new Date()}
                dropdownMode="select"
                showMonthDropdown={true}
                showYearDropdown={true}
              />
              <CommonDatePicker
                values={values}
                setValues={setValues}
                errors={errors}
                setErrors={setErrors}
                label={"Document Expiry Date"}
                name="expiry_date"
                minDate={new Date()}
                dropdownMode="select"
                showMonthDropdown={true}
                showYearDropdown={true}
              />
              {/* <Input
                className="create_from_input_content null"
                labelClassName="create_from_label required"
                inputClassName="create_from_input"
                errorClassName="err_text"
                type="text"
                placeholder="Enter nationality"
                label="Document Nationality"
                id="nationality"
                name="nationality"
                value={values?.nationality}
                onChange={handleInputChange}
                error={errors?.nationality || ""}
              /> */}
              <div className="project_edit_feature_input mt-0">
                <label className="create_from_label required">
                  Document Nationality
                </label>
                <Select
                  placeholder="Select Document Type"
                  value={values?.nationality}
                  className="basic-multi-select z-99999"
                  classNamePrefix="select"
                  name="nationality"
                  options={nationalityOptions}
                  onChange={(e) => handleInputChange(e, "nationality")}
                />
                {errors?.nationality && (
                  <span className="err_text">{errors?.nationality}</span>
                )}
              </div>
              <div className="login_input_row mb-0">
                <label className="required">Phone Number</label>
                <PhoneInput
                  placeholder="Enter Phone number"
                  value={values?.phone_number}
                  defaultCountry="TH"
                  onChange={(e) => {
                    setValues({
                      ...values,
                      phone_number: e,
                    });
                    setErrors({ ...errors, phone_number: "" });
                  }}
                />
                {errors?.phone_number && (
                  <span className="err_text">{errors?.phone_number}</span>
                )}
              </div>
              {/* <Input
                    className="create_from_input_content null"
                    labelClassName="create_from_label"
                    inputClassName="create_from_input disabled_input"
                    errorClassName="err_text"
                    type="text"
                    placeholder="Enter Company"
                    label="Company"
                    id="company"
                    name="company"
                    value={values?.tenant_company}
                    onChange={handleInputChange}
                    error={errors?.company || ""}
                    disabled={true}
                  /> */}
              {/* <Input
                    className="create_from_input_content null"
                    labelClassName="create_from_label required"
                    inputClassName="create_from_input"
                    errorClassName="err_text"
                    type="text"
                    placeholder="Enter vehicle type"
                    label="Vehicle Type"
                    id="vehicle_type"
                    name="vehicle_type"
                    value={values?.vehicle_type}
                    onChange={handleInputChange}
                    error={errors?.vehicle_type || ""}
                  /> */}
              {/* <div>
                <label className="create_from_label">Select Vehicle Type</label>
                <Select
                  placeholder={"Select Vehicle Type"}
                  value={values?.vehicle_type}
                  className={`basic-multi-select visitor_label_tenant general_form_tanant z-9999`}
                  classNamePrefix="select"
                  name="vehicle_type"
                  options={vehicleTypeOptions}
                  onChange={(e) => changeVehTypeHandler(e)}
                />
                {errors?.vehicle_type && (
                  <span className="err_text">{errors?.vehicle_type}</span>
                )}
              </div> */}
              {/* <Input
                className="create_from_input_content null"
                labelClassName={`create_from_label ${
                  (values?.vehicle_type?.value === "Car" ||
                    values?.vehicle_type?.value === "Motorcycle") &&
                  "required"
                }`}
                inputClassName={`create_from_input 
                ${
                  (values?.vehicle_type?.value === "" ||
                    values?.vehicle_type === "") &&
                  "visitor_disabled_input"
                }`}
                errorClassName="err_text"
                type="text"
                placeholder="Enter License plate number"
                label="Vehicle Number"
                id="license_plate_number"
                name="license_plate_number"
                value={values?.license_plate_number}
                onChange={(e) => {
                  setValues({
                    ...values,
                    license_plate_number: e.target.value,
                  });
                  setVehiclePlateErr("");
                }}
                disabled={
                  values?.vehicle_type?.value === "" ||
                  values?.vehicle_type === ""
                }
                error={vehiclePlateErr || ""}
              /> */}
              {/* <Input
            className="create_from_input_content null"
            labelClassName="create_from_label required"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder="Enter Make"
            label="Make"
            id="make"
            name="make"
            value={values?.make}
            onChange={handleInputChange}
            error={errors?.make || ""}
          />
          <Input
            className="create_from_input_content null"
            labelClassName="create_from_label"
            inputClassName="create_from_input h-35"
            errorClassName="err_text"
            type="color"
            placeholder="Enter Color"
            label="Color"
            id="color"
            name="color"
            value={values?.color}
            onChange={handleInputChange}
            error={errors?.color || ""}
          /> */}

              <div className="project_edit_detail_divider">
                <label className="create_from_label required">
                  Document Image
                </label>
                <div className="project_edit_feature_input_file">
                  <Input
                    className="create_from_input_content"
                    labelClassName="create_from_label"
                    errorClassName="err_text"
                    type="file"
                    id={`doc_image`}
                    labelOtherProps={
                      <span>{values?.doc_image ? editIcon : addIcon}</span>
                    }
                    name="doc_image"
                    accept="image/*"
                    onChange={handleInputChange}
                    onClick={(e) => (e.target.value = null)}
                    imageProps={imagePreviewProps(
                      values?.doc_image,
                      values?.doc_image_preview,
                      imageRemoveHandler
                    )}
                  />
                </div>
                <p className="create_project_review mb-0 block">
                  108 x 108 (1 : 1),The maximum size for an upload is 5 MB
                </p>
                {errors?.doc_image && (
                  <span className="err_text">{errors?.doc_image}</span>
                )}
              </div>
              <div></div>
              <div className="visitor_infom_form_col">
                <h2 className="visitor_infom_form_heading">
                  Additional Visitor
                </h2>
              </div>
              <div className="col_span_2">
                <Input
                  className="create_from_input_content create_link_btn_link"
                  labelClassName="create_from_label"
                  inputClassName="create_from_input"
                  errorClassName="err_text"
                  placeholder="Enter Email of Subvisitor"
                  label="Subvisitor 1"
                  id="subvisitor_1"
                  name="subvisitor_1"
                  onChange={handleInputChange}
                  value={values?.subvisitor_1}
                  // error={errors.subvisitor_1 || ""}
                />
                <Input
                  className="create_from_input_content create_link_btn_link"
                  labelClassName="create_from_label"
                  inputClassName="create_from_input"
                  errorClassName="err_text"
                  placeholder="Enter Email of Subvisitor"
                  label="Subvisitor 2"
                  id="subvisitor_2"
                  name="subvisitor_2"
                  onChange={handleInputChange}
                  value={values?.subvisitor_2}
                  // error={errors.subvisitor_2 || ""}
                />
                <Input
                  className="create_from_input_content create_link_btn_link"
                  labelClassName="create_from_label"
                  inputClassName="create_from_input"
                  errorClassName="err_text"
                  placeholder="Enter Email of Subvisitor"
                  label="Subvisitor 3"
                  id="subvisitor_3"
                  name="subvisitor_3"
                  onChange={handleInputChange}
                  value={values?.subvisitor_3}
                  // error={errors.subvisitor_3 || ""}
                />
                <Input
                  className="create_from_input_content null"
                  labelClassName="create_from_label"
                  inputClassName="create_from_input"
                  errorClassName="err_text"
                  placeholder="Enter Email of Subvisitor"
                  label="Subvisitor 4"
                  id="subvisitor_4"
                  name="subvisitor_4"
                  onChange={handleInputChange}
                  value={values?.subvisitor_4}
                  // error={errors.subvisitor_4 || ""}
                />
              </div>

              <Button
                className="project_submit_bottom_btn center_back_btn m-0"
                buttonClassName="comman_btn "
                onClick={createVisitorHandler}
                disabled={fileUploadLoader}
                text={fileUploadLoader ? "Loading..." : "Update"}
              />
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default GeneralForm;

import { Navigate } from "react-router-dom";
import AuthPage from "./component/Layout/AuthPage";
import Layout from "./component/Layout/MainLayout";
import Dashboard from "./pages/Dashboard/Dashboard";
import LoginPage from "./pages/Login/LoginPage";
import Page404 from "./pages/Page404/Page404";
import { routes } from "../src/constants";
import CreateAmenities from "./pages/Amenities/CreateAmenities";
import Amenities from "./pages/Amenities/Amenities";
import SidemenuList from "./pages/Sidemenu/SidemenuList";
import CreateSidemenu from "./pages/Sidemenu/CreateSidemenu";
import Announcements from "./pages/Announcement/Announcements";
import CreateAnnouncement from "./pages/Announcement/CreateAnnouncement";
import Contacts from "./pages/Contact/Contacts";
import CreateContact from "./pages/Contact/CreateContact";
import RoleManagement from "./pages/Role-Management/RoleManagement";
import CreateRoleFeature from "./pages/Role-Management/CreateRoleFeature";
import ViewRoleFeature from "./pages/Features/ViewRoleFeature";
import FloorLevels from "./pages/FloorLevels/FloorLevels";
import CreateFloorLevel from "./pages/FloorLevels/CreateFloorLevel";
import Directory from "./pages/Directory/Directory";
import CreateDirectory from "./pages/Directory/CreateDirectory";
import Services from "./pages/Services/Services";
import Notifications from "./pages/Notification/Notifications";
import CreateNotification from "./pages/Notification/CreateNotification";
import Feedback from "./pages/Feedback/Feedback";
import Users from "./pages/Users/Users";
import UserDetailPage from "./pages/Users/UserDetailPage";
import UserEditPage from "./pages/Users/UserEditPage";
import Features from "./pages/Features/Features";
import AdminUsers from "./pages/AdminUsers/AdminUsers";
import CreateAdminUser from "./pages/AdminUsers/CreateAdminUser";
import Settings from "./pages/Settings/Settings";
import Event from "./pages/Event/Event";
import CreateEvent from "./pages/Event/CreateEvent";
import CreatePromotion from "./pages/Promotions/CreatePromotion";
import Promotion from "./pages/Promotions/Promotion";
import VisitorInvite from "./pages/VisitorInvite/VisitorInvite";
import CreateVisitorInvite from "./pages/VisitorInvite/CreateVisitorInvite";
import GeneralForm from "./pages/GeneralForm";
import Password from "./pages/Password/Password";
import PrivacyPolicy from "./pages/Settings/PrivacyPolicy";
import TermsConditions from "./pages/Settings/TermsConditions";
import Token from "./pages/Settings/Token";
import CompletionFormPage from "./pages/CompletionFormPage";
import FoodBeverage from "./pages/FoodBeverage/FoodBeverage";
import CreateFoodBeverage from "./pages/FoodBeverage/CreateFoodBeverage";
import PrivacyPolicyPage from "./pages/StaticPage/PrivacyPolicyPage";
import TermsConditionsPage from "./pages/StaticPage/TermsConditionsPage";
import MobileConfiguration from "./pages/Settings/MobileConfiguration";
import Purpose from "./pages/Purpose/Purpose";
import DocType from "./pages/DocType/DocType";
import JustCoConfig from "./pages/JustCo/JustCoConfig";
import CreateJustCoConfig from "./pages/JustCo/CreateJustCoConfig";
import JustCoSecretApplication from "./pages/JustCo/JustCoSecretApplication";
import JustCoLogo from "./pages/JustCo/JustCoLogo";
import JustCoTermsCondition from "./pages/JustCo/JustCoTermsCondition";
import JustCoTitleDescription from "./pages/JustCo/JustCoTitleDescription";
import VisitorPrivacyPolicy from "./pages/Settings/VisitorPrivacyPolicy";
import CountryCode from "./pages/Settings/CountryCode/CountryCode";
import CreateCountryCode from "./pages/Settings/CountryCode/CreateCountryCode";

const getRoutes = (user) => [
  {
    path: routes.homepage,
    element: !user ? (
      <AuthPage />
    ) : user?.data?.reset_password === "0" ? (
      <Password />
    ) : (
      <Navigate to={routes.dashboard} />
    ),
    children: [
      { path: routes.login, element: <LoginPage /> },
      { path: routes.general, element: <GeneralForm /> },
      { path: routes.completionForm, element: <CompletionFormPage /> },
      { path: routes.privacyPolicyPage, element: <PrivacyPolicyPage /> },
      { path: routes.termsConditionsPage, element: <TermsConditionsPage /> },
      // { path: routes.passwordPage, element: <Password /> },
      { path: routes.homepage, element: <Navigate to={routes.login} /> },
    ],
  },
  {
    path: routes.homepage,
    element: user ? (
      user?.reset_password === "0" ? (
        <Password />
      ) : (
        <Layout />
      )
    ) : (
      <Navigate to={routes.login} />
    ),
    children: [
      { path: routes.dashboard, element: <Dashboard /> },

      { path: routes.adminUsers, element: <AdminUsers /> },
      { path: routes.createAdminUsers, element: <CreateAdminUser /> },
      { path: routes.editAdminUsers, element: <CreateAdminUser /> },

      { path: routes.users, element: <Users /> },
      { path: routes.usersByPage, element: <Users /> },
      { path: routes.viewUser, element: <UserDetailPage /> },
      { path: routes.editUser, element: <UserEditPage /> },

      { path: routes.rolemanagement, element: <RoleManagement /> },
      { path: routes.createRole, element: <CreateRoleFeature /> },
      { path: routes.editRole, element: <CreateRoleFeature /> },

      { path: routes.features, element: <Features /> },
      { path: routes.viewFeaturemanagement, element: <ViewRoleFeature /> },

      { path: routes.amenities, element: <Amenities /> },
      { path: routes.createAmenities, element: <CreateAmenities /> },
      { path: routes.editAmenities, element: <CreateAmenities /> },

      { path: routes.sidemenu, element: <SidemenuList /> },
      { path: routes.createSidemenu, element: <CreateSidemenu /> },
      { path: routes.editSidemenu, element: <CreateSidemenu /> },

      { path: routes.announcement, element: <Announcements /> },
      { path: routes.announcementByPage, element: <Announcements /> },
      { path: routes.createAnnouncement, element: <CreateAnnouncement /> },
      { path: routes.editAnnouncement, element: <CreateAnnouncement /> },

      { path: routes.contact, element: <Contacts /> },
      { path: routes.contactByPage, element: <Contacts /> },
      { path: routes.createContact, element: <CreateContact /> },
      { path: routes.editContact, element: <CreateContact /> },

      { path: routes.floorLevel, element: <FloorLevels /> },
      { path: routes.floorLevelByPage, element: <FloorLevels /> },
      { path: routes.createFloorLevel, element: <CreateFloorLevel /> },
      { path: routes.editFloorLevel, element: <CreateFloorLevel /> },

      { path: routes.directory, element: <Directory /> },
      { path: routes.directoryByPage, element: <Directory /> },
      { path: routes.createDirectory, element: <CreateDirectory /> },
      { path: routes.editDirectory, element: <CreateDirectory /> },

      { path: routes.services, element: <Services /> },
      { path: routes.servicesByPage, element: <Services /> },

      { path: routes.notifications, element: <Notifications /> },
      { path: routes.notificationsByPage, element: <Notifications /> },
      { path: routes.createNotification, element: <CreateNotification /> },
      { path: routes.editNotification, element: <CreateNotification /> },

      { path: routes.events, element: <Event /> },
      { path: routes.eventsByPage, element: <Event /> },
      { path: routes.createEvent, element: <CreateEvent /> },
      { path: routes.editEvent, element: <CreateEvent /> },

      { path: routes.promotions, element: <Promotion /> },
      { path: routes.promotionsByPage, element: <Promotion /> },
      { path: routes.createPromotion, element: <CreatePromotion /> },
      { path: routes.editPromotion, element: <CreatePromotion /> },

      { path: routes.visitorInvite, element: <VisitorInvite /> },
      { path: routes.visitorInviteByPage, element: <VisitorInvite /> },
      { path: routes.createVisitorInvite, element: <CreateVisitorInvite /> },
      { path: routes.editVisitorInvite, element: <CreateVisitorInvite /> },

      { path: routes.feedback, element: <Feedback /> },
      { path: routes.feedbackByPage, element: <Feedback /> },

      { path: routes.settings, element: <Settings /> },
      { path: routes.privacyPolicy, element: <PrivacyPolicy /> },
      { path: routes.termsConditions, element: <TermsConditions /> },
      { path: routes.tokenComponent, element: <Token /> },
      { path: routes.mobileConComponent, element: <MobileConfiguration /> },
      { path: routes.visitorPolicy, element: <VisitorPrivacyPolicy /> },

      { path: routes.countryCodes, element: <CountryCode /> },
      { path: routes.countryCodesByPage, element: <CountryCode /> },
      { path: routes.createCountryCodes, element: <CreateCountryCode /> },
      { path: routes.editCountryCodes, element: <CreateCountryCode /> },

      { path: routes.justCo, element: <JustCoConfig /> },
      { path: routes.editJustCo, element: <CreateJustCoConfig /> },
      {
        path: routes.editSecretApplicationKey,
        element: <JustCoSecretApplication />,
      },
      {
        path: routes.editTermsAndConditions,
        element: <JustCoTermsCondition />,
      },
      { path: routes.editLogo, element: <JustCoLogo /> },
      {
        path: routes.editTitleDescription,
        element: <JustCoTitleDescription />,
      },

      { path: routes.foodBeverage, element: <FoodBeverage /> },
      { path: routes.foodBeverageByPage, element: <FoodBeverage /> },
      { path: routes.createRestaurant, element: <CreateFoodBeverage /> },
      { path: routes.editRestaurant, element: <CreateFoodBeverage /> },

      { path: routes.purpose, element: <Purpose /> },

      { path: routes.docType, element: <DocType /> },

      { path: routes.homepage, element: <Navigate to={routes.dashboard} /> },
    ],
  },
  {
    path: "",
    element: !user ? <Navigate to={routes.login} /> : <Page404 />,
    children: [
      { path: routes.login, element: <LoginPage /> },
      { path: "*", element: <LoginPage /> },
    ],
  },
];

export default getRoutes;

import React from "react";
import Button from "../../component/Common/Button";
import Input from "../../component/Common/Input";
import TextArea from "../../component/Common/TextArea";
import CommonDatePicker from "../../component/Common/DatePicker";
import { useForm } from "../../hooks/useForm";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  createAnnoncement,
  getAnnouncementTypes,
  updateAnnouncement,
} from "../../store/slice/announcementSlice";
import { fileUpload } from "../../store/slice/fileUploadSlice";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../constants";
import { announcementValidation } from "../../component/Common/PageComponent/AnnouncementComponents";
import CreatableSelect from "react-select/creatable";
import { handleInputChangePost } from "../../component/Common/PageComponent/ContactComponents";
import { useEffect } from "react";
import { getRoles } from "../../store/slice/rolemanagementSlice";
import { useCallback } from "react";
import AnnouncementType from "./AnnouncementType";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  SidebarComponent,
  defaultTimeZone,
  imagePreviewProps,
} from "../../helpers/commonFunction";
import { addIcon, editIcon } from "../../icons";
import Loader from "../../component/Common/Loader";
import { useRef } from "react";
import Select from "react-select";

export const announcementPinOptions = [
  { value: "0", label: "0" },
  { value: "1", label: "1" },
];

const CreateAnnouncement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const editComponent = location?.state?.menu;
  const value = location?.state?.value;
  const valuePage = location?.state?.page_number;

  const sideHeading = localStorage.getItem("sidebar_component");

  let fileUploadLoader = useSelector((state) => state.fileUpload.loader);
  let announcementLoader = useSelector((state) => state.announcement.loader);

  const { t } = useTranslation("common");

  const [updateState, setUpdateState] = useState();
  const [isChanged, setIsChanged] = useState("0");

  useEffect(() => {
    if (updateState) {
      setValues({
        ...values,
        announcementType_name: updateState.announcement_type_name
          ? updateState.announcement_type_name
          : "",
        announcementType_icon: updateState.announcement_type_image
          ? updateState.announcement_type_image
          : "",
        announcementType_icon_preview: updateState.announcement_type_image
          ? `${process.env.REACT_APP_FILE_BASE_URL}/${updateState.announcement_type_image}`
          : "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateState]);

  useEffect(() => {
    setErrors({
      ...errors,
      announcementType_name: "",
      announcementType_icon: "",
    });
    dispatch(getRoles({ appType: 1 }));
    dispatch(getAnnouncementTypes());
  }, [dispatch]);

  const announcementTypes = useSelector(
    (state) => state.announcement.announcementTypesList
  );

  const roleList = useSelector((state) => state.rolemanagement.rolesList?.data);

  const announcementTypeList = announcementTypes?.map((type, i) => {
    return {
      value: type.announcement_type_name,
      label: type.announcement_type_name,
      id: type.id,
    };
  });

  const initialFValues = useCallback(() => {
    return getInitialAnnouncementState(editComponent);
  }, [editComponent, roleList]);

  const getInitialAnnouncementState = (editComponent) => {
    return {
      announcement_title: editComponent
        ? editComponent?.announcement_title
        : "",
      announcement_description: editComponent
        ? editComponent?.announcement_description
        : "",
      announcement_date_and_time: editComponent
        ? new Date(
            moment
              .utc(
                editComponent?.announcement_date_and_time,
                "YYYYMMDD HH:mm:ss"
              )
              .clone()
              .tz(defaultTimeZone)
              .format("MM/DD/YYYY, hh:mm A")
          )
        : "",

      announcement_type: editComponent
        ? {
            label: editComponent?.announcement_type_name,
            value: editComponent?.announcement_type_name,
            id: editComponent?.announcement_type_id,
          }
        : "",
      is_pinned:
        editComponent && editComponent.is_pinned
          ? editComponent.is_pinned
          : "0",

      user_type:
        editComponent?.user_role && roleList
          ? JSON.parse(editComponent?.user_role).length === roleList?.length
            ? ["ALL"]
            : JSON.parse(editComponent?.user_role)
          : [],
      start_date: editComponent ? new Date(editComponent?.start_date) : "",
      end_date: editComponent ? new Date(editComponent?.end_date) : "",
      announcementTypeComponent: false,
      announcementType_name: "",
      announcementType_icon: "",
      announcementType_icon_preview: "",
      image:
        editComponent && editComponent.announcement_image
          ? editComponent.announcement_image
          : "",
      image_preview:
        editComponent && editComponent.announcement_image
          ? `${process.env.REACT_APP_FILE_BASE_URL}/${editComponent.announcement_image}`
          : "",
      send_now:
        editComponent && editComponent.send_now ? editComponent.send_now : "0",
      include_image:
        editComponent && editComponent.include_image
          ? editComponent.include_image
          : "0",
    };
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    return announcementValidation(
      temp,
      fieldValues,
      values,
      setErrors,
      setValues,
      t
    );
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate,
    roleList
  );

  const userTypeHandler = (e, item) => {
    setErrors({ ...errors, user_type: "" });
    let tempUserTypeHandler = [...values.user_type];
    if (tempUserTypeHandler.includes(item.id)) {
      let index = tempUserTypeHandler.findIndex((obj) => obj === item.id);
      if (index > -1) {
        tempUserTypeHandler.splice(index, 1);
      }
      setValues({ ...values, user_type: tempUserTypeHandler });
    } else {
      if (tempUserTypeHandler.includes("ALL")) {
        let index = tempUserTypeHandler.findIndex((obj) => obj === "ALL");
        if (index > -1) {
          tempUserTypeHandler.splice(index, 1);
        }
        setValues({
          ...values,
          user_type: [...tempUserTypeHandler, item.id],
        });
      } else {
        setValues({
          ...values,
          user_type: [...values.user_type, item.id],
        });
      }
    }
  };

  const userAllTypeHandler = () => {
    setErrors({ ...errors, user_type: "" });
    let tempUserTypeHandler = [...values.user_type];
    if (tempUserTypeHandler.includes("ALL")) {
      let index = tempUserTypeHandler.findIndex((obj) => obj === "ALL");
      if (index > -1) {
        tempUserTypeHandler.splice(index, 1);
      }
      setValues({ ...values, user_type: tempUserTypeHandler });
    } else {
      setValues({
        ...values,
        user_type: ["ALL"],
      });
    }
  };

  const createTypeHandler = () => {
    setValues({ ...values, announcementTypeComponent: true });
  };

  const imageRemoveHandler = () => {
    setValues({
      ...values,
      image: "",
      image_preview: "",
    });
  };

  // console.log("values", values);

  const createAnnouncementHandler = async (e) => {
    e.preventDefault();
    if (validate()) {
      const formData = new FormData();
      let attachmentUrl;
      if (values.image && typeof values.image !== "string") {
        formData.append("file", values.image);
        attachmentUrl = await dispatch(fileUpload(formData));
        if (attachmentUrl && attachmentUrl.payload) {
          setValues({
            ...values,
            image: attachmentUrl.payload,
          });
        }
        formData.delete("file");
      } else {
        attachmentUrl = values.image;
      }

      const data = {
        announcement_title: values.announcement_title,
        announcement_description: values.announcement_description,
        announcement_date_and_time: moment
          .utc(moment(values.announcement_date_and_time).utc())
          .format("YYYY-MM-DD HH:mm:ss"),
        announcement_type_id: values.announcement_type.id,
        user_role: values.user_type.includes("ALL")
          ? roleList.map((item) => item.id)
          : values.user_type,
        start_date: moment(values.start_date).format("YYYY-MM-DD"),
        end_date: moment(values.end_date).format("YYYY-MM-DD"),
        announcement_image:
          typeof values.image !== "string"
            ? attachmentUrl.payload
            : attachmentUrl,
        send_now: values.send_now,
        include_image: values.include_image,
        is_pinned: values.is_pinned,
      };

      editComponent
        ? dispatch(
            updateAnnouncement({
              data,
              navigate,
              id: editComponent.id,
              toastText: t("toast.update_announcement"),
              value,
              valuePage,
            })
          )
        : dispatch(
            createAnnoncement({
              data,
              navigate,
              toast: t("toast.create_announcement"),
            })
          );
    }
  };

  useEffect(() => {
    if (editComponent) {
      if (isChanged === "1") {
        setValues({
          ...values,
          end_date: "",
          // announcement_date_and_time: "",
        });
      }
    } else {
      setValues({
        ...values,
        end_date: "",
        // announcement_date_and_time: "",
      });
    }
  }, [values.start_date]);

  if (fileUploadLoader || announcementLoader) {
    return <Loader />;
  }

  const onChangeNotification = () => {
    setValues({
      ...values,
      send_now: values.send_now === "0" ? "1" : "0",
    });
  };

  const onChangeAnnouncementImage = () => {
    setValues({
      ...values,
      include_image: values.include_image === "0" ? "1" : "0",
    });
  };

  // Handle filter times
  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  const onChangeIsPinned = () => {
    setValues({
      ...values,
      is_pinned: values.is_pinned === "0" ? "1" : "0",
    });
  };
  return (
    <>
      {values.announcementTypeComponent ? (
        <>
          <div className="comman_btn_container center_back_btn announcement_page-container">
            <button
              onClick={() => {
                setValues({
                  ...values,
                  announcementTypeComponent: false,
                  announcementType_name: "",
                  announcementType_icon: "",
                  announcementType_icon_preview: "",
                });
                setErrors({
                  ...errors,
                  announcementType_name: "",
                  announcementType_icon: "",
                });
                setUpdateState("");
              }}
              className="comman_btn ml-auto secondary_btn"
            >
              {t("common.back")}
            </button>
          </div>
          <div className="project_edit_main_content">
            <AnnouncementType
              updateState={updateState}
              setUpdateState={setUpdateState}
              values={values}
              setValues={setValues}
              errors={errors}
              setErrors={setErrors}
              handleInputChange={handleInputChange}
            />
          </div>
        </>
      ) : (
        <>
          <div className="comman_btn_container center_back_btn announcement_page-container">
            <h2 className="mobile_configuration_heading">
              {editComponent
                ? `${SidebarComponent()} > Edit`
                : `${SidebarComponent()} > Create`}
            </h2>
            <Link
              to={`${routes.announcement}/page/${valuePage}`}
              state={{ value, valuePage }}
              className="comman_btn ml-auto secondary_btn"
            >
              {t("common.back")}
            </Link>
          </div>
          <div className="project_edit_main_content">
            <div className="create_from_row">
              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label required"
                inputClassName="create_from_input"
                errorClassName="err_text"
                type="text"
                placeholder={t("announcement.announcement_title_place")}
                label={t("announcement.title")}
                id="announcement_title"
                name="announcement_title"
                value={values.announcement_title}
                onChange={handleInputChange}
                error={errors?.announcement_title || ""}
              />
              <div className={`create_from_input_content`}>
                <label className="create_from_label inline_block required">
                  {t("table.type")}
                </label>
                <button className="create_link_btn" onClick={createTypeHandler}>
                  {t("announcement.create_announcement_type")}
                </button>
                <CreatableSelect
                  placeholder={t("announcement.announcement_place")}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  value={values.announcement_type}
                  onChange={(e) => handleInputChange(e, "announcement_type")}
                  onInputChange={handleInputChangePost}
                  options={announcementTypeList}
                />
                {errors?.announcement_type && (
                  <span className="err_text">{errors?.announcement_type}</span>
                )}
              </div>
              <TextArea
                className="create_from_input_content col_span_2"
                labelClassName="create_from_label required"
                inputClassName="create_from_input"
                errorClassName="err_text"
                type="text"
                placeholder={t("announcement.description_place")}
                label={t("announcement.description")}
                id="announcement_description"
                name="announcement_description"
                rows={2}
                value={values.announcement_description}
                onChange={handleInputChange}
                error={errors?.announcement_description || ""}
              />

              <div className="create_from_input_content ">
                <label className="create_from_label required">
                  {t("announcement.user_role")}
                </label>
                <Input
                  className="create_from_radio_checkbox_contentainer"
                  labelClassName="create_from_radio_label_role"
                  inputClassName="create_from_radio_checkbox"
                  type="checkbox"
                  label={"All"}
                  id={"ALL"}
                  name="user_type"
                  value={"ALL"}
                  onChange={userAllTypeHandler}
                  checked={values.user_type?.includes("ALL")}
                />
                {roleList?.map((type, i) => {
                  return (
                    <Input
                      key={i}
                      className="create_from_radio_checkbox_contentainer"
                      labelClassName="create_from_radio_label_role"
                      inputClassName="create_from_radio_checkbox"
                      type="checkbox"
                      label={type.role_display_name}
                      id={type.role_display_name}
                      name="user_type"
                      value={type.id}
                      onChange={(e) => userTypeHandler(e, type)}
                      checked={values?.user_type?.includes(type.id)}
                      // disabled={values?.user_type?.includes("ALL")}
                    />
                  );
                })}
                {errors?.user_type && (
                  <span className="err_text">{errors?.user_type}</span>
                )}
              </div>
              <div></div>

              <CommonDatePicker
                values={values}
                setValues={setValues}
                errors={errors}
                setErrors={setErrors}
                label={t("table.start_date")}
                name="start_date"
                // minDate={new Date()}
                isChanged={isChanged}
                type="ann_start_date"
                setIsChanged={setIsChanged}
              />
              <CommonDatePicker
                values={values}
                setValues={setValues}
                errors={errors}
                setErrors={setErrors}
                label={t("table.end_date") + " "}
                name="end_date"
                minDate={new Date(values?.start_date)}
                isDisabled={values && !values.start_date && true}
              />
              <CommonDatePicker
                values={values}
                setValues={setValues}
                errors={errors}
                setErrors={setErrors}
                label={t("announcement.announcement_time") + " "}
                name="announcement_date_and_time"
                dateFormat="dd/MM/yyyy h:mm aa"
                // showTimeInput={true}
                showTimeSelect={true}
                timeIntervals={10}
                // filterTime={filterPassedTime}
                hasInfoIcon
                tooltipText={t("toast.tooltipText")}
                type="announcement_date"
                // maxDate={values.end_date}
                // minDate={values.start_date}
                // isDisabled={values && !values.end_date && true}
              />

              <div>
                {/* <label className="create_from_label required">
                  {t("visitorInvite.visitor_pin")}
                </label>
                <Select
                  placeholder={t("announcement.select_pin")}
                  value={values.is_pinned}
                  className={`basic-multi-select visitor_label_tenant`}
                  classNamePrefix="select"
                  name="is_pinned"
                  options={announcementPinOptions}
                  onChange={(e) => handleInputChange(e, "is_pinned")}
                /> */}
                <Input
                  className="create_from_radio_checkbox_contentainer pin_checkbox"
                  labelClassName="create_from_radio_label_role"
                  inputClassName="create_from_radio_checkbox"
                  type="checkbox"
                  label={"Pin Announcement"}
                  id={"is_pinned"}
                  name="is_pinned"
                  value={"1"}
                  onChange={() => onChangeIsPinned()}
                  checked={values.is_pinned === "1"}
                />
              </div>

              <div className="project_edit_detail_divider span_2">
                <label
                  className={`create_from_label ${
                    values.include_image === "1" && "required"
                  }`}
                >
                  {t("table.announcement_img")}
                </label>
                <div className="project_edit_feature_input_file">
                  <Input
                    className="create_from_input_content"
                    labelClassName="create_from_label"
                    errorClassName="err_text"
                    type="file"
                    id={`image`}
                    labelOtherProps={
                      <span>{values.image ? editIcon : addIcon}</span>
                    }
                    name="image"
                    accept=".jpg, .jpeg, .png"
                    onChange={handleInputChange}
                    onClick={(e) => (e.target.value = null)}
                    imageProps={imagePreviewProps(
                      values.image,
                      values.image_preview,
                      imageRemoveHandler
                    )}
                  />
                </div>
                <p className="create_project_review mb-0 block">
                  {t("form.xsmall_size_desc")}
                </p>
                {errors?.image && (
                  <span className="err_text">{errors?.image}</span>
                )}
              </div>

              {/* {!editComponent ||
                ((editComponent.send_now === "0" ||
                  editComponent.include_image === "0") && (
                  <> */}
              <hr className="container_bottom_dashed_line col_span_2" />
              <div>
                <label className="notification_details_heading">
                  {t("notification.notification_detail")}
                </label>
              </div>
              {/* </>
                ))} */}

              <div></div>

              <div className="create_from_input_content ">
                {!editComponent ||
                (editComponent && editComponent.send_now === "0") ? (
                  <>
                    <Input
                      className="create_from_radio_checkbox_contentainer"
                      labelClassName="create_from_radio_label_role"
                      inputClassName="create_from_radio_checkbox"
                      type="checkbox"
                      label={t("announcement.send_notification_now")}
                      id={"send_now"}
                      name="send_now"
                      value={"1"}
                      onChange={() => onChangeNotification()}
                      checked={values.send_now === "1"}
                    />

                    <Input
                      className="create_from_radio_checkbox_contentainer"
                      labelClassName="create_from_radio_label_role"
                      inputClassName="create_from_radio_checkbox"
                      type="checkbox"
                      label={t("announcement.include_announcement_image")}
                      id={"include_image"}
                      name="include_image"
                      value={"1"}
                      onChange={() => onChangeAnnouncementImage()}
                      checked={values.include_image === "1"}
                    />
                  </>
                ) : (
                  <span className="notification_sent">
                    Notification already sent
                  </span>
                )}
                {/* {(!editComponent ||
                  (editComponent && editComponent.include_image === "0")) && ( */}
                {/* )} */}
              </div>
            </div>
            <Button
              className="project_submit_bottom_btn center_back_btn"
              buttonClassName="comman_btn"
              onClick={createAnnouncementHandler}
              text={`${editComponent ? t("common.save") : t("common.create")}`}
            />
          </div>
        </>
      )}
    </>
  );
};

export default CreateAnnouncement;

export const eventValidation = (
  editComponent,
  temp,
  fieldValues,
  values,
  setValues,
  setErrors,
  t
) => {
  // console.log("fieldValues", fieldValues);
  if ("event_title" in fieldValues) {
    temp.event_title = fieldValues.event_title ? "" : t("errors.title");
  }
  // if ("date" in fieldValues) {
  //   temp.date =
  //     new Date(values?.date) < new Date()
  //       ? "Selected event time is in the past."
  //       : fieldValues.date
  //       ? ""
  //       : t("errors.date");
  // }
  if ("date" in fieldValues) {
    if (!fieldValues.date) {
      temp.date = t("errors.date");
    } else {
      if (!editComponent) {
        if (new Date(values?.date) < new Date()) {
          temp.date = "Selected event time is in the past";
        } else {
          temp.date = "";
        }
      }
    }
  }
  if ("end_date" in fieldValues) {
    temp.end_date =
      fieldValues.end_date &&
      new Date(values?.end_date) < new Date(values?.date)
        ? "Selected time is in the past"
        : fieldValues.end_date &&
          new Date(values?.end_date).getTime() ===
            new Date(values?.date).getTime()
        ? "End date should not be same as start date"
        : "";

    // if (new Date(values?.end_date) < new Date(values?.date)) {
    //   temp.end_date = "Selected date and time is in the past.";
    // } else if (new Date(values?.end_date) === new Date(values?.date)) {
    //   temp.end_date = "End date cannot be the same as start date!";
    // }
  }
  if ("event_description" in fieldValues) {
    temp.event_description = fieldValues.event_description
      ? ""
      : t("errors.description");
  }
  // if ("start_time" in fieldValues) {
  //   temp.start_time = fieldValues.start_time ? "" : t("errors.start_time");
  // }
  // if ("end_time" in fieldValues) {
  //   temp.end_time = fieldValues.end_time ? "" : t("errors.end_time");
  // }
  if ("address" in fieldValues) {
    temp.address = fieldValues.address ? "" : t("errors.address");
  }
  if ("organizer_name" in fieldValues) {
    temp.organizer_name = fieldValues.organizer_name
      ? ""
      : t("errors.organizer_name");
  }
  // if ("event_link" in fieldValues) {
  //   temp.event_link = fieldValues.event_link ? "" : t("errors.event_link");
  // }
  if ("organizer_company_name" in fieldValues) {
    temp.organizer_company_name = fieldValues.organizer_company_name
      ? ""
      : t("errors.organizer_company_name");
  }
  if ("user_type" in fieldValues) {
    temp.user_type =
      fieldValues.user_type.length > 0 ? "" : t("errors.user_role");
  }
  if ("event_floor_level_id" in fieldValues) {
    temp.event_floor_level_id = fieldValues.event_floor_level_id
      ? ""
      : t("errors.floor_level");
  }
  if ("image" in fieldValues) {
    temp.image = "";
    if (!fieldValues.image) {
      temp.image = t("errors.img_require");
    }
    // if (values?.include_image === "1" && !fieldValues.image) {
    //   temp.image = t("errors.img_require");
    // }
    if (fieldValues.image.size > 1000000) {
      // temp.image = t("errors.small_icon_size");
      temp.image = t("errors.small_image_size1");
      setValues({
        ...values,
        image_preview: "",
        image: "",
      });
    }
    if (
      fieldValues.image?.name &&
      !fieldValues.image?.name?.match(/.(jpg|jpeg|png|gif)$/i)
    ) {
      temp.image = t("errors.img_invalid");
      setValues({
        ...values,
        image_preview: "",
        image: "",
      });
    }
  }
  if ("organizer_profile_picture" in fieldValues) {
    temp.organizer_profile_picture = "";
    if (fieldValues.organizer_profile_picture?.size > 1000000) {
      temp.organizer_profile_picture = t("errors.small_image_size1");
      setValues({
        ...values,
        organizer_profile_picture_preview: "",
        organizer_profile_picture: "",
      });
    } else {
      temp.organizer_profile_picture = "";
    }
  }

  if ("event_optional_image" in fieldValues) {
    temp.event_optional_image = "";
    if (fieldValues.event_optional_image?.size > 1000000) {
      temp.event_optional_image = t("errors.small_image_size1");
      setValues({
        ...values,
        event_optional_image: "",
        event_optional_image_preview: "",
      });
    } else {
      temp.event_optional_image = "";
    }
  }

  if ("image_rectangle" in fieldValues) {
    temp.image_rectangle = "";
    if (fieldValues.image_rectangle?.size > 1000000) {
      temp.image_rectangle = t("errors.small_image_size1");
      setValues({
        ...values,
        image_rectangle: "",
        image_rectangle_preview: "",
      });
    } else {
      temp.image_rectangle = "";
    }
  }

  setErrors({
    ...temp,
  });
  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};

import moment from "moment";
import React, { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Button from "../../component/Common/Button";
import Input from "../../component/Common/Input";
import Loader from "../../component/Common/Loader";
import { routes } from "../../constants";
import {
  checkIcon,
  crossIcon,
  deleteIcon,
  editIcon,
  searchIcon,
} from "../../icons";
import { getRestaurant } from "../../store/slice/foodBeverageSlice";
import DeleteRestaurant from "./DeleteRestaurant";
import {
  SidebarComponent,
  defaultTimeZone,
} from "../../helpers/commonFunction";

const FoodBeverage = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { page_number } = params;

  const ref = useRef(false);

  const [title, setTitle] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalId, setDeleteModalId] = useState("");
  const [apiErr, setApiErr] = useState(false);

  const { t } = useTranslation("common");

  const sideHeading = localStorage.getItem("sidebar_component");

  const restaurant = useSelector((state) => state.foodBeverage.restaurantList);
  const restaurantLoader = useSelector((state) => state.foodBeverage.loader);

  useEffect(() => {
    if (ref.current) {
      const getRes = setTimeout(() => {
        dispatch(
          getRestaurant({
            restaurant_name: title,
            noAuthority: (error) => {
              if (error) {
                console.log("err", error);
                setApiErr(true);
              } else {
                setApiErr(false);
              }
            },
          })
        );
      }, 500);
      return () => {
        clearTimeout(getRes);
      };
    }
  }, [title]);

  useEffect(() => {
    dispatch(
      getRestaurant({
        restaurant_name: title,
        noAuthority: (error) => {
          if (error) {
            console.log("err", error);
            setApiErr(true);
          } else {
            setApiErr(false);
          }
        },
      })
    );
    ref.current = true;
  }, []);

  const handleInputChange = (e) => {
    setTitle(e.target.value);
  };

  const deleteRestaurantHandler = (menu) => {
    setDeleteModal(true);
    setDeleteModalId(menu.id);
  };

  const handleClose = () => {
    setDeleteModal(false);
    setDeleteModalId("");
  };

  // DD/MM/YYYY, kk:mm:ss
  return (
    <>
      <div className="project_edit_main_content">
        <div className="rolemanagement_project_btn_row_flex">
          <h2 className="project_subheading">{SidebarComponent()}</h2>
          <div className="creat_edit_project_btn_row">
            <Link
              to={routes.createRestaurant}
              state={{ page_number }}
              className="comman_btn ml-auto"
            >
              {t("common.create")}
            </Link>
          </div>
        </div>
        <div className="user_data_search_select_box_row">
          <div className="user_data_search">
            <Input
              inputClassName="user_data_search_input"
              type="search"
              placeholder={t("filter.search_title")}
              id="title"
              name="title"
              value={title}
              onChange={handleInputChange}
            />
            <Button buttonClassName="user_data_search_btn" text={searchIcon} />
          </div>
        </div>
        {restaurantLoader ? (
          <Loader />
        ) : restaurant?.length === 0 ? (
          <h2 className="ta_c c-grey">No results found</h2>
        ) : !restaurant && apiErr ? (
          <h2 className="ta_c c-grey">You have no authority</h2>
        ) : (
          <div className="custom_data_table_content">
            <table className="custom_data_table">
              <thead className="custom_data_table_head">
                <tr>
                  <th className="custom_data_table_heading">
                    {t("form.restaurantName")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("form.owner_name")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.email")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.phoneNumber")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.is_active")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.createdAt")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.updatedAt")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.action")}
                  </th>
                </tr>
              </thead>
              <tbody className="custom_data_table_body">
                {restaurant?.map((menu, i) => {
                  return (
                    <tr className="custom_data_table_row" key={menu.id}>
                      <td className="custom_data_table_item table_item">
                        {menu.restaurant_name}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {menu.owner_name}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {menu.email}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {menu.phone_number && menu.phone_number !== "null"
                          ? "+" + menu.country_code + " " + menu.phone_number
                          : "-"}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {menu.active_status === "1" ? checkIcon : crossIcon}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {menu.created_at &&
                          moment
                            .utc(menu.created_at, "YYYYMMDD HH:mm:ss")
                            .clone()
                            .tz(defaultTimeZone)
                            .format("DD/MM/YYYY, hh:mm A")}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {menu.updated_at
                          ? moment
                              .utc(menu.updated_at, "YYYYMMDD HH:mm:ss")
                              .clone()
                              .tz(defaultTimeZone)
                              .format("DD/MM/YYYY, hh:mm A")
                          : "-"}
                      </td>
                      <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                        <div className="custom_data_table_view_edit_btn_item_row">
                          <div className="tooltip">
                            <Link
                              to={`${routes.foodBeverage}/edit/${menu.id}`}
                              state={{ restaurant: menu }}
                              className="custom_data_table_view_edit_item_btn"
                            >
                              {editIcon}
                            </Link>
                            <span className="tooltiptext">Edit</span>
                          </div>
                          <div className="tooltip">
                            <button
                              className="custom_data_table_view_edit_item_btn"
                              onClick={() => deleteRestaurantHandler(menu)}
                            >
                              {deleteIcon}
                            </button>
                            <span className="tooltiptext">Delete</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        <DeleteRestaurant
          show={deleteModal}
          restaurantDetail={deleteModalId}
          handleClose={handleClose}
          setDeleteModal={setDeleteModal}
          setDeleteModalId={setDeleteModalId}
        />
      </div>
    </>
  );
};

export default FoodBeverage;

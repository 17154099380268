import {
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { routes } from "../../constants";
import Axios, { authHeaders } from "../../helpers/axios";

export const createPurpose = createAsyncThunk(
  "createPurpose",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `sph/purpose`,
        data.data,
        authHeaders()
      );
      if ((response.status === 200 || response.status === 201) && data.cb) {
        toast.success(response.data.message, {
          autoClose: 3000,
        });
        return data.cb(null, response.data);
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

export const updatePurpose = createAsyncThunk(
  "updatePurpose",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `sph/purpose/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 && data.cb && data.only_edit) {
        toast.success(response.data.message, {
          autoClose: 3000,
        });
        return data.cb(null, response.data);
      }
      if (response.status === 200 && data.cb && !data.only_edit) {
        return data.cb(null, response.data);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
    }
  }
);

export const getPurpose = createAsyncThunk(
  "getPurpose",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(`sph/purpose`, authHeaders());

      return { data: response.data, count: response.headers["x-total-count"] };
    } catch (error) {
      if (error.response.status === 403 && data.noAuthority) {
        toast.error(error.response.data.message, {
          autoClose: 3000,
        });
        return data.noAuthority(error.response.data);
      }
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

const purposeSlice = createSlice({
  name: "purpose",
  initialState: {
    purposeList: [],
    createPurposeComponent: "",
    updatePurposeComponent: "",
    error: null,
    loader: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Get Floors
    builder.addCase(getPurpose.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getPurpose.fulfilled, (state, action) => {
      state.loader = false;
      state.purposeList = action.payload;
    });
    builder.addCase(getPurpose.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Create Floor
    builder.addCase(createPurpose.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(createPurpose.fulfilled, (state, action) => {
      state.loader = false;
      state.createPurposeComponent = action.payload;
    });
    builder.addCase(createPurpose.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Update Floor
    builder.addCase(updatePurpose.pending, (state) => {
      state.loader = true;
      state.updateLoader = true;
    });
    builder.addCase(updatePurpose.fulfilled, (state, action) => {
      state.loader = false;
      state.updatePurposeComponent = action.payload;
    });
    builder.addCase(updatePurpose.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default purposeSlice.reducer;

import {
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { routes } from "../../constants";
import Axios, { authHeaders } from "../../helpers/axios";

export const createFloor = createAsyncThunk(
  "createFloor",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/sph/types/building-floor-level`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 || response.status === 201) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        data.navigate(routes.floorLevel);
        return response.data;
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

export const updateFloor = createAsyncThunk(
  "updateFloor",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/sph/types/building-floor-level/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 && !data.cb && !data.toast) {
        toast.success(data.toastText, {
          autoClose: 3000,
        });
        data.navigate(routes.floorLevel);
        return response.data;
      }
      if (response.status === 200 && data.cb) {
        toast.error(data.toastText, {
          autoClose: 3000,
        });
        return data.cb(null, response.data);
      }
    } catch (error) {
      console.log("error", error.response);
    }
  }
);

export const getFloors = createAsyncThunk(
  "getFloors",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/sph/types/building-floor-level?start=${data.start || 0}`,
        authHeaders()
      );

      return { data: response.data, count: response.headers["x-total-count"] };
    } catch (error) {
      if (error.response.status === 403 && data.noAuthority) {
        toast.error(error.response.data.message, {
          autoClose: 3000,
        });
        return data.noAuthority(error.response.data);
      }
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

const floorSlice = createSlice({
  name: "floor",
  initialState: {
    floorsList: [],
    createFloorComponent: "",
    updateFloorComponent: "",
    error: null,
    loader: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Get Floors
    builder.addCase(getFloors.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getFloors.fulfilled, (state, action) => {
      state.loader = false;
      state.floorsList = action.payload;
    });
    builder.addCase(getFloors.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Create Floor
    builder.addCase(createFloor.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(createFloor.fulfilled, (state, action) => {
      state.loader = false;
      state.createFloorComponent = action.payload;
    });
    builder.addCase(createFloor.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Update Floor
    builder.addCase(updateFloor.pending, (state) => {
      state.loader = true;
      state.updateLoader = true;
    });
    builder.addCase(updateFloor.fulfilled, (state, action) => {
      state.loader = false;
      state.updateFloorComponent = action.payload;
    });
    builder.addCase(updateFloor.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default floorSlice.reducer;

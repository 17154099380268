import moment from "moment";
import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../component/Common/Loader";
import { deleteIcon, editIcon } from "../../icons";
import {
  SidebarComponent,
  defaultTimeZone,
} from "../../helpers/commonFunction";
import { toast } from "react-toastify";
import { getDocType, updateDocType } from "../../store/slice/docTypeSlice";
import CreateDocType from "./CreateDocType";
import DeleteDocTypeModal from "./DeleteDocTypeModal";

const DocType = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");

  const docTypeList = useSelector((state) => state.docType.docTypeList?.data);
  const docTypeListLoader = useSelector((state) => state.docType.loader);

  const sideHeading = localStorage.getItem("sidebar_component");

  const [deleteModal, setDeleteModal] = useState(false);
  const [docTypeForm, setDocTypeForm] = useState(false);
  const [deleteDocTypeId, setDeleteDocTypeId] = useState("");
  const [docTypeId, setDocTypeId] = useState();

  const [title, setTitle] = useState("");
  const [titleErr, setTitleErr] = useState("");

  useEffect(() => {
    dispatch(getDocType({}));
  }, [dispatch]);

  if (docTypeListLoader) {
    return <Loader />;
  }

  const openCreateDocTypeModal = () => {
    setDocTypeForm(true);
  };

  const openEditDocTypeModal = (menu) => {
    setDocTypeId(menu.id);
    setTitle(menu.title);
    setDocTypeForm(true);
  };

  const activeDocTypeHandler = (menu) => {
    const data = { is_active: menu.is_active === "1" ? "0" : "1" };
    dispatch(
      updateDocType({
        data,
        id: menu.id,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            toast.success(
              `${t("docType.head")} ${
                menu.is_active === "1"
                  ? t("docType.deactivated")
                  : t("docType.activated")
              } ${t("docType.successfully")}`,
              {
                autoClose: 3000,
              }
            );
            dispatch(getDocType({}));
          }
        },
      })
    );
  };

  const openDeleteModal = (menu) => {
    setDeleteModal(true);
    setDeleteDocTypeId(menu.id);
  };

  const closeDeleteModal = () => {
    setDeleteModal(false);
    setDeleteDocTypeId("");
  };

  return (
    <>
      <div className="project_edit_main_content">
        <div className="rolemanagement_project_btn_row_flex">
          <h2 className="project_subheading">{SidebarComponent()}</h2>
          <div className="creat_edit_project_btn_row">
            <button
              className="comman_btn ml-auto"
              onClick={openCreateDocTypeModal}
            >
              {t("common.create")}
            </button>
          </div>
        </div>
        <div className="custom_data_table_content">
          {docTypeList?.length === 0 ? (
            <h2 className="ta_c c-grey">No results found</h2>
          ) : (
            <table className="custom_data_table">
              <thead className="custom_data_table_head">
                <tr>
                  <th className="custom_data_table_heading">
                    {t("table.title")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.createdAt")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.updatedAt")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.action")}
                  </th>
                </tr>
              </thead>
              <tbody className="custom_data_table_body">
                {docTypeList?.map((menu, i) => {
                  return (
                    <tr className="custom_data_table_row" key={menu.id}>
                      <td className="custom_data_table_item table_item">
                        {menu.title}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {menu.created_at &&
                          moment
                            .utc(menu.created_at, "YYYYMMDD HH:mm:ss")
                            .clone()
                            .tz(defaultTimeZone)
                            .format("DD/MM/YYYY, hh:mm A")}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {menu.updated_at
                          ? moment
                              .utc(menu.updated_at, "YYYYMMDD HH:mm:ss")
                              .clone()
                              .tz(defaultTimeZone)
                              .format("DD/MM/YYYY, hh:mm A")
                          : "-"}
                      </td>
                      <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                        <div className="custom_data_table_view_edit_btn_item_row">
                          <button
                            className={`custom_data_table_view_edit_item_btn hide_compoenent_btn w-77 ${
                              menu.is_active === "0" ? "red" : "green"
                            }`}
                            onClick={() => activeDocTypeHandler(menu)}
                          >
                            {menu.is_active === "0"
                              ? t("feature.deactivate")
                              : t("table.activate")}
                          </button>
                          <div className="tooltip">
                            <button
                              onClick={() => openEditDocTypeModal(menu)}
                              className="custom_data_table_view_edit_item_btn"
                            >
                              {editIcon}
                            </button>
                            <span className="tooltiptext">Edit</span>
                          </div>
                          <div className="tooltip">
                            <button
                              className="custom_data_table_view_edit_item_btn"
                              onClick={() => openDeleteModal(menu)}
                            >
                              {deleteIcon}
                            </button>
                            <span className="tooltiptext">Delete</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <CreateDocType
        setDocTypeForm={setDocTypeForm}
        docTypeForm={docTypeForm}
        docTypeId={docTypeId}
        setDocTypeId={setDocTypeId}
        title={title}
        setTitle={setTitle}
        titleErr={titleErr}
        setTitleErr={setTitleErr}
      />
      <DeleteDocTypeModal
        closeDeleteModal={closeDeleteModal}
        deleteModal={deleteModal}
        deleteDocTypeId={deleteDocTypeId}
        setDeleteModal={setDeleteModal}
        setDeleteDocTypeId={setDeleteDocTypeId}
      />
    </>
  );
};

export default DocType;

import {
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { routes } from "../../constants";
import Axios, { authHeaders } from "../../helpers/axios";

export const createAdminUser = createAsyncThunk(
  "createAdminUser",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/sph/admin-users`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 || response.status === 201) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        data.navigate(`${routes.adminUsers}`, {
          state: { title: data.title },
        });
        return response.data;
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

export const getAdminUser = createAsyncThunk(
  "getAdminUser",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/sph/admin-users?name=${data.name || ""}&phone=${
          data.phone || ""
        }&email=${data.email || ""}`,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      if (error.response.status === 403 && data.noAuthority) {
        toast.error(error.response.data.message, {
          autoClose: 3000,
        });
        return data.noAuthority(error.response.data);
      }
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

export const confirmPassword = createAsyncThunk(
  "confirmPassword",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/sph/users/confirm-password`,
        data.data,
        authHeaders()
      );
      return data.cb(null, response.data);
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

export const updateAdminUser = createAsyncThunk(
  "updateAdminUser",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/sph/admin-users/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 && !data.cb) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        data.navigate(`${routes.adminUsers}`, {
          state: { value: data.value, title: data.title },
        });
        return response.data;
      }
      if (data.cb) {
        toast.error(data.toast, {
          autoClose: 3000,
        });
        return data.cb(null, response.data);
      }
      // return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

const adminUserSlice = createSlice({
  name: "adminUser",
  initialState: {
    adminUserList: [],
    createUser: "",
    error: null,
    loader: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Get Admin
    builder.addCase(getAdminUser.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getAdminUser.fulfilled, (state, action) => {
      state.loader = false;
      state.adminUserList = action.payload;
    });
    builder.addCase(getAdminUser.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Create Admin
    builder.addCase(createAdminUser.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(createAdminUser.fulfilled, (state, action) => {
      state.loader = false;
      state.createUser = action.payload;
    });
    builder.addCase(createAdminUser.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default adminUserSlice.reducer;

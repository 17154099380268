import { toast } from "react-toastify";
import { closeIcon } from "../icons";
import { useLocation } from "react-router-dom";
const moment = require("moment-timezone");

const toastList = new Set();
const MAX_TOAST = 1;

export const defaultTimeZone = moment.tz.guess();

// One time toast message show //
export function notify(test, message) {
  if (toastList.size < MAX_TOAST) {
    let id;
    if (message === "success") {
      id = toast.success(test, {
        // I'm using the onClose hook here to remove the id
        // from the set
        autoClose: 1500,
        onClose: () => toastList.delete(id),
      });
    } else {
      id = toast.error(test, {
        // I'm using the onClose hook here to remove the id
        // from the set
        autoClose: 1500,
        onClose: () => toastList.delete(id),
      });
    }
    toastList.add(id);
  }
}

// after uploading images preview it

export const imagePreviewProps = (image, src, imageRemoveHandler) => {
  return (
    image && (
      <>
        <img src={src} alt="benefits logo" />
        <button className="image_remove_btn" onClick={imageRemoveHandler}>
          {closeIcon}
        </button>
      </>
    )
  );
};

// For Drag & Drop functionality
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

// Website address validation
export const webaddressValidation = (address) => {
  if (address?.includes("https://")) {
    return address;
  } else {
    return "https://" + address;
  }
};

export const SidebarComponent = () => {
  const sideHeading = localStorage.getItem("sidebar_component");
  let headingName = "";
  const { pathname } = useLocation();

  if (pathname.includes("/dashboard")) {
    return (headingName = "Dashboard");
  } else if (pathname.includes("/purpose")) {
    return (headingName = "Purpose");
  } else if (pathname.includes("/doc-type")) {
    return (headingName = "Doc Type");
  } else if (pathname.includes("/just-co")) {
    return (headingName = "JustCo Config");
  } else if (pathname.includes("/admin-user")) {
    return (headingName = "Admin User");
  } else if (pathname.includes("/users")) {
    return (headingName = "Mobile User");
  } else if (pathname.includes("/contacts")) {
    return (headingName = "Contact");
  } else if (pathname.includes("/services")) {
    return (headingName = "Service Request");
  } else if (pathname.includes("/settings")) {
    return (headingName = "Setting");
  } else if (pathname.includes("/directory")) {
    return (headingName = "Directory");
  } else if (pathname.includes("/announcements")) {
    return (headingName = "Announcement");
  } else if (pathname.includes("/feedback")) {
    return (headingName = "Feedback");
  } else if (pathname.includes("/purpose")) {
    return (headingName = "Purpose");
  } else if (pathname.includes("/notifications")) {
    return (headingName = "Notification");
  } else if (pathname.includes("/events")) {
    return (headingName = "News & Events");
  } else if (pathname.includes("/promotions")) {
    return (headingName = "Promotions");
  } else if (pathname.includes("/food_beverage")) {
    return (headingName = "Food & Beverage");
  } else if (pathname.includes("/rolemanagement")) {
    return (headingName = "Role");
  } else if (pathname.includes("/visitor-invite")) {
    return (headingName = "Visitor Invite");
  } else if (pathname.includes("/floorlevel")) {
    return (headingName = "Floor Level");
  } else if (pathname.includes("/amenities")) {
    return (headingName = "Amenity");
  } else if (pathname.includes("/features")) {
    return (headingName = "Feature");
  } else if (pathname.includes("/sidemenu")) {
    return (headingName = "Sidemenu");
  } else {
    return (headingName = sideHeading);
  }
};

import React, { useState } from "react";
import PhoneInput from "react-phone-number-input";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../component/Common/Button";
import Input from "../../component/Common/Input";
import {} from "../../constants";
import {
  SidebarComponent,
  imagePreviewProps,
} from "../../helpers/commonFunction";
import { useForm } from "../../hooks/useForm";
import { addIcon, editIcon, eyeIcon, hideEyeIcon } from "../../icons";
import Select from "react-select";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRoles } from "../../store/slice/rolemanagementSlice";
import {
  adminUserValidation,
  generate_password,
} from "../../component/Common/PageComponent/AdminUserComponents";
import { withoutMetadata } from "../../component/Common/PageComponent/LoginPageComponents";
import { parsePhoneNumber } from "react-phone-number-input";
import { fileUpload } from "../../store/slice/fileUploadSlice";
import {
  confirmPassword,
  createAdminUser,
  updateAdminUser,
} from "../../store/slice/adminUserSlice";
import Loader from "../../component/Common/Loader";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const CreateAdminUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation("common");
  const editComponent = location?.state?.user;
  const value = location?.state?.value;
  const sideHeading = localStorage.getItem("sidebar_component");

  let initialImagePreview;
  if (editComponent?.profile_image) {
    initialImagePreview = `${process.env.REACT_APP_FILE_BASE_URL}/${editComponent?.profile_image}`;
  }

  // const userName =
  //   localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));

  const roleList = useSelector((state) => state.rolemanagement.rolesList?.data);
  const adminLoader = useSelector((state) => state.rolemanagement.loader);
  let fileUploadLoader = useSelector((state) => state.fileUpload.loader);

  const roleOptions = roleList
    ?.filter((item, i) => item.is_default !== "1")
    ?.map((role) => {
      return {
        value: role.role_display_name,
        label: role.role_display_name,
        id: role.id,
      };
    });

  useEffect(() => {
    dispatch(getRoles({ adminType: 1 }));
  }, [dispatch]);

  const initialFValues = {
    first_name: editComponent ? editComponent.first_name : "",
    last_name: editComponent ? editComponent.last_name : "",
    phone_number:
      editComponent && editComponent?.phone_number !== ""
        ? "+" + editComponent.country_code + editComponent.phone_number
        : "",
    email: editComponent ? editComponent?.email : "",
    user_role_id: editComponent
      ? {
          value: editComponent.user_role_id,
          id: editComponent.user_role_id,
          label: editComponent.role_display_name,
        }
      : "",
    profile_image: editComponent ? editComponent.profile_image : "",
    profile_image_preview: editComponent ? initialImagePreview : "",
    changePasswordComponent: false,
    current_password: "",
    currentPasswordType: "password",
    current_password_err: "",
    new_password: "",
    newPasswordType: "password",
    new_password_err: "",
    confirm_password: "",
    conPasswordType: "password",
    confirm_password_err: "",
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    return adminUserValidation(
      fieldValues,
      temp,
      values,
      setValues,
      setErrors,
      t
    );
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const changePasswordComponentHandler = () => {
    setValues({ ...values, changePasswordComponent: true });
  };

  const backBtnHandler = () => {
    setValues({
      ...values,
      changePasswordComponent: false,
      current_password_err: "",
      new_password_err: "",
      confirm_password_err: "",
    });
  };

  const imageRemoveHandler = () => {
    setValues({ ...values, profile_image: "", profile_image_preview: "" });
  };

  // const curPassEyeIconHandler = () => {
  //   setValues({
  //     ...values,
  //     currentPasswordType:
  //       values.currentPasswordType === "password" ? "text" : "password",
  //   });
  // };

  const newPassEyeIconHandler = () => {
    setValues({
      ...values,
      newPasswordType:
        values.newPasswordType === "password" ? "text" : "password",
    });
  };

  const conPassEyeIconHandler = () => {
    setValues({
      ...values,
      conPasswordType:
        values.conPasswordType === "password" ? "text" : "password",
    });
  };

  // const currentPassHandler = (e) => {
  //   setValues({
  //     ...values,
  //     current_password: e.target.value,
  //     current_password_err: "",
  //   });
  // };

  const newPassHandler = (e) => {
    setValues({
      ...values,
      new_password: e.target.value,
      new_password_err: "",
    });
  };
  const conPassHandler = (e) => {
    setValues({
      ...values,
      confirm_password: e.target.value,
      confirm_password_err: "",
    });
  };

  const contactHandler = async () => {
    if (validate()) {
      const parsedPhone =
        values.phone_number &&
        withoutMetadata(parsePhoneNumber(values.phone_number));

      const formData = new FormData();
      let attachmentUrl;
      if (values.profile_image && typeof values.profile_image !== "string") {
        formData.append("file", values.profile_image);
        attachmentUrl = await dispatch(fileUpload(formData));
        if (attachmentUrl && attachmentUrl.payload) {
          setValues({
            ...values,
            profile_image: attachmentUrl.payload,
          });
        }
        formData.delete("file");
      } else {
        attachmentUrl = values.profile_image;
      }

      const data = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        country_code: parsedPhone ? parsedPhone?.countryCallingCode : "",
        phone_number: parsedPhone ? parsedPhone?.nationalNumber : "",
        user_role_id: values.user_role_id.id,
        profile_image:
          typeof values.profile_image !== "string"
            ? attachmentUrl.payload
            : attachmentUrl,
      };
      if (!editComponent) {
        data.password = generate_password();
      }

      editComponent
        ? dispatch(
            updateAdminUser({
              data,
              navigate,
              id: editComponent.id,
              toast: t("toast.user_update"),
              value,
            })
          )
        : dispatch(
            createAdminUser({
              data,
              navigate,
              toast: t("toast.user"),
            })
          );
    }
  };

  const validatePassword = () => {
    let isValid = false;

    // if (!values.current_password) {
    //   setValues({
    //     ...values,
    //     current_password_err: "Current password is required!",
    //   });
    // }
    // else
    if (!values.new_password) {
      setValues({
        ...values,
        new_password_err: "New password is required!",
      });
    } else if (!values.confirm_password) {
      setValues({
        ...values,
        confirm_password_err: "Confirm password is required!",
      });
    } else if (values.confirm_password !== values.new_password) {
      setValues({
        ...values,
        confirm_password_err: "Password & Confirm Password should be same!",
      });
    } else {
      isValid = true;
    }

    return isValid;
  };

  const updatePasswordHandler = () => {
    if (validatePassword()) {
      const data = {
        // current_password: values.current_password,
        user_id: editComponent?.id,
        new_password: values.new_password,
        confirm_password: values.confirm_password,
      };

      dispatch(
        confirmPassword({
          data,
          cb: (err, res) => {
            if (err) {
              console.log("err", err);
            } else {
              toast.success(t("toast.change_password"), {
                autoClose: 3000,
              });
              setValues({
                ...values,
                current_password: "",
                new_password: "",
                confirm_password: "",
                changePasswordComponent: false,
              });
            }
          },
        })
      );
    }
  };

  if (fileUploadLoader || adminLoader) {
    return <Loader />;
  }

  return (
    <>
      {!values.changePasswordComponent ? (
        <>
          <div className="comman_btn_container center_back_btn jc_sb announcement_page-container">
            <h2 className="mobile_configuration_heading">
              {editComponent
                ? `${SidebarComponent()} > Edit`
                : `${SidebarComponent()} > Create`}
            </h2>
            <div>
              {/* {editComponent &&
                userName.first_name + " " + userName.last_name ===
                  editComponent?.first_name +
                    " " +
                    editComponent?.last_name && ( */}
              <button
                className="create_link_btn mr-20"
                onClick={changePasswordComponentHandler}
              >
                Change Password
              </button>
              {/* )} */}
              <button
                onClick={() =>
                  navigate(`/admin-user`, {
                    state: {
                      value,
                    },
                  })
                }
                className="comman_btn ml-auto secondary_btn"
              >
                {t("common.back")}
              </button>
            </div>
          </div>
          <div className="project_edit_main_content">
            <div className="create_from_row">
              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label required"
                inputClassName="create_from_input"
                errorClassName="err_text"
                type="text"
                placeholder={t("form.firstnamePlace")}
                label={t("form.firstname")}
                id="first_name"
                name="first_name"
                value={values.first_name}
                onChange={handleInputChange}
                error={errors.first_name || ""}
              />
              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label required"
                inputClassName="create_from_input"
                errorClassName="err_text"
                type="text"
                placeholder={t("form.lastnamePlace")}
                label={t("form.lastname")}
                id="last_name"
                name="last_name"
                value={values.last_name}
                onChange={handleInputChange}
                error={errors.last_name || ""}
              />

              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label required"
                inputClassName={`create_from_input ${
                  editComponent && "disabled_input"
                }`}
                errorClassName="err_text"
                type="email"
                placeholder={t("form.emailPlace")}
                label={t("form.email")}
                id="email"
                name="email"
                disabled={editComponent}
                value={values.email}
                onChange={handleInputChange}
                error={errors.email || ""}
              />

              <div className="login_input_row mb-0">
                <label>{t("form.phoneNumber")}</label>
                <PhoneInput
                  placeholder={t("form.phoneNumberPlace")}
                  value={values.phone_number}
                  defaultCountry="TH"
                  onChange={(e) => {
                    setValues({
                      ...values,
                      phone_number: e,
                    });
                    setErrors({ ...errors, phone_number: "" });
                  }}
                />
                {errors.phone_number && (
                  <span className="err_text">{errors.phone_number}</span>
                )}
              </div>

              <div className="project_edit_feature_input mt-0">
                <label className="create_from_label required">
                  {t("form.userRole")}
                </label>
                <Select
                  placeholder={t("form.userRolePlace")}
                  value={values.user_role_id}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  name="user_role_id"
                  options={roleOptions}
                  onChange={(e) => handleInputChange(e, "user_role_id")}
                />
                {errors.user_role_id && (
                  <span className="err_text">{errors.user_role_id}</span>
                )}
              </div>

              {/* <Input
            className="login_input_row m-0 null"
            errorClassName="err_text"
            type={values.currentPasswordType}
            placeholder={t("form.currentPasswordPlace")}
            label={t("form.currentPassword")}
            id="current_password"
            name="current_password"
            value={values?.current_password}
            onChange={handleInputChange}
            eyeIcon={
              values.currentPasswordType === "password" ? eyeIcon : hideEyeIcon
            }
            eyeIconHandler={curPassEyeIconHandler}
            autoComplete="on"
            // error={errors?.current_password || ""}
          />
          <Input
            className="login_input_row m-0 null"
            errorClassName="err_text"
            type={values.newPasswordType}
            placeholder={t("form.newPasswordPlace")}
            label={t("form.newPassword")}
            id="new_password"
            name="new_password"
            value={values?.new_password}
            onChange={handleInputChange}
            eyeIcon={
              values.newPasswordType === "password" ? eyeIcon : hideEyeIcon
            }
            eyeIconHandler={newPassEyeIconHandler}
            autoComplete="on"
            // error={errors?.new_password || ""}
          />
          <Input
            className="login_input_row m-0 null"
            errorClassName="err_text"
            type={values.conPasswordType}
            placeholder={t("form.confirmPasswordPlace")}
            label={t("form.confirmPassword")}
            id="confirm_password"
            name="confirm_password"
            value={values?.confirm_password}
            onChange={handleInputChange}
            eyeIcon={
              values.conPasswordType === "password" ? eyeIcon : hideEyeIcon
            }
            eyeIconHandler={conPassEyeIconHandler}
            autoComplete="on"
            // error={errors?.confirm_password || ""}
          /> */}

              <div className="project_edit_detail_divider">
                <label className="create_from_label">
                  {t("form.profileImage")}
                </label>
                <div className="project_edit_feature_input_file">
                  <Input
                    className="create_from_input_content"
                    labelClassName="create_from_label"
                    errorClassName="err_text"
                    type="file"
                    id={`profile_image`}
                    labelOtherProps={
                      <span>{values.profile_image ? editIcon : addIcon}</span>
                    }
                    name="profile_image"
                    accept="image/*"
                    onChange={handleInputChange}
                    onClick={(e) => (e.target.value = null)}
                    imageProps={imagePreviewProps(
                      values.profile_image,
                      values.profile_image_preview,
                      imageRemoveHandler
                    )}
                  />
                </div>
                <p className="create_project_review mb-0 block">
                  {t("form.small_size_desc")}
                </p>
                {errors.profile_image && (
                  <span className="err_text">{errors.profile_image}</span>
                )}
              </div>
            </div>
            <Button
              className="project_submit_bottom_btn center_back_btn"
              buttonClassName="comman_btn"
              onClick={contactHandler}
              text={`${editComponent ? t("common.save") : t("common.create")}`}
            />
          </div>
        </>
      ) : (
        <>
          <div className="comman_btn_container center_back_btn jc_sb announcement_page-container">
            {/* <h2 className="mobile_configuration_heading">{`${
              editComponent
                ? t("adminUser.editHead")
                : t("adminUser.createHead")
            }`}</h2> */}
            <button
              onClick={backBtnHandler}
              className="comman_btn ml-auto secondary_btn"
            >
              {t("common.back")}
            </button>
          </div>
          <div className="project_edit_main_content">
            <div className="create_from_row">
              {/* <Input
                className="login_input_row m-0 null"
                errorClassName="err_text"
                type={values.currentPasswordType}
                placeholder={t("form.currentPasswordPlace")}
                label={t("form.currentPassword")}
                id="current_password"
                name="current_password"
                value={values?.current_password}
                onChange={currentPassHandler}
                eyeIcon={
                  values.currentPasswordType === "password"
                    ? eyeIcon
                    : hideEyeIcon
                }
                eyeIconHandler={curPassEyeIconHandler}
                autoComplete="on"
                error={values?.current_password_err || ""}
              /> */}
              <Input
                className="login_input_row m-0 null"
                errorClassName="err_text"
                type={values.newPasswordType}
                placeholder={t("form.newPasswordPlace")}
                label={t("form.newPassword")}
                id="new_password"
                name="new_password"
                value={values.new_password}
                onChange={newPassHandler}
                eyeIcon={
                  values.newPasswordType === "password" ? eyeIcon : hideEyeIcon
                }
                eyeIconHandler={newPassEyeIconHandler}
                autoComplete="on"
                error={values.new_password_err || ""}
              />
              <Input
                className="login_input_row m-0 null"
                errorClassName="err_text"
                type={values.conPasswordType}
                placeholder={t("form.confirmPasswordPlace")}
                label={t("form.confirmPassword")}
                id="confirm_password"
                name="confirm_password"
                value={values.confirm_password}
                onChange={conPassHandler}
                eyeIcon={
                  values.conPasswordType === "password" ? eyeIcon : hideEyeIcon
                }
                eyeIconHandler={conPassEyeIconHandler}
                autoComplete="on"
                error={values.confirm_password_err || ""}
              />
            </div>
            <Button
              className="project_submit_bottom_btn center_back_btn"
              buttonClassName="comman_btn"
              onClick={updatePasswordHandler}
              text={`${editComponent ? t("common.save") : t("common.create")}`}
            />
          </div>
        </>
      )}
    </>
  );
};

export default CreateAdminUser;

import React from "react";
import PhoneInput from "react-phone-number-input";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Input from "../../component/Common/Input";
import { routes } from "../../constants";
import CreatableSelect from "react-select/creatable";
import { addIcon, editIcon, removeIcon } from "../../icons";
import Button from "../../component/Common/Button";
import { useForm } from "../../hooks/useForm";
import {
  SidebarComponent,
  imagePreviewProps,
} from "../../helpers/commonFunction";
import { useDispatch, useSelector } from "react-redux";
import {
  createContact,
  getContactLinkType,
  getContactPost,
  updateContact,
} from "../../store/slice/contactSlice";
import Loader from "../../component/Common/Loader";
import {
  contactData,
  contactValidation,
  handleInputChangePost,
} from "../../component/Common/PageComponent/ContactComponents";
import { useEffect } from "react";
import Select from "react-select";
import ContactLink from "./ContactLink";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteContactLinkModal from "./DeleteContactLinkModal";

const CreateContact = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const editComponent = location?.state?.menu;
  const value = location?.state?.value;
  const valuePage = location?.state?.page_number;

  const { t } = useTranslation("common");
  const sideHeading = localStorage.getItem("sidebar_component");

  const [updateState, setUpdateState] = useState();

  useEffect(() => {
    if (updateState) {
      setValues({
        ...values,
        contactLink_name: updateState.contact_link_type
          ? updateState.contact_link_type
          : "",
        contactLink_icon: updateState.contact_link_image
          ? updateState.contact_link_image
          : "",
        contactLink_icon_preview: updateState?.contact_link_image
          ? `${process.env.REACT_APP_FILE_BASE_URL}/${updateState.contact_link_image}`
          : "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateState]);

  const contactLoader = useSelector((state) => state.contact.loader);

  let initialImagePreview;

  const contactPosts = useSelector((state) => state.contact.contactPostList);
  const contactLinks = useSelector((state) => state.contact.contactLinkList);

  const postList = contactPosts?.map((post, i) => {
    return { value: post.contact_post, label: post.contact_post };
  });

  const contactLinkTypeOptions = contactLinks?.map((link, i) => {
    return {
      value: link.contact_link_type,
      label: link.contact_link_type,
      image: link.contact_link_image,
    };
  });

  useEffect(() => {
    setErrors({ ...errors, contactLink_name: "", contactLink_icon: "" });
    dispatch(getContactPost());
    dispatch(getContactLinkType());
  }, [dispatch]);

  if (editComponent?.contact_profile_image) {
    initialImagePreview = `${process.env.REACT_APP_FILE_BASE_URL}/${editComponent?.contact_profile_image}`;
  }

  const contactLinksObject =
    editComponent?.contact_link &&
    JSON.parse(editComponent?.contact_link).length > 0
      ? JSON.parse(editComponent?.contact_link)?.map((item, i) => {
          return {
            contact_link_type: {
              label: item.contact_link_type,
              value: item.contact_link_type,
            },
            contact_link_name: item.contact_link_name,
            contact_link_image: item.contact_link_image,
          };
        })
      : [
          {
            contact_link_type: "",
            contact_link_name: "",
            contact_link_image: "",
          },
        ];

  const initialFValues = {
    first_name: editComponent ? editComponent.contact_first_name : "",
    last_name: editComponent ? editComponent.contact_last_name : "",
    // phone_number: editComponent ? "+" + editComponent.contact_country_code + editComponent.contact_phone_number : "",
    contact_phone_number: editComponent
      ? editComponent.contact_phone_number
      : "",
    contact_link: editComponent
      ? contactLinksObject
      : [
          {
            contact_link_type: "",
            contact_link_name: "",
            contact_link_image: "",
          },
        ],
    contact_post: editComponent
      ? { value: editComponent.contact_post, label: editComponent.contact_post }
      : "",
    profile_image: editComponent ? editComponent.contact_profile_image : "",
    profile_image_preview: editComponent ? initialImagePreview : "",
    email: editComponent ? editComponent?.email : "",
    position: editComponent ? editComponent?.contact_position : "",
    company: editComponent ? editComponent?.company : "",
    contactLinkComponent: false,
    contactLink_name: "",
    contactLink_icon: "",
    contactLink_icon_preview: "",
    deleteModal: false,
    deleteModalId: "",
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    return contactValidation(
      fieldValues,
      temp,
      values,
      setValues,
      setErrors,
      t
    );
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const imageRemoveHandler = () => {
    setValues({ ...values, profile_image: "", profile_image_preview: "" });
  };

  const contactLinkNameHandler = (e, i) => {
    const list = [...values.contact_link];
    list[i]["contact_link_name"] = e.target.value;
    setValues({
      ...values,
      contact_link: list,
    });
  };

  const contactLinkTypehandler = (e, i) => {
    const list = [...values.contact_link];
    list[i]["contact_link_type"] = { label: e.label, value: e.label };
    list[i]["contact_link_image"] = e.image;
    setValues({
      ...values,
      contact_link: list,
    });
  };

  const contactLinkHandleRemoveClick = (index) => {
    const list = [...values.contact_link];
    list.splice(index, 1);
    setValues({
      ...values,
      contact_link: list,
    });
  };

  const contactLinkHandleAddClick = () => {
    setValues({
      ...values,
      contact_link: [
        ...values.contact_link,
        {
          contact_link_type: "",
          contact_link_name: "",
        },
      ],
    });
  };

  const createLinkHandler = () => {
    setValues({ ...values, contactLinkComponent: true });
  };

  const handleCloseHandler = () => {
    setValues({
      ...values,
      deleteModal: false,
      deleteModalId: "",
    });
  };

  const contactHandler = async () => {
    if (validate()) {
      const data = await contactData(values, dispatch, setValues);

      editComponent
        ? dispatch(
            updateContact({
              data,
              navigate,
              id: editComponent.id,
              toast: t("toast.update_contact"),
              value,
              valuePage,
            })
          )
        : dispatch(
            createContact({ data, navigate, toast: t("toast.create_contact") })
          );
    }
  };

  if (contactLoader) {
    return <Loader />;
  }

  return (
    <>
      {values.contactLinkComponent ? (
        <>
          <div className="comman_btn_container center_back_btn announcement_page-container">
            <button
              onClick={() => {
                setValues({
                  ...values,
                  contactLinkComponent: false,
                  contactLink_name: "",
                  contactLink_icon: "",
                  contactLink_icon_preview: "",
                });
                setErrors({
                  ...errors,
                  contactLink_name: "",
                  contactLink_icon: "",
                });
                setUpdateState("");
              }}
              className="comman_btn ml-auto secondary_btn"
            >
              {t("common.back")}
            </button>
          </div>
          <div className="project_edit_main_content">
            <ContactLink
              updateState={updateState}
              setUpdateState={setUpdateState}
              values={values}
              setValues={setValues}
              errors={errors}
              setErrors={setErrors}
              handleInputChange={handleInputChange}
            />
          </div>
        </>
      ) : (
        <>
          <div className="comman_btn_container center_back_btn announcement_page-container">
            <h2 className="mobile_configuration_heading">
              {editComponent
                ? `${SidebarComponent()} > Edit`
                : `${SidebarComponent()} > Create`}
            </h2>
            <Link
              to={`${routes.contact}/page/${valuePage}`}
              state={{ value, valuePage }}
              className="comman_btn ml-auto secondary_btn"
            >
              {t("common.back")}
            </Link>
          </div>
          <div className="project_edit_main_content">
            <div className="create_from_row">
              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label required"
                inputClassName="create_from_input"
                errorClassName="err_text"
                type="text"
                placeholder={t("form.firstnamePlace")}
                label={t("form.firstname")}
                id="first_name"
                name="first_name"
                value={values.first_name}
                onChange={handleInputChange}
                error={errors.first_name || ""}
              />
              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label required"
                inputClassName="create_from_input"
                errorClassName="err_text"
                type="text"
                placeholder={t("form.lastnamePlace")}
                label={t("form.lastname")}
                id="last_name"
                name="last_name"
                value={values.last_name}
                onChange={handleInputChange}
                error={errors.last_name || ""}
              />
              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label required"
                inputClassName="create_from_input"
                errorClassName="err_text"
                type="text"
                placeholder={t("form.company_place")}
                label={t("form.company")}
                id="company"
                name="company"
                value={values.company}
                onChange={handleInputChange}
                error={errors.company || ""}
              />

              <div className={`create_from_input_content`}>
                <label className="create_from_label required">
                  {t("table.department")}
                </label>
                <CreatableSelect
                  placeholder={t("table.department_place")}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  value={values.contact_post}
                  onChange={(e) => handleInputChange(e, "contact_post")}
                  onInputChange={handleInputChangePost}
                  options={postList}
                />
                {errors.contact_post && (
                  <span className="err_text">{errors.contact_post}</span>
                )}
              </div>
              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label required"
                inputClassName="create_from_input"
                errorClassName="err_text"
                type="text"
                placeholder={t("table.phoneNumber")}
                label={t("table.display_phoneNumber")}
                id="contact_phone_number"
                name="contact_phone_number"
                value={values.contact_phone_number}
                onChange={handleInputChange}
                error={errors.contact_phone_number || ""}
              />
              {/* <div className="login_input_row mb-0">
                <label className="required">{t("form.phoneNumber")}</label> */}
              {/* <PhoneInput
                  placeholder={t("form.phoneNumberPlace")}
                  value={values.phone_number}
                  defaultCountry="TH"
                  onChange={(e) => {
                    setValues({
                      ...values,
                      phone_number: e,
                    });
                    setErrors({ ...errors, phone_number: "" });
                  }}
                /> */}
              {/* {errors.phone_number && (
                  <span className="err_text">{errors.phone_number}</span>
                )}
              </div> */}
              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label required"
                inputClassName="create_from_input"
                errorClassName="err_text"
                type="email"
                placeholder={t("form.emailPlace")}
                label={t("form.email")}
                id="email"
                name="email"
                value={values.email}
                onChange={handleInputChange}
                error={errors.email || ""}
              />
              <div>
                <div className="create_from_label_btn_container">
                  <label className="create_from_label">{t("table.link")}</label>
                  <button
                    className="create_link_btn create_link_btn_link"
                    onClick={createLinkHandler}
                  >
                    {t("contact.create_contact_link_type")}
                  </button>
                </div>
                {values.contact_link?.map((link, i) => {
                  return (
                    <>
                      <div className="create_from_input_content tower_dropdown">
                        <Select
                          placeholder={t("contact.contact_link_place")}
                          value={link.contact_link_type}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          name="contact_link_type"
                          options={contactLinkTypeOptions}
                          onChange={(e) => contactLinkTypehandler(e, i)}
                        />
                      </div>
                      <Input
                        className="create_from_input_content "
                        labelClassName="create_from_label"
                        inputClassName="create_from_input"
                        errorClassName="err_text"
                        type="text"
                        placeholder={t("contact.contact_link_name")}
                        id="contact_link_name"
                        name="contact_link_name"
                        value={link.contact_link_name}
                        onChange={(e) => contactLinkNameHandler(e, i)}
                      />
                      <div className="project_edit_feature_add_remove_btn_row">
                        {values.contact_link.length !== 1 && (
                          <Button
                            buttonClassName="project_edit_feature_add_remove_btn project_edit_feature_add_btn contact_remove_btn"
                            onClick={() => contactLinkHandleRemoveClick(i)}
                            text={t("contact.remove")}
                            other={removeIcon}
                          />
                        )}
                        {values.contact_link.length - 1 === i && (
                          <Button
                            buttonClassName="project_edit_feature_add_remove_btn project_edit_feature_add_btn contact_add_btn"
                            onClick={() => contactLinkHandleAddClick()}
                            text={t("contact.add")}
                            other={addIcon}
                          />
                        )}
                      </div>
                    </>
                  );
                })}
              </div>

              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label required"
                inputClassName="create_from_input"
                errorClassName="err_text"
                type="text"
                placeholder={t("form.position_place")}
                label={t("form.position")}
                id="position"
                name="position"
                value={values.position}
                onChange={handleInputChange}
                error={errors.position || ""}
              />

              <div className="project_edit_detail_divider">
                <label className="create_from_label required">
                  {t("form.profileImage")}
                </label>
                <div className="project_edit_feature_input_file">
                  <Input
                    className="create_from_input_content"
                    labelClassName="create_from_label"
                    errorClassName="err_text"
                    type="file"
                    id={`profile_image`}
                    labelOtherProps={
                      <span>{values.profile_image ? editIcon : addIcon}</span>
                    }
                    name="profile_image"
                    accept="image/*"
                    onChange={handleInputChange}
                    onClick={(e) => (e.target.value = null)}
                    imageProps={imagePreviewProps(
                      values.profile_image,
                      values.profile_image_preview,
                      imageRemoveHandler
                    )}
                  />
                </div>
                <p className="create_project_review mb-0 block">
                  {t("form.small_size_desc")}
                </p>
                {errors.profile_image && (
                  <span className="err_text">{errors.profile_image}</span>
                )}
              </div>
            </div>
            <Button
              className="project_submit_bottom_btn center_back_btn"
              buttonClassName="comman_btn"
              onClick={contactHandler}
              text={`${editComponent ? t("common.save") : t("common.create")}`}
            />
          </div>
        </>
      )}
      <DeleteContactLinkModal
        values={values}
        setValues={setValues}
        handleClose={handleCloseHandler}
      />
    </>
  );
};

export default CreateContact;

import moment from "moment";
import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "../../component/Common/Button";
import Input from "../../component/Common/Input";
import Loader from "../../component/Common/Loader";
import Pagination from "../../component/Pagination/Pagination";
import { routes } from "../../constants";
import { useForm } from "../../hooks/useForm";
import { deleteIcon, editIcon, searchIcon, viewIcon } from "../../icons";
import { getDirectory } from "../../store/slice/directorySlice";
import DeleteDirectoryModal from "./DeleteDirectoryModal";
import ViewModalDirectory from "./ViewModalDirectory";
import {
  SidebarComponent,
  defaultTimeZone,
} from "../../helpers/commonFunction";
import ViewImageModal from "../../component/Common/PageComponent/ViewImageModal";

const Directory = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const ref = useRef(false);
  const { page_number } = params;
  const [pageN, setPageN] = useState(page_number - 1);

  const sideHeading = localStorage.getItem("sidebar_component");

  const location = useLocation();
  const page = location?.state?.page;
  const value = location?.state?.value;

  const { t } = useTranslation("common");

  const [imgZoom, setImgZoom] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const [apiErr, setApiErr] = useState(false);

  const directories = useSelector(
    (state) => state.directory.directoryList?.data
  );
  const directoryLoader = useSelector((state) => state.directory.loader);
  const directoriesCount = useSelector(
    (state) => state.directory.directoryList?.count
  );

  const initialFValues = {
    viewModal: false,
    viewModalData: "",
    deleteModal: false,
    deleteModalData: "",
    directory_type_name: value?.directory_type_name
      ? value?.directory_type_name
      : "",
    floor: value?.floor ? value?.floor : "",
  };

  const { values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
  );

  useEffect(() => {
    if (ref.current) {
      navigate(`${routes.directory}/page/1`);
      if (+pageN === 0) {
        const timer = setTimeout(() => {
          dispatch(
            getDirectory({
              directory_type_name: values.directory_type_name,
              floor: values.floor,
              noAuthority: (error) => {
                if (error) {
                  console.log("err", error);
                  setApiErr(true);
                } else {
                  setApiErr(false);
                }
              },
            })
          );
        }, 500);
        return () => clearTimeout(timer);
      }
    }
  }, [dispatch, values.directory_type_name, values.floor]);

  useEffect(() => {
    if (ref.current) {
      dispatch(
        getDirectory({
          start: pageN,
          directory_type_name: values.directory_type_name,
          floor: values.floor,
          noAuthority: (error) => {
            if (error) {
              console.log("err", error);
              setApiErr(true);
            } else {
              setApiErr(false);
            }
          },
        })
      );
    }
  }, [pageN]);

  useEffect(() => {
    dispatch(
      getDirectory({
        start: (page - 1) * 10,
        directory_type_name: values.directory_type_name,
        floor: values.floor,
        noAuthority: (error) => {
          if (error) {
            console.log("err", error);
            setApiErr(true);
          } else {
            setApiErr(false);
          }
        },
      })
    );
    ref.current = true;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const viewDirectoryHandler = (menu) => {
    setValues({ ...values, viewModal: true, viewModalData: menu });
  };

  const handleClose = () => {
    setValues({ ...values, viewModal: false, viewModalData: "" });
  };

  const handleDeleteDirectoryClose = () => {
    setValues({ ...values, deleteModal: false, deleteModalData: "" });
  };

  const deleteDirectoryHandler = (menu) => {
    setValues({ ...values, deleteModal: true, deleteModalData: menu });
  };

  const onPageChange = (page_number) => {
    if (ref.current) {
      setPageN(page_number);
    }
  };

  // if (directoryLoader) {
  //   return <Loader />;
  // }

  return (
    <>
      <div className="project_edit_main_content">
        <div className="rolemanagement_project_btn_row_flex">
          <h2 className="project_subheading">{SidebarComponent()}</h2>
          <div className="creat_edit_project_btn_row">
            <Link
              to={routes.createDirectory}
              state={{ page_number }}
              className="comman_btn ml-auto"
            >
              {t("common.create")}
            </Link>
          </div>
        </div>

        <div className="user_data_search_select_box_row">
          <div className="user_data_search">
            <Input
              inputClassName="user_data_search_input"
              type="search"
              placeholder={t("filter.search_directory_type")}
              id="directory_type_name"
              name="directory_type_name"
              value={values.directory_type_name}
              onChange={handleInputChange}
            />
            <Button buttonClassName="user_data_search_btn" text={searchIcon} />
          </div>
          <div className="user_data_search">
            <Input
              inputClassName="user_data_search_input"
              type="search"
              placeholder={t("floor.floor/level")}
              id="floor"
              name="floor"
              value={values.floor}
              onChange={handleInputChange}
            />
            <Button buttonClassName="user_data_search_btn" text={searchIcon} />
          </div>
        </div>

        {directoryLoader ? (
          <Loader />
        ) : directories?.length === 0 ? (
          <h2 className="ta_c c-grey">No results found</h2>
        ) : !directories && apiErr ? (
          <h2 className="ta_c c-grey">You have no authority</h2>
        ) : (
          <>
            <div className="custom_data_table_content">
              <table className="custom_data_table">
                <thead className="custom_data_table_head">
                  <tr>
                    <th className="custom_data_table_heading">
                      {t("directory.logo")}
                    </th>
                    <th className="custom_data_table_heading">
                      {t("table.retailName")}
                    </th>
                    <th className="custom_data_table_heading">
                      {t("table.phoneNumber")}
                    </th>
                    <th className="custom_data_table_heading">
                      {t("table.type")}
                    </th>
                    <th className="custom_data_table_heading">
                      {t("floor.floor/level")}
                    </th>
                    <th className="custom_data_table_heading">
                      {t("table.createdAt")}
                    </th>
                    <th className="custom_data_table_heading">
                      {t("table.updatedAt")}
                    </th>
                    <th className="custom_data_table_heading">
                      {t("table.created_by")}
                    </th>
                    <th className="custom_data_table_heading">
                      {t("table.action")}
                    </th>
                  </tr>
                </thead>
                <tbody className="custom_data_table_body">
                  {directories?.map((menu, i) => {
                    return (
                      <tr className="custom_data_table_row" key={menu.id}>
                        <td className="custom_data_table_item table_item">
                          <img
                            src={
                              menu.directory_logo
                                ? process.env.REACT_APP_FILE_BASE_URL +
                                  "/" +
                                  menu.directory_logo
                                : process.env.REACT_APP_FILE_BASE_URL +
                                  "/45/a30a40a1-510a-420a-bfb6-bedb974e15ed.png"
                            }
                            alt={"directory_icon"}
                            className={`custom_data_table_img`}
                            onClick={() => {
                              setImgZoom(true);
                              setImgSrc(
                                menu.directory_logo
                                  ? process.env.REACT_APP_FILE_BASE_URL +
                                      "/" +
                                      menu.directory_logo
                                  : process.env.REACT_APP_FILE_BASE_URL +
                                      "/45/a30a40a1-510a-420a-bfb6-bedb974e15ed.png"
                              );
                            }}
                          />
                        </td>
                        <td className="custom_data_table_item table_item">
                          {menu.directory_name}
                        </td>
                        <td className="custom_data_table_item table_item">
                          { (menu.directory_contact_country_code) ? "+" +
                            menu.directory_contact_country_code +
                            " " +
                            menu.directory_contact_number: ""}
                        </td>
                        <td className="custom_data_table_item table_item">
                          {menu.directory_type_name}
                        </td>
                        <td className="custom_data_table_item table_item">
                          {menu.floor_name}
                        </td>

                        <td className="custom_data_table_item table_item">
                          {menu.created_at &&
                            moment
                              .utc(menu.created_at, "YYYYMMDD HH:mm:ss")
                              .clone()
                              .tz(defaultTimeZone)
                              .format("DD/MM/YYYY, hh:mm A")}
                        </td>
                        <td className="custom_data_table_item table_item">
                          {menu.updated_at
                            ? moment
                                .utc(menu.updated_at, "YYYYMMDD HH:mm:ss")
                                .clone()
                                .tz(defaultTimeZone)
                                .format("DD/MM/YYYY, hh:mm A")
                            : "-"}
                        </td>
                        <td className="custom_data_table_item table_item">
                          {menu.user_name || "-"}
                        </td>
                        <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                          <div className="custom_data_table_view_edit_btn_item_row">
                            <div className="tooltip">
                              <button
                                className="custom_data_table_view_edit_item_btn"
                                onClick={() => viewDirectoryHandler(menu)}
                              >
                                {viewIcon}
                              </button>
                              <span className="tooltiptext">View</span>
                            </div>
                            <div className="tooltip">
                              <Link
                                to={`${routes.directory}/edit/${menu.id}`}
                                state={{
                                  directory: menu,
                                  value: {
                                    directory_type_name:
                                      values.directory_type_name,
                                    floor: values.floor,
                                  },
                                  page_number,
                                }}
                                className="custom_data_table_view_edit_item_btn"
                              >
                                {editIcon}
                              </Link>
                              <span className="tooltiptext">Edit</span>
                            </div>

                            <div className="tooltip">
                              <button
                                className="custom_data_table_view_edit_item_btn"
                                onClick={() => deleteDirectoryHandler(menu)}
                              >
                                {deleteIcon}
                              </button>
                              <span className="tooltiptext">Delete</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {+directoriesCount > 0 && (
              <Pagination
                totalRecords={directoriesCount}
                onPageChange={onPageChange}
              />
            )}
          </>
        )}

        <ViewModalDirectory
          handleClose={handleClose}
          show={values.viewModal}
          directoryDetail={values.viewModalData}
        />
        <DeleteDirectoryModal
          handleClose={handleDeleteDirectoryClose}
          values={values}
          setValues={setValues}
          page_number={page_number}
        />
      </div>
      <ViewImageModal
        setImgZoom={setImgZoom}
        imgZoom={imgZoom}
        imgSrc={imgSrc}
      />
    </>
  );
};

export default Directory;

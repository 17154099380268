import React from "react";
import oneCityLogo from "../images/onecitylogo/Logo-Black.png";

const CompletionFormPage = () => {
  return (
    <div className="oc-thankyou-modal-page">
      <div className="oc-thankyou-modal">
        <div className="oc-thankyou-modal-content">
          <div className="oc-thankyou-modal-banner-img">
            <img src={oneCityLogo} alt="thankyou img" />
          </div>
          <div className="oc-thankyou-modal-text">
            <h2>Thank you for submitting your information.</h2>
            <p>
              Your details have been received and is <b>pending approval</b>.
              Once your invitation has been approved, you will receive an email
              regarding the approval. If you do not receive any update by email,
              please contact the Host for assistance.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompletionFormPage;

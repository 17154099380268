import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  getVisitorInvite,
  updateVisitorInvite,
} from "../../store/slice/visitorInviteSlice";
import { SidebarComponent } from "../../helpers/commonFunction";

const DeleteVisitorInviteModal = ({
  show,
  serviceDetail,
  handleClose,
  setDeleteModal,
  setDeleteModalId,
  page_number,
  values,
}) => {
  const dispatch = useDispatch();
  const showHideClassName = show && "user_detail_modal_show";
  const { t } = useTranslation("common");
  const sideHeading = localStorage.getItem("sidebar_component");

  const deleteVisitorInviteHandler = () => {
    const data = {
      delete_status: "1",
    };
    dispatch(
      updateVisitorInvite({
        data,
        id: serviceDetail,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            dispatch(
              getVisitorInvite({
                start: (page_number - 1) * 10,
                title: values.title,
                status: values.status.value,
              })
            );
            setDeleteModal(false);
            setDeleteModalId("");
          }
        },
        toast: t("toast.delete_visitorinvite"),
      })
    );
  };

  return (
    <>
      <div
        className={`user_detail_modal_bg  ${showHideClassName}`}
        onClick={handleClose}
      ></div>
      <section
        className={`user_detail_modal_main delete_feedback_modal ${showHideClassName}`}
      >
        <div className="inquiry_complaint_detail_section">
          <h2 className="announcement_page-view-modal">
            Delete {SidebarComponent()}
          </h2>
          <h4 className="announcement_page-view-modal delete_feedback_subheading">
            {t("modal.delete.deleteVisitorDesc")}
          </h4>
          <div className="delete_feedback_btn-container">
            <button
              type="button"
              onClick={handleClose}
              className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn secondary_btn"
            >
              {t("common.close")}
            </button>
            <button
              type="button"
              onClick={deleteVisitorInviteHandler}
              className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn ml-20"
            >
              {t("common.delete")}
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default DeleteVisitorInviteModal;

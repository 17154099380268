import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../component/Common/Button";
import Input from "../../component/Common/Input";
import {
  SidebarComponent,
  imagePreviewProps,
} from "../../helpers/commonFunction";
import { addIcon, editIcon } from "../../icons";
import { fileUpload } from "../../store/slice/fileUploadSlice";
import { notificationTypeValidate } from "../../component/Common/PageComponent/NotificationType";
import {
  createNotificationType,
  getNotificationType,
  updateNotificationType,
} from "../../store/slice/notificationSlice";
import { useRef } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ViewImageModal from "../../component/Common/PageComponent/ViewImageModal";

const NotificationTypeForm = ({
  updateState,
  setUpdateState,
  values,
  setValues,
  errors,
  setErrors,
  handleInputChange,
}) => {
  const dispatch = useDispatch();
  const ref = useRef(false);

  const sideHeading = localStorage.getItem("sidebar_component");

  const { t } = useTranslation("common");

  let fileUploadLoader = useSelector((state) => state.fileUpload.loader);

  const [imgZoom, setImgZoom] = useState(false);
  const [imgSrc, setImgSrc] = useState("");

  useEffect(() => {
    if (!ref.current) {
      ref.current = true;
    } else if (values.notificationType_name && values.notificationType_icon) {
      setErrors({
        ...errors,
        notificationType_name: "",
        notificationType_icon: "",
      });
    } else if (values.notificationType_name) {
      setErrors({ ...errors, notificationType_name: "" });
    } else if (values.notificationType_icon) {
      setErrors({ ...errors, notificationType_icon: "" });
    }
  }, [values.notificationType_icon, values.notificationType_name]);

  const notificationTypes = useSelector(
    (state) => state.notification.notificationTypeList
  );

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    return notificationTypeValidate(
      fieldValues,
      temp,
      setValues,
      values,
      setErrors,
      t
    );
  };

  const imageRemoveHandler = () => {
    setValues({
      ...values,
      notificationType_icon: "",
      notificationType_icon_preview: "",
    });
  };

  const createNotificationTypeHandler = async () => {
    if (validate()) {
      const formData = new FormData();
      let attachmentUrl;
      if (
        values.notificationType_icon &&
        typeof values.notificationType_icon !== "string"
      ) {
        formData.append("file", values.notificationType_icon);
        attachmentUrl = await dispatch(fileUpload(formData));
        if (attachmentUrl && attachmentUrl.payload) {
          setValues({
            ...values,
            notificationType_icon: attachmentUrl.payload,
          });
        }
        formData.delete("file");
      } else {
        attachmentUrl = values.notificationType_icon;
      }
      const data = {
        notification_type_name: values.notificationType_name,
        notification_type_image:
          typeof values.notificationType_icon !== "string"
            ? attachmentUrl.payload
            : attachmentUrl,
      };

      updateState
        ? dispatch(
            updateNotificationType({
              data,
              id: updateState.id,
              cb: (err, res) => {
                if (err) {
                  console.log("err", err);
                } else {
                  dispatch(getNotificationType());
                  setValues({
                    ...values,
                    notificationType_name: "",
                    notificationType_icon: "",
                    notificationType_icon_preview: "",
                  });
                  ref.current = false;
                  setUpdateState("");
                }
              },
              toast: t("toast.update_notiType"),
            })
          )
        : dispatch(
            createNotificationType({
              data,
              cb: (err, res) => {
                if (err) {
                  console.log("err", err);
                } else {
                  dispatch(getNotificationType());
                  setValues({
                    ...values,
                    notificationType_name: "",
                    notificationType_icon: "",
                    notificationType_icon_preview: "",
                  });
                  ref.current = false;
                }
              },
              toast: t("toast.create_notiType"),
            })
          );
    }
  };

  return (
    <>
      <div className="create_from_row">
        <div className="project_edit_detail_column_content type_form_content_devider">
          <h2>
            {updateState
              ? `${SidebarComponent()} Type > Edit`
              : `${SidebarComponent()} Type > Create`}
          </h2>
          <div className="project_edit_detail_divider">
            <label className="create_from_label required">
              {t("form.icon")}
            </label>
            <div className="project_edit_feature_input_file">
              <Input
                className="create_from_input_content"
                labelClassName="create_from_label"
                errorClassName="err_text"
                type="file"
                id={`notificationType_icon`}
                labelOtherProps={
                  <span>
                    {!values.notificationType_icon ? addIcon : editIcon}
                  </span>
                }
                name="notificationType_icon"
                accept="image/*"
                onChange={handleInputChange}
                onClick={(e) => (e.target.value = null)}
                imageProps={imagePreviewProps(
                  values.notificationType_icon,
                  values.notificationType_icon_preview,
                  imageRemoveHandler
                )}
              />
            </div>
            <p className="create_project_review mb-0 block">
              {t("form.xsmall_size_desc")}
            </p>
            {errors?.notificationType_icon && (
              <span className="err_text">{errors?.notificationType_icon}</span>
            )}
            <Input
              className="project_edit_feature_input"
              labelClassName="create_from_label required"
              errorClassName="err_text"
              type="text"
              placeholder={t("form.name_place")}
              label={t("form.name")}
              id="notificationType_name"
              name="notificationType_name"
              value={values.notificationType_name}
              onChange={handleInputChange}
              error={errors?.notificationType_name || ""}
            />
          </div>
          {fileUploadLoader ? (
            <div className="project_submit_bottom_btn center_back_btn">
              <span className="comman_btn">Loading...</span>
            </div>
          ) : (
            <Button
              className="project_submit_bottom_btn center_back_btn"
              buttonClassName="comman_btn"
              onClick={createNotificationTypeHandler}
              text={`${updateState ? t("common.save") : t("common.create")}`}
            />
          )}
        </div>
        <div className="custom_data_table_content">
          <h2>{t("table.list")}</h2>
          <table className="custom_data_table custom_data_table_width">
            <thead className="custom_data_table_head">
              <tr>
                <th className="custom_data_table_heading">{t("table.name")}</th>
                <th className="custom_data_table_heading">
                  {" "}
                  {t("table.image")}
                </th>
                <th className="custom_data_table_heading">
                  {t("table.action")}
                </th>
              </tr>
            </thead>
            <tbody className="custom_data_table_body">
              {notificationTypes?.map((menu, i) => {
                return (
                  <tr className="custom_data_table_row" key={menu.id}>
                    <td className="custom_data_table_item table_item">
                      {menu.notification_type_name}
                    </td>
                    <td className="custom_data_table_item table_item">
                      <img
                        src={
                          process.env.REACT_APP_FILE_BASE_URL +
                          "/" +
                          menu.notification_type_image
                        }
                        alt="notificationType_icon"
                        className="custom_data_table_img"
                        onClick={() => {
                          setImgZoom(true);
                          setImgSrc(
                            process.env.REACT_APP_FILE_BASE_URL +
                              "/" +
                              menu.notification_type_image
                          );
                        }}
                      />
                    </td>
                    <td className="custom_data_table_item table_item">
                      <div className="custom_data_table_view_edit_btn_item_row">
                        <div className="tooltip">
                          <button
                            onClick={() => setUpdateState(menu)}
                            className="custom_data_table_view_edit_item_btn"
                          >
                            {editIcon}
                          </button>
                          <span className="tooltiptext">Edit</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <ViewImageModal
        setImgZoom={setImgZoom}
        imgZoom={imgZoom}
        imgSrc={imgSrc}
      />
    </>
  );
};

export default NotificationTypeForm;

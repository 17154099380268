import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { routes } from "../../constants";
import {
  getAnnouncement,
  updateAnnouncement,
} from "../../store/slice/announcementSlice";
import { useForm } from "../../hooks/useForm";
import AnnouncementViewModal from "./AnnouncementViewModal";
import Loader from "../../component/Common/Loader";
import { announcementTypeValidate } from "../../component/Common/PageComponent/AnnouncementComponents";
import Pagination from "../../component/Pagination/Pagination";
import AnnouncementFilters from "./AnnouncementFilters";
import AnnouncementList from "./AnnouncementList";
import DeleteAnnouncementModal from "./DeleteAnnouncementModal";
import { useRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import CommonNotification from "../../component/Common/PageComponent/CommonNotification";
import { SidebarComponent } from "../../helpers/commonFunction";

const Announcements = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { page_number } = params;
  const { t } = useTranslation("common");

  const location = useLocation();
  const value = location?.state?.value;
  const valuePage = location?.state?.valuePage;

  const sideHeading = localStorage.getItem("sidebar_component");

  const announcements = useSelector(
    (state) => state.announcement.announcementList?.data
  );

  const [pageN, setPageN] = useState(page_number - 1);
  const [apiErr, setApiErr] = useState(false);

  console.log("page_number", page_number);
  console.log("pageN", pageN);

  console.log("valuePage", valuePage);

  const announcementCount = useSelector(
    (state) => state.announcement.announcementList?.count
  );

  const announcementLoader = useSelector((state) => state.announcement.loader);

  let fileUploadLoader = useSelector((state) => state.fileUpload.loader);

  const initialFValues = {
    viewAnnouncementModal: false,
    announcementDetail: "",
    user_type: "",
    announcement_type: value?.announcement_type ? value?.announcement_type : "",
    announcement_title: value?.announcement_title
      ? value?.announcement_title
      : "",
    announcementDeleteModal: false,
    deleteModalId: "",
    startDate: value?.startDate ? value?.startDate : null,
    endDate: value?.endDate ? value?.endDate : null,
    sendNotificationModal: false,
    sendNotificationModalData: "",
  };

  const onChange = (dates) => {
    const [start, end] = dates;
    setValues({ ...values, startDate: start, endDate: end });
    // setStartDate(start);
    // setEndDate(end);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    return announcementTypeValidate(
      fieldValues,
      temp,
      setValues,
      values,
      setErrors,
      t
    );
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );
  const ref = useRef(false);

  useEffect(() => {
    // if (
    //   values.user_type ||
    //   values.announcement_type ||
    //   values.announcement_title
    // ) {
    if (ref.current) {
      navigate(`${routes.announcement}/page/1`);
      if (+pageN === 0) {
        const timer = setTimeout(() => {
          dispatch(
            getAnnouncement({
              start: pageN,
              user_type: values.user_type,
              announcement_type: values.announcement_type,
              announcement_title: values.announcement_title,
              sdate:
                values.startDate &&
                moment(values.startDate).format("YYYY-MM-DD"),
              edate:
                values.endDate && moment(values.endDate).format("YYYY-MM-DD"),
              noAuthority: (error) => {
                if (error) {
                  setApiErr(true);
                } else {
                  setApiErr(false);
                }
              },
            })
          );
        }, 500);
        return () => clearTimeout(timer);
      }
    }
    // } else {
    //   dispatch(
    //     getAnnouncement({
    //       start: (+page_number - 1) * 10,
    //       user_type: values.user_type,
    //       announcement_type: values.announcement_type,
    //       announcement_title: values.announcement_title,
    //     })
    //   );
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.user_type,
    values.announcement_type,
    values.announcement_title,
    values.endDate,
  ]);

  useEffect(() => {
    if (ref.current) {
      dispatch(
        getAnnouncement({
          start: pageN,
          user_type: values.user_type,
          announcement_type: values.announcement_type,
          announcement_title: values.announcement_title,
          sdate:
            values.startDate && moment(values.startDate).format("YYYY-MM-DD"),
          edate: values.endDate && moment(values.endDate).format("YYYY-MM-DD"),
          noAuthority: (error) => {
            if (error) {
              setApiErr(true);
            } else {
              setApiErr(false);
            }
          },
        })
      );
    }
  }, [pageN]);

  useEffect(() => {
    dispatch(
      getAnnouncement({
        // start: (valuePage - 1) * 10,
        start: (valuePage - 1) * 20,
        announcement_type: values.announcement_type,
        announcement_title: values.announcement_title,
        sdate:
          values.startDate && moment(values.startDate).format("YYYY-MM-DD"),
        edate: values.endDate && moment(values.endDate).format("YYYY-MM-DD"),
        noAuthority: (error) => {
          if (error) {
            setApiErr(true);
          } else {
            setApiErr(false);
          }
        },
      })
    );
    ref.current = true;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    setValues({
      ...values,
      viewAnnouncementModal: false,
      announcementDetail: "",
    });
  };

  const handleCloseHandler = () => {
    setValues({
      ...values,
      announcementDeleteModal: false,
      deleteModalId: "",
    });
  };

  // const onPageChange = (page_number) => {
  //   dispatch(
  //     getAnnouncement({
  //       start: page_number,
  //       user_type: values.user_type,
  //       announcement_type: values.announcement_type,
  //       announcement_title: values.announcement_title,
  //     })
  //   );
  // };

  const onPageChange = (page_number) => {
    if (ref.current) {
      setPageN(page_number);
    }
  };

  // if (fileUploadLoader || announcementLoader) {
  //   return <Loader />;
  // }

  const handleNotificationClose = () => {
    setValues({
      ...values,
      sendNotificationModal: false,
      sendNotificationModalData: "",
    });
  };

  const handleNotificationShow = (menu) => {
    setValues({
      ...values,
      sendNotificationModal: true,
      sendNotificationModalData: { id: menu.id },
    });
  };

  const sendNowHandler = () => {
    const data = {
      send_now: "1",
    };

    dispatch(
      updateAnnouncement({
        data,
        id: values?.sendNotificationModalData?.id,
        toastText: t("toast.announce_notification"),
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            dispatch(
              getAnnouncement({
                start: pageN,
                user_type: values.user_type,
                announcement_type: values.announcement_type,
                announcement_title: values.announcement_title,
                sdate:
                  values.startDate &&
                  moment(values.startDate).format("YYYY-MM-DD"),
                edate:
                  values.endDate && moment(values.endDate).format("YYYY-MM-DD"),
                noAuthority: (error) => {
                  if (error) {
                    setApiErr(true);
                  } else {
                    setApiErr(false);
                  }
                },
              })
            );
            setValues({
              ...values,
              sendNotificationModal: false,
              sendNotificationModalData: "",
            });
          }
        },
      })
    );
  };

  return (
    <div className="project_edit_main_content">
      <div className="rolemanagement_project_btn_row_flex">
        <h2 className="project_subheading">{SidebarComponent()}</h2>
        <div className="creat_edit_project_btn_row creat_edit_project_btn_row_flex">
          <Link
            to={routes.createAnnouncement}
            state={{
              value: {
                announcement_type: values.announcement_type,
                announcement_title: values.announcement_title,
              },
              page_number,
            }}
            className="comman_btn ml-auto"
          >
            {t("common.create")}
          </Link>
        </div>
      </div>
      <AnnouncementFilters
        values={values}
        handleInputChange={handleInputChange}
        onChange={onChange}
      />
      <>
        {fileUploadLoader || announcementLoader ? (
          <Loader />
        ) : announcements?.length === 0 ? (
          <h2 className="ta_c c-grey">No results found</h2>
        ) : !announcements && apiErr ? (
          <h2 className="ta_c c-grey">You have no authority</h2>
        ) : (
          <>
            <AnnouncementList
              page_number={page_number}
              values={values}
              setValues={setValues}
              handleNotificationShow={handleNotificationShow}
            />
            {+announcementCount > 0 && (
              <Pagination
                totalRecords={announcementCount}
                onPageChange={onPageChange}
                type={"Announcement"}
              />
            )}
          </>
        )}
      </>

      <DeleteAnnouncementModal
        page_number={page_number}
        values={values}
        setValues={setValues}
        handleClose={handleCloseHandler}
      />
      <AnnouncementViewModal
        show={values.viewAnnouncementModal}
        announcementDetail={values.announcementDetail}
        handleClose={handleClose}
      />
      <CommonNotification
        show={values.sendNotificationModal}
        handleNotificationClose={handleNotificationClose}
        sendNowHandler={sendNowHandler}
      />
    </div>
  );
};

export default Announcements;

import {
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { routes } from "../../constants";
import Axios, { authHeaders } from "../../helpers/axios";

export const createNotificationType = createAsyncThunk(
  "createNotificationType",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/sph/types/notifications`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 || response.status === 201) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        if (data.cb) {
          return data.cb(null, response.data);
        }
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

export const createNotification = createAsyncThunk(
  "createNotification",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/sph/notifications`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 || response.status === 201) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        data.navigate(`${routes.notifications}/page/1`);
        return response.data;
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

export const updateNotification = createAsyncThunk(
  "updateNotification",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/sph/notifications/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 && !data.cb) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        data.navigate(`${routes.notifications}/page/${data.page}`, {
          state: { value: data.value, page: data.page },
        });
        return response.data;
      }
      if (data.cb) {
        return data.cb(null, response.data);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      console.log("error", error.response);
    }
  }
);

export const updateNotificationType = createAsyncThunk(
  "updateNotificationType",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/sph/types/notifications/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        // data.navigate(routes.contact);
        if (data.cb) {
          return data.cb(null, response.data);
        }
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      console.log("error", error.response);
    }
  }
);

export const getNotificationType = createAsyncThunk(
  "getNotificationType",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/sph/types/notifications`,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      if (error.response.status === 403 && data.noAuthority) {
        toast.error(error.response.data.message, {
          autoClose: 3000,
        });
        return data.noAuthority(error.response.data);
      }
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

export const getNotification = createAsyncThunk(
  "getNotification",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/sph/notifications?user_role=&start=${
          data.start || 0
        }&notification_type=${
          data.notification_type || ""
        }&notification_title=&notification_date=${
          data.notification_date || ""
        }`,
        authHeaders()
      );
      return { data: response.data, count: response.headers["x-total-count"] };
    } catch (error) {
      if (error.response.status === 403 && data.noAuthority) {
        toast.error(error.response.data.message, {
          autoClose: 3000,
        });
        return data.noAuthority(error.response.data);
      }
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    notificationList: [],
    notificationTypeList: [],
    createNotificationComponent: "",
    createNotificationTypeComponent: "",
    updateContactComponent: "",
    error: null,
    loader: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Get Notifications
    builder.addCase(getNotification.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getNotification.fulfilled, (state, action) => {
      state.loader = false;
      state.notificationList = action.payload;
    });
    builder.addCase(getNotification.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get Notification Type
    builder.addCase(getNotificationType.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getNotificationType.fulfilled, (state, action) => {
      state.loader = false;
      state.notificationTypeList = action.payload;
    });
    builder.addCase(getNotificationType.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Create Notification
    builder.addCase(createNotification.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(createNotification.fulfilled, (state, action) => {
      state.loader = false;
      state.createNotificationComponent = action.payload;
    });
    builder.addCase(createNotification.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Create Notification type
    builder.addCase(createNotificationType.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(createNotificationType.fulfilled, (state, action) => {
      state.loader = false;
      state.createNotificationTypeComponent = action.payload;
    });
    builder.addCase(createNotificationType.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // // Update Contact
    // builder.addCase(updateContact.pending, (state) => {
    //   state.loader = true;
    // });
    // builder.addCase(updateContact.fulfilled, (state, action) => {
    //   state.loader = false;
    //   state.updateContactComponent = action.payload;
    // });
    // builder.addCase(updateContact.rejected, (state, action) => {
    //   state.loader = false;
    //   state.error = action.payload;
    // });
  },
});

export default notificationSlice.reducer;

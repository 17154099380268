import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useRoutes } from "react-router-dom";
import getRoutes from "./routes";
import "./App.css";
import "./css/style.css";
import { useEffect } from "react";
import { checkAuth } from "./helpers/checkAuth";
import { useState } from "react";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);

  const [err, setErr] = useState(localStorage.getItem("error"));

  useEffect(() => {
    user && checkAuth(dispatch, setErr, navigate);
  }, [dispatch, err, navigate]);

  const routing = useRoutes(getRoutes(user));

  return <>{routing}</>;
}

export default App;

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CommonDatePicker from "../../component/Common/DatePicker";
import Input from "../../component/Common/Input";
import { eventValidation } from "../../component/Common/PageComponent/FormComponent/EventComponents";
import TextArea from "../../component/Common/TextArea";
import { routes } from "../../constants";
import { useForm } from "../../hooks/useForm";
import { addIcon, editIcon } from "../../icons";
import {
  SidebarComponent,
  defaultTimeZone,
  imagePreviewProps,
} from "../../helpers/commonFunction";
import Button from "../../component/Common/Button";
import Select from "react-select";
import moment from "moment";
import { fileUpload } from "../../store/slice/fileUploadSlice";
import { useDispatch, useSelector } from "react-redux";
import { createEvent, updateEvent } from "../../store/slice/eventSlice";
import { getRoles } from "../../store/slice/rolemanagementSlice";
import { getFloors } from "../../store/slice/floorLevelSlice";
import Loader from "../../component/Common/Loader";
import { useCallback } from "react";

const CreateEvent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const editComponent = location?.state?.menu;
  const value = location?.state?.values;
  const page = location?.state?.page_number;
  const { t } = useTranslation("common");

  const sideHeading = localStorage.getItem("sidebar_component");

  const roleList = useSelector((state) => state.rolemanagement.rolesList?.data);

  let fileUploadLoader = useSelector((state) => state.fileUpload.loader);
  let eventLoader = useSelector((state) => state.event.loader);

  const floorsList = useSelector((state) => state.floor.floorsList.data);

  const eventFloorLevelOptions = floorsList?.map((floor) => {
    return {
      value: floor.floor_name,
      label: floor.floor_name,
      id: floor.id,
    };
  });

  useEffect(() => {
    dispatch(getRoles({ appType: 1 }));
    dispatch(getFloors({}));
  }, [dispatch]);

  useEffect(() => {
    if (editComponent && roleList) {
      setValues({
        ...values,
        user_type: editComponent?.user_role
          ? JSON.parse(editComponent?.user_role).length === roleList?.length
            ? ["ALL"]
            : JSON.parse(editComponent?.user_role)
          : [],
      });
    }
  }, [editComponent, roleList]);

  const initialFValues = () => {
    return {
      event_floor_level_id: editComponent
        ? {
            value: editComponent?.floor_name,
            label: editComponent?.floor_name,
            id: editComponent?.floor_id,
          }
        : "",
      event_title: editComponent ? editComponent?.title : "",
      date: editComponent
        ? new Date(
            moment
              .utc(editComponent?.date, "YYYYMMDD HH:mm:ss")
              .clone()
              .tz(defaultTimeZone)
              .format("MM/DD/YYYY, kk:mm:ss")
          )
        : "",
      end_date:
        editComponent && editComponent.end_date
          ? new Date(
              moment
                .utc(editComponent.end_date, "YYYYMMDD HH:mm:ss")
                .clone()
                .tz(defaultTimeZone)
                .format("MM/DD/YYYY, kk:mm:ss")
            )
          : null,
      event_description: editComponent ? editComponent?.description : "",
      address: editComponent ? editComponent?.address : "",
      organizer_name: editComponent ? editComponent?.organizer_name : "",
      event_link: editComponent ? editComponent?.event_link : "",
      organizer_company_name: editComponent
        ? editComponent?.organizer_company_name
        : "",
      user_type:
        editComponent?.user_role && roleList
          ? JSON.parse(editComponent?.user_role).length === roleList?.length
            ? ["ALL"]
            : JSON.parse(editComponent?.user_role)
          : [],
      image: editComponent ? editComponent?.image : "",
      image_preview: editComponent
        ? `${process.env.REACT_APP_FILE_BASE_URL}/${editComponent?.image}`
        : "",
      event_optional_image: editComponent ? editComponent?.image_square : "",
      event_optional_image_preview: editComponent
        ? `${process.env.REACT_APP_FILE_BASE_URL}/${editComponent?.image_square}`
        : "",
      organizer_profile_picture: editComponent
        ? editComponent?.organizer_profile_picture
        : "",
      organizer_profile_picture_preview:
        editComponent?.organizer_profile_picture
          ? `${process.env.REACT_APP_FILE_BASE_URL}/${editComponent?.organizer_profile_picture}`
          : "",
      image_rectangle: editComponent ? editComponent?.image_rectangle : "",
      image_rectangle_preview: editComponent
        ? `${process.env.REACT_APP_FILE_BASE_URL}/${editComponent?.image_rectangle}`
        : "",
      send_now:
        editComponent && editComponent.send_now ? editComponent.send_now : "0",
      include_image:
        editComponent && editComponent.include_image
          ? editComponent.include_image
          : "0",
    };
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    return eventValidation(
      editComponent,
      temp,
      fieldValues,
      values,
      setValues,
      setErrors,
      t
    );
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const imageRemoveHandler = () => {
    setValues({
      ...values,
      image: "",
      image_preview: "",
    });
  };

  const profileRemoveHandler = () => {
    setValues({
      ...values,
      organizer_profile_picture: "",
      organizer_profile_picture_preview: "",
    });
  };

  const optionalImageRemoveHandler = () => {
    setValues({
      ...values,
      event_optional_image: "",
      event_optional_image_preview: "",
    });
  };

  const imageRectangleRemoveHandler = () => {
    setValues({
      ...values,
      image_rectangle: "",
      image_rectangle_preview: "",
    });
  };

  const onChangeNotification = (event) => {
    const { name } = event.target;
    if (name === "send_now") {
      setValues({
        ...values,
        send_now: values.send_now === "0" ? "1" : "0",
      });
    } else {
      setValues({
        ...values,
        include_image: values.include_image === "0" ? "1" : "0",
      });
    }
  };

  const onChangeNewsAndEventNotification = () => {
    setValues({
      ...values,
      include_image: values.include_image === "0" ? "1" : "0",
    });
  };

  const userAllTypeHandler = (e) => {
    setErrors({ ...errors, user_type: "" });
    let tempUserTypeData = [...values.user_type];
    if (tempUserTypeData.includes("ALL")) {
      const index = tempUserTypeData.findIndex((obj) => obj === "ALL");
      if (index > -1) {
        tempUserTypeData.splice(index, 1);
      }
      setValues({ ...values, user_type: tempUserTypeData });
    } else {
      setValues({ ...values, user_type: ["ALL"] });
    }
  };

  const userTypeHandler = (e, item) => {
    setErrors({ ...errors, user_type: "" });
    let tempUserTypeHandler = [...values.user_type];
    if (tempUserTypeHandler.includes(item.id)) {
      let index = tempUserTypeHandler.findIndex((obj) => obj === item.id);
      if (index > -1) {
        tempUserTypeHandler.splice(index, 1);
      }
      setValues({ ...values, user_type: tempUserTypeHandler });
    } else {
      if (tempUserTypeHandler.includes("ALL")) {
        let index = tempUserTypeHandler.findIndex((obj) => obj === "ALL");
        if (index > -1) {
          tempUserTypeHandler.splice(index, 1);
        }
        setValues({
          ...values,
          user_type: [...tempUserTypeHandler, item.id],
        });
      } else {
        setValues({
          ...values,
          user_type: [...values.user_type, item.id],
        });
      }
    }
  };

  const createEventHandler = async (e) => {
    e.preventDefault();
    if (validate()) {
      const formData = new FormData();

      let attachmentRectangleImgUrl;
      if (
        values?.image_rectangle &&
        typeof values.image_rectangle !== "string"
      ) {
        formData.append("file", values.image_rectangle);
        attachmentRectangleImgUrl = await dispatch(fileUpload(formData));
        if (attachmentRectangleImgUrl && attachmentRectangleImgUrl.payload) {
          setValues({
            ...values,
            image_rectangle: attachmentRectangleImgUrl.payload,
          });
        }
        formData.delete("file");
      } else {
        attachmentRectangleImgUrl = values?.image_rectangle;
      }

      ////////////////////////////////////////////////////////////////////////////
      let attachmentImageUrl;
      if (values?.image && typeof values.image !== "string") {
        formData.append("file", values.image);
        attachmentImageUrl = await dispatch(fileUpload(formData));
        if (attachmentImageUrl && attachmentImageUrl.payload) {
          setValues({
            ...values,
            image: attachmentImageUrl.payload,
          });
        }
        formData.delete("file");
      } else {
        attachmentImageUrl = values?.image;
      }
      ////////////////////////////////////////////////////////////////////////////
      let optionalEventImgUrl;
      if (
        values?.event_optional_image &&
        typeof values.event_optional_image !== "string"
      ) {
        formData.append("file", values.event_optional_image);
        optionalEventImgUrl = await dispatch(fileUpload(formData));
        if (optionalEventImgUrl && optionalEventImgUrl.payload) {
          setValues({
            ...values,
            event_optional_image: optionalEventImgUrl.payload,
          });
        }
        formData.delete("file");
      } else {
        optionalEventImgUrl = values?.event_optional_image;
      }
      ////////////////////////////////////////////////////////////////////////////
      let attachmentProfilePictureUrl;
      if (
        values?.organizer_profile_picture &&
        typeof values.organizer_profile_picture !== "string"
      ) {
        formData.append("file", values?.organizer_profile_picture);
        attachmentProfilePictureUrl = await dispatch(fileUpload(formData));
        if (
          attachmentProfilePictureUrl &&
          attachmentProfilePictureUrl.payload
        ) {
          setValues({
            ...values,
            organizer_profile_picture: attachmentProfilePictureUrl.payload,
          });
        }
        formData.delete("file");
      } else {
        attachmentProfilePictureUrl = values?.organizer_profile_picture;
      }
      ////////////////////////////////////////////////////////////////////////////
      const data = {
        title: values?.event_title,
        description: values?.event_description,
        floor_id: values?.event_floor_level_id.id,
        date: moment
          .utc(moment(values?.date).utc())
          .format("YYYY-MM-DD HH:mm:ss"),
        end_date: values?.end_date
          ? moment
              .utc(moment(values?.end_date).utc())
              .format("YYYY-MM-DD HH:mm:ss")
          : null,
        // start_time: moment(values.start_time).format("HH:mm"),
        // end_time: values.end_time
        //   ? moment(values.end_time).format("HH:mm")
        //   : "",
        address: values?.address,
        image:
          typeof values?.image !== "string"
            ? attachmentImageUrl?.payload
            : attachmentImageUrl,
        image_square:
          typeof values?.event_optional_image !== "string"
            ? optionalEventImgUrl?.payload
            : optionalEventImgUrl,
        image_rectangle:
          typeof values?.image_rectangle !== "string"
            ? attachmentRectangleImgUrl?.payload
            : attachmentRectangleImgUrl,
        organizer_name: values?.organizer_name,
        event_link: values?.event_link ? values?.event_link : "",
        organizer_profile_picture:
          typeof values?.organizer_profile_picture !== "string"
            ? attachmentProfilePictureUrl?.payload
            : attachmentProfilePictureUrl,
        organizer_company_name: values?.organizer_company_name,
        user_role: values?.user_type.includes("ALL")
          ? roleList.map((item) => item.id)
          : values?.user_type,

        send_now: values?.send_now,
        include_image: values?.include_image,
      };

      editComponent
        ? dispatch(
            updateEvent({
              data,
              navigate,
              id: editComponent?.id,
              toast: t("toast.update_event"),
              page,
              value,
            })
          )
        : dispatch(
            createEvent({ data, navigate, toast: t("toast.create_event") })
          );
    }
  };

  if (fileUploadLoader || eventLoader) {
    return <Loader />;
  }

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  const filterPassedEndTime = (time) => {
    const currentDate = new Date();
    // const currentDate = values.date;
    const selectedDate = new Date(time);

    // Check if the selected date is the same as the start date
    if (values && values.date) {
      const startDate = new Date(values.date);

      // If the selected date is the same as the start date,
      // compare the times to ensure end time is after start time
      if (
        currentDate.getDate() === startDate.getDate() &&
        currentDate.getMonth() === startDate.getMonth() &&
        currentDate.getFullYear() === startDate.getFullYear()
      ) {
        return selectedDate.getTime() > values.date.getTime();
      }
    }

    // For dates on different days, no additional validation is needed
    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <>
      <div className="comman_btn_container center_back_btn announcement_page-container">
        <h2 className="mobile_configuration_heading">
          {/* {editComponent ? `${sideHeading} > Edit` : `${sideHeading} > Create`} */}
          {editComponent
            ? `${SidebarComponent()} > Edit`
            : `${SidebarComponent()} > Create`}
        </h2>
        <Link
          to={`${routes.events}/page/${page}`}
          state={{ page, value }}
          className="comman_btn ml-auto secondary_btn"
        >
          {t("common.back")}
        </Link>
      </div>
      <div className="project_edit_main_content">
        <div className="create_from_row">
          <Input
            className="create_from_input_content null"
            labelClassName="create_from_label required"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder={t("announcement.announcement_title_place")}
            label={t("announcement.title")}
            id="event_title"
            name="event_title"
            value={values?.event_title}
            onChange={handleInputChange}
            error={errors?.event_title || ""}
          />
          <div className="event_date_picker_wrapper">
            <CommonDatePicker
              values={values}
              setValues={setValues}
              errors={errors}
              setErrors={setErrors}
              label={t("table.start_date") + " "}
              name="date"
              dateFormat="dd/MM/yyyy h:mm aa"
              showTimeSelect={true}
              timeIntervals={10}
              filterTime={filterPassedTime}
            />

            <CommonDatePicker
              values={values && values}
              setValues={setValues}
              errors={errors}
              setErrors={setErrors}
              label={t("table.end_date") + " "}
              name="end_date"
              dateFormat="dd/MM/yyyy h:mm aa"
              showTimeSelect={true}
              timeIntervals={10}
              filterTime={filterPassedEndTime}
              not_required
              minDate={values?.date && values?.date}
              isDisabled={values && !values?.date && true}
              // hasInfoIcon
              // tooltipText="Announce Time is the date and time at which the announcement is made"
            />
          </div>
          <TextArea
            className="create_from_input_content col_span_2"
            labelClassName="create_from_label required"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder={t("announcement.description_place")}
            label={t("announcement.description")}
            id="event_description"
            name="event_description"
            rows={2}
            value={values?.event_description}
            onChange={handleInputChange}
            error={errors?.event_description || ""}
          />

          {/* <CommonDatePicker
            values={values}
            setValues={setValues}
            errors={errors}
            setErrors={setErrors}
            label={t("table.end_date")}
            name="end_date"
            not_required
          /> */}
          {/* <div className="create_from_input_content ">
            <label className="create_from_label required">
              {t("event.start_time")}
            </label>
            <DatePicker
              className="create_from_input mb-5"
              placeholderText={t("event.start_place")}
              selected={values.start_time}
              onChange={(e) => onChangeStart(e)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
            />
            {errors?.start_time && (
              <span className="err_text">{errors?.start_time}</span>
            )}
          </div>
          <div className="create_from_input_content ">
            <label className="create_from_label required">
              {t("event.end_time")}
            </label>
            <DatePicker
              className="create_from_input"
              placeholderText={t("event.end_place")}
              selected={values.end_time}
              onChange={(e) => onChangeEnd(e)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
            />
            {errors?.end_time && (
              <span className="err_text">{errors?.end_time}</span>
            )}
          </div> */}
          <Input
            className="create_from_input_content null"
            labelClassName="create_from_label required"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder={t("event.address_place")}
            label={t("form.event_location")}
            id="address"
            name="address"
            value={values?.address}
            onChange={handleInputChange}
            error={errors?.address || ""}
          />
          <Input
            className="create_from_input_content"
            labelClassName="create_from_label required"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder={t("event.organizer_name_place")}
            label={t("event.organizer_name")}
            id="organizer_name"
            name="organizer_name"
            value={values?.organizer_name}
            onChange={handleInputChange}
            error={errors?.organizer_name || ""}
          />
          <Input
            className="create_from_input_content null"
            labelClassName="create_from_label"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder={t("form.event_link_place")}
            label={t("form.event_link")}
            id="event_link"
            name="event_link"
            value={values?.event_link}
            onChange={handleInputChange}
            error={errors?.event_link || ""}
          />
          <Input
            className="create_from_input_content null"
            labelClassName="create_from_label required"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder={t("event.organizer_company_name_place")}
            label={t("event.organizer_company_name")}
            id="organizer_company_name"
            name="organizer_company_name"
            value={values?.organizer_company_name}
            onChange={handleInputChange}
            error={errors?.organizer_company_name || ""}
          />
          <div className="login_input_row mb-0">
            <label className="required">{t("floor.floor/level")}</label>
            <Select
              placeholder={t("floor.selectfloor/level")}
              value={values?.event_floor_level_id}
              className="basic-multi-select"
              classNamePrefix="select"
              name="event_floor_level_id"
              options={eventFloorLevelOptions}
              onChange={(e) => handleInputChange(e, "event_floor_level_id")}
            />
            {errors?.event_floor_level_id && (
              <span className="err_text">{errors?.event_floor_level_id}</span>
            )}
          </div>
          <div className={`create_from_input_content`}>
            <label className="create_from_label required">
              {t("announcement.user_role")}{" "}
            </label>
            <Input
              className="create_from_radio_checkbox_contentainer"
              labelClassName="create_from_radio_label_role"
              inputClassName="create_from_radio_checkbox"
              type="checkbox"
              label={"All"}
              id={"ALL"}
              name="user_type"
              value={"ALL"}
              onChange={(e) => userAllTypeHandler(e)}
              checked={values?.user_type?.includes("ALL")}
            />
            {roleList?.map((type, i) => {
              return (
                <Input
                  className="create_from_radio_checkbox_contentainer"
                  // className={`create_from_radio_checkbox_contentainer ${
                  //   values?.user_type?.includes("ALL") &&
                  //   "disable_radio_btns"
                  // }`}
                  key={i}
                  labelClassName="create_from_radio_label_role"
                  inputClassName="create_from_radio_checkbox"
                  type="checkbox"
                  label={type.role_display_name}
                  id={type.role_display_name}
                  name="user_type"
                  value={type.id}
                  onChange={(e) => userTypeHandler(e, type)}
                  checked={values?.user_type?.includes(type.id)}
                  // disabled={values?.user_type?.includes("ALL")}
                />
              );
            })}
            {errors?.user_type && (
              <span className="err_text">{errors?.user_type}</span>
            )}
          </div>
          {/* <div className="event_inputs_column"></div> */}

          <div className="event_img_wrapper">
            <div className="project_edit_detail_divider">
              <label
                className={`create_from_label required`}
                // ${values.include_image === "1" && "required"}`}
              >
                {t("form.news_and_event_image")} (16:9)
              </label>
              <div className="project_edit_feature_input_file six_nine_image">
                <Input
                  className="create_from_input_content"
                  labelClassName="create_from_label"
                  errorClassName="err_text"
                  type="file"
                  id={`image`}
                  labelOtherProps={
                    <span>{values?.image ? editIcon : addIcon}</span>
                  }
                  name="image"
                  accept=".jpg, .jpeg, .png"
                  onChange={handleInputChange}
                  onClick={(e) => (e.target.value = null)}
                  imageProps={imagePreviewProps(
                    values?.image,
                    values?.image_preview,
                    imageRemoveHandler
                  )}
                />
              </div>
              <p className="create_project_review mb-0 block">
                {t("form.max_size_six_nine")}
              </p>
              {errors?.image && (
                <span className="err_text">{errors?.image}</span>
              )}
            </div>
            <div className="project_edit_detail_divider">
              <label
                className={`create_from_label`}
                // ${values.include_image === "1" && "required"}`}
              >
                {t("form.news_and_event_image")} (1:1)
              </label>
              <div className="project_edit_feature_input_file">
                <Input
                  className="create_from_input_content"
                  labelClassName="create_from_label"
                  errorClassName="err_text"
                  type="file"
                  id={`event_optional_image`}
                  labelOtherProps={
                    <span>
                      {values?.event_optional_image ? editIcon : addIcon}
                    </span>
                  }
                  name="event_optional_image"
                  accept=".jpg, .jpeg, .png"
                  onChange={handleInputChange}
                  onClick={(e) => (e.target.value = null)}
                  imageProps={imagePreviewProps(
                    values?.event_optional_image,
                    values?.event_optional_image_preview,
                    optionalImageRemoveHandler
                  )}
                />
              </div>
              <p className="create_project_review mb-0 block">
                {t("form.max_size_one_one")}
              </p>
              {errors?.event_optional_image && (
                <span className="err_text">{errors?.event_optional_image}</span>
              )}
            </div>
          </div>

          <div className="project_edit_detail_divider">
            <label className="create_from_label">
              {t("event.organizer_profile_picture")} (1:1)
            </label>
            <div className="project_edit_feature_input_file">
              <Input
                className="create_from_input_content"
                labelClassName="create_from_label"
                errorClassName="err_text"
                type="file"
                id={`organizer_profile_picture`}
                labelOtherProps={
                  <span>
                    {values?.organizer_profile_picture ? editIcon : addIcon}
                  </span>
                }
                name="organizer_profile_picture"
                accept="image/*"
                onChange={handleInputChange}
                onClick={(e) => (e.target.value = null)}
                imageProps={imagePreviewProps(
                  values?.organizer_profile_picture,
                  values?.organizer_profile_picture_preview,
                  profileRemoveHandler
                )}
              />
            </div>
            <p className="create_project_review mb-0 block">
              {t("form.max_size_one_one")}
            </p>
            {errors?.organizer_profile_picture && (
              <span className="err_text">
                {errors?.organizer_profile_picture}
              </span>
            )}
          </div>

          <div className="project_edit_detail_divider">
            <label
              className={`create_from_label`}
              // ${values.include_image === "1" && "required"}`}
            >
              {t("form.news_and_event_image")} (2:3)
            </label>
            <div className="project_edit_feature_input_file">
              <Input
                className="create_from_input_content"
                labelClassName="create_from_label"
                errorClassName="err_text"
                type="file"
                id={`image_rectangle`}
                labelOtherProps={
                  <span>{values?.image_rectangle ? editIcon : addIcon}</span>
                }
                name="image_rectangle"
                accept=".jpg, .jpeg, .png"
                onChange={handleInputChange}
                onClick={(e) => (e.target.value = null)}
                imageProps={imagePreviewProps(
                  values?.image_rectangle,
                  values?.image_rectangle_preview,
                  imageRectangleRemoveHandler
                )}
              />
            </div>
            <p className="create_project_review mb-0 block">
              {t("form.max_size_two_three")}
            </p>
            {errors?.event_optional_image && (
              <span className="err_text">{errors?.event_optional_image}</span>
            )}
          </div>

          <hr className="container_bottom_dashed_line col_span_2" />
          <div>
            <label className="notification_details_heading">
              {t("notification.notification_detail")}
            </label>
          </div>
          <div></div>

          <div className="create_from_input_content ">
            {(!editComponent ||
              (editComponent && editComponent.send_now === "0")) && (
              <Input
                className="create_from_radio_checkbox_contentainer"
                labelClassName="create_from_radio_label_role"
                inputClassName="create_from_radio_checkbox"
                type="checkbox"
                label={t("announcement.send_notification_now")}
                id={"send_now"}
                name="send_now"
                value={"1"}
                onChange={(e) => onChangeNotification(e)}
                checked={values.send_now === "1"}
              />
            )}
            <Input
              className="create_from_radio_checkbox_contentainer"
              labelClassName="create_from_radio_label_role"
              inputClassName="create_from_radio_checkbox"
              type="checkbox"
              label={t("common.include") + " " + t("form.news_and_event_image")}
              id={"include_image"}
              name="include_image"
              value={"1"}
              onChange={() => onChangeNewsAndEventNotification()}
              checked={values.include_image === "1"}
            />
          </div>
        </div>
        <Button
          className="project_submit_bottom_btn center_back_btn"
          buttonClassName="comman_btn"
          onClick={createEventHandler}
          text={`${
            fileUploadLoader
              ? "Loading..."
              : editComponent
              ? t("common.save")
              : t("common.create")
          }`}
        />
      </div>
    </>
  );
};

export default CreateEvent;

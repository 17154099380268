import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "../../component/Common/Button";
import { routes } from "../../constants";
import { useForm } from "../../hooks/useForm";
import {
  createStaticPage,
  getPolicyPage,
  updateStaticPage,
} from "../../store/slice/settingSlice";
import Select from "react-select";
import { useState } from "react";
import { useEffect } from "react";

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const test = location.state;

  const { staticContent } = location.state;
  const [lang, setLang] = useState({
    value: staticContent.language,
    label: staticContent.language === "en" ? "English" : "Thai",
  });
  // ?.filter(
  //   (item, i) => item.language === lang.value
  // )[0];

  let tempContent =
    staticContent.page_detail && JSON.parse(staticContent.page_detail).text;
  const contentBlock = tempContent && htmlToDraft(tempContent);
  const contentState =
    contentBlock &&
    ContentState.createFromBlockArray(contentBlock.contentBlocks);
  const editorState1 =
    contentState && EditorState.createWithContent(contentState);

  const initialFValues = {
    policy_editor: editorState1 ? editorState1 : EditorState.createEmpty(),
    content:
      (staticContent.page_detail &&
        JSON.parse(staticContent.page_detail).text) ||
      "",
    page_type:
      Object.keys(staticContent).length !== 0
        ? staticContent.page_type
        : "privacy_policy",
    id: Object.keys(staticContent).length !== 0 ? staticContent.id : "",
  };

  useEffect(() => {
    setValues({
      ...values,
      policy_editor: editorState1 ? editorState1 : EditorState.createEmpty(),
      content:
        (staticContent.page_detail &&
          JSON.parse(staticContent.page_detail).text) ||
        "",
      page_type:
        Object.keys(staticContent).length !== 0
          ? staticContent.page_type
          : "privacy_policy",
      id: Object.keys(staticContent).length !== 0 ? staticContent.id : "",
    });
  }, [staticContent]);

  const languageListOptions = [
    { value: "en", label: "English" },
    { value: "th", label: "Thai" },
  ];

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    false,
    false
  );

  const dispatch = useDispatch();
  const { t } = useTranslation("common");

  const policySaveHandler = () => {
    const data = {
      page_type: "privacy_policy",
      page_detail: { text: values.content },
      language: lang.value,
    };

    Object.keys(staticContent).length !== 0
      ? dispatch(
          updateStaticPage({
            data,
            id: values.id,
            page_type: values.page_type,
            toast: t("toast.update_policy"),
            navigate,
          })
        )
      : dispatch(
          createStaticPage({
            data,
            page_type: values.page_type,
            cb: (err, res) => {
              if (err) {
                console.log("err", err);
              } else {
                dispatch(getPolicyPage());
              }
            },
            toast: t("toast.create_policy"),
            navigate,
          })
        );
  };

  return (
    <>
      <div className="project_edit_main_content">
        <div className="comman_btn_container center_back_btn announcement_page-container">
          <h2 className="mobile_configuration_heading">{"Privacy Policy"}</h2>
          <Link
            to={routes.settings}
            className="comman_btn ml-auto secondary_btn"
          >
            {t("common.back")}
          </Link>
        </div>
        <div className="comman_btn_container center_back_btn announcement_page-container">
          <Select
            placeholder={t("visitorInvite.language_place")}
            value={lang}
            className="basic-multi-select visitor_label_tenant"
            classNamePrefix="select"
            name="lang"
            options={languageListOptions}
            onChange={(e) => setLang(e)}
            isDisabled={true}
          />
        </div>
        <div className="create_from_row  settings_container">
          <div className="col_span_2">
            <div className="zu_common_form_input_content zu_common_form_textarea_content">
              {/* <label
                className="zu_common_form_input_label"
                htmlFor="biographyInput"
              >
                {t("setting.policy.privacyPolicyDetail")}
              </label> */}
              <Editor
                editorState={values.policy_editor}
                wrapperClassName="card"
                editorClassName="card-body"
                toolbarClassName="toolbarClassName"
                onEditorStateChange={(newState) => {
                  setValues({
                    ...values,
                    policy_editor: newState,
                    content: draftToHtml(
                      convertToRaw(newState.getCurrentContent())
                    ),
                  });
                }}
                toolbar={{
                  options: [
                    "inline",
                    "blockType",
                    "fontSize",
                    "fontFamily",
                    "list",
                    "textAlign",
                    "colorPicker",
                    "emoji",
                    "link",
                    "embedded",
                    "image",
                    "history",
                  ],
                  fontFamily: {
                    options: ["Roboto", "Klavika"],
                  },
                  inline: { inDropdown: true },
                  list: { inDropdown: true },
                  textAlign: { inDropdown: true },
                  link: { inDropdown: true },
                  history: { inDropdown: true },
                }}
              />
            </div>
          </div>
          <div className="col_span_2">
            <Button
              className="project_submit_bottom_btn center_back_btn"
              buttonClassName="comman_btn"
              onClick={policySaveHandler}
              text={
                Object.keys(staticContent).length !== 0
                  ? t("common.save")
                  : t("common.create")
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;

import {
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { routes } from "../../constants";
import Axios, { authHeaders } from "../../helpers/axios";

export const createVisitorInvite = createAsyncThunk(
  "createVisitorInvite",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/sph/visitor-invite`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 || response.status === 201) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        data.navigate(`${routes.visitorInvite}/page/1`);
        return response.data;
      }
      data.setIsSaving(false);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message || "Something went wrong!", {
        autoClose: 3000,
      });
      data.setIsSaving(false);
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

export const sendNotificationVisitorInvite = createAsyncThunk(
  "sendNotificationVisitorInvite",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/sph/visitor-invite/send-notification`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 || response.status === 201) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        // data.navigate(routes.visitorInvite);
        return data.cb(null, response.data);
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

export const getVisitorInvite = createAsyncThunk(
  "getVisitorInvite",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/sph/visitor-invite?start=${data.start || 0}&search_key=${
          data.title || ""
          // }&search_type=${data.status || ""}`,
        }&invite_status=${data.status || ""}`,
        authHeaders()
      );
      return { data: response.data, count: response.headers["x-total-count"] };
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

export const getVisitorCompany = createAsyncThunk(
  "getVisitorCompany",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/sph/visitor-invite/company`,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

export const getVisitorCompanyDepartment = createAsyncThunk(
  "getVisitorCompanyDepartment",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/sph/visitor-invite/department/${data.id}`,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

export const getVisitorInviteById = createAsyncThunk(
  "getVisitorInviteById",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/sph/visitor-invite/by-code/${data.id}`
      );
      if (data.cb) {
        return data.cb(null, response.data);
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

export const getVisitorInviteFloor = createAsyncThunk(
  "getVisitorInviteFloor",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/sph/visitor-invite/floors/${data.id}`,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

export const updateVisitorInvite = createAsyncThunk(
  "updateVisitorInvite",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/sph/visitor-invite/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 && !data.cb) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        data.navigate(`${routes.visitorInvite}/page/1`, {
          state: { page: data.page, value: data.value },
        });
        return response.data;
      }
      if (data.cb) {
        toast.error(data.toast, {
          autoClose: 3000,
        });
        return data.cb(null, response.data);
      }
      data.setIsSaving(false);
      return response.data;
    } catch (error) {
      data.setIsSaving(false);
      toast.error(error.response.data.message || "Something went wrong!", {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

export const updateVisitorInviteByGeneral = createAsyncThunk(
  "updateVisitorInviteByGeneral",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(`/sph/visitor-invite/info`, data.data);
      if (response.status === 200 || response.status === 201) {
        toast.success("Information submitted successfully.", {
          autoClose: 3000,
        });

        return data.cb(null, response.data);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

// Get tenants by company id
export const getTenantsByCompanyId = createAsyncThunk(
  "getTenantsById",
  async (data) => {
    try {
      const response = await Axios.get(
        `sph/visitor-invite/tenant?company_id=${data.id}`,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

const visitorInviteSlice = createSlice({
  name: "visitorInvite",
  initialState: {
    visitorCompany: [],
    visitorCompanyDepartment: [],
    visitorInviteList: [],
    visitorInviteFloorList: [],
    tenantsList: [],
    visitorInviteById: "",
    createVisitorInvite: "",
    error: null,
    loader: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Get Visitor Invite
    builder.addCase(getVisitorInvite.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getVisitorInvite.fulfilled, (state, action) => {
      state.loader = false;
      state.visitorInviteList = action.payload;
    });
    builder.addCase(getVisitorInvite.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get Visitor Company
    builder.addCase(getVisitorCompany.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getVisitorCompany.fulfilled, (state, action) => {
      state.loader = false;
      state.visitorCompany = action.payload;
    });
    builder.addCase(getVisitorCompany.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get Visitor Company department
    builder.addCase(getVisitorCompanyDepartment.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getVisitorCompanyDepartment.fulfilled, (state, action) => {
      state.loader = false;
      state.visitorCompanyDepartment = action.payload;
    });
    builder.addCase(getVisitorCompanyDepartment.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get Visitor Invite floors
    builder.addCase(getVisitorInviteFloor.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getVisitorInviteFloor.fulfilled, (state, action) => {
      state.loader = false;
      state.visitorInviteFloorList = action.payload;
    });
    builder.addCase(getVisitorInviteFloor.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get Visitor Invite By Id
    builder.addCase(getVisitorInviteById.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getVisitorInviteById.fulfilled, (state, action) => {
      state.loader = false;
      state.visitorInviteById = action.payload;
    });
    builder.addCase(getVisitorInviteById.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Create Visitor Invite
    builder.addCase(createVisitorInvite.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(createVisitorInvite.fulfilled, (state, action) => {
      state.loader = false;
      state.createVisitorInvite = action.payload;
    });
    builder.addCase(createVisitorInvite.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get tenants by company id
    builder.addCase(getTenantsByCompanyId.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getTenantsByCompanyId.fulfilled, (state, action) => {
      state.loader = false;
      state.tenantsList = action.payload;
    });
    builder.addCase(getTenantsByCompanyId.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // updateVisitorInvite
    builder.addCase(updateVisitorInvite.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(updateVisitorInvite.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(updateVisitorInvite.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default visitorInviteSlice.reducer;

import {
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { routes } from "../../constants";
import Axios, { authHeaders } from "../../helpers/axios";

export const createAnnoncementType = createAsyncThunk(
  "createAnnoncementType",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/sph/types/announcements`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 || response.status === 201) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        if (data.cb) {
          return data.cb(null, response.data);
        }
        // data.navigate(routes.announcement);
        // return response.data;
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

export const createAnnoncement = createAsyncThunk(
  "createAnnoncement",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/sph/announcement`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 || response.status === 201) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        data.navigate(`${routes.announcement}/page/1`);
        return response.data;
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

export const updateAnnouncement = createAsyncThunk(
  "updateAnnouncement",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/sph/announcement/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 && !data.cb && !data.toast) {
        toast.success(data.toastText, {
          autoClose: 3000,
        });
        data.navigate(`${routes.announcement}/page/${data.valuePage}`, {
          state: { value: data.value, valuePage: data.valuePage },
        });
        return response.data;
      }
      if (response.status === 200 && data.cb) {
        toast.success(data.toastText, {
          autoClose: 3000,
        });
        return data.cb(null, response.data);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      console.log("error", error.response);
    }
  }
);

export const updateAnnouncementType = createAsyncThunk(
  "updateAnnouncementType",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/sph/types/announcements/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        // data.navigate(routes.announcement);
        if (data.cb) {
          return data.cb(null, response.data);
        }
      }
      return response.data;
    } catch (error) {
      console.log("error", error.response);
    }
  }
);

export const getAnnouncementTypes = createAsyncThunk(
  "getAnnouncementTypes",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/sph/types/announcements`,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

export const getAnnouncement = createAsyncThunk(
  "getAnnouncement",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/sph/announcement?user_type=${data.user_type || ""}&start=${
          data.start || 0
        }&limit=${data.limit || 20}&announcement_type=${
          data.announcement_type || ""
        }&announcement_title=${data.announcement_title || ""}&sdate=${
          data.sdate || ""
        }&edate=${data.edate || ""}`,
        authHeaders()
      );

      return { data: response.data, count: response.headers["x-total-count"] };
    } catch (error) {
      if (error.response.status === 403 && data.noAuthority) {
        console.log("error", error);
        toast.error(error.response.data.message, {
          autoClose: 3000,
        });
        return data.noAuthority(error.response.data);
      }
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

const announcementSlice = createSlice({
  name: "announcement",
  initialState: {
    announcementList: [],
    announcementTypesList: [],
    createAnnouncementComponent: "",
    createAnnouncementTypeComponent: "",
    updateAnnouncementComponent: "",
    error: null,
    loader: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Get announcement
    builder.addCase(getAnnouncement.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getAnnouncement.fulfilled, (state, action) => {
      state.loader = false;
      state.announcementList = action.payload;
    });
    builder.addCase(getAnnouncement.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get announcement types
    builder.addCase(getAnnouncementTypes.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getAnnouncementTypes.fulfilled, (state, action) => {
      state.loader = false;
      state.announcementTypesList = action.payload;
    });
    builder.addCase(getAnnouncementTypes.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Create announcement
    builder.addCase(createAnnoncement.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(createAnnoncement.fulfilled, (state, action) => {
      state.loader = false;
      state.createAnnouncementComponent = action.payload;
    });
    builder.addCase(createAnnoncement.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Create Announcement type
    builder.addCase(createAnnoncementType.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(createAnnoncementType.fulfilled, (state, action) => {
      state.loader = false;
      state.createAnnouncementTypeComponent = action.payload;
    });
    builder.addCase(createAnnoncementType.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Update Announcement
    builder.addCase(updateAnnouncement.pending, (state) => {
      state.loader = true;
      state.updateLoader = true;
    });
    builder.addCase(updateAnnouncement.fulfilled, (state, action) => {
      state.loader = false;
      state.updateAnnouncementComponent = action.payload;
    });
    builder.addCase(updateAnnouncement.rejected, (state, action) => {
      state.loader = false;
      state.updateLoader = false;
      state.error = action.payload;
    });
  },
});

export default announcementSlice.reducer;
